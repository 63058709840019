import { Cloud, CloudOffIcon, CloudUploadIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppSelector } from "@/hooks/types";
import { useNavigatorOnline } from "@/hooks/useNavigatorOnline";
import { usePrevious } from "@/hooks/usePrevious";
import { getSaveTemplateState } from "@/store/app/selectors";
import { getActiveResumeId } from "@/store/resume/selectors";
import { SaveTemplateState } from "@/types/app";
import { trackClarityError } from "@/utils/tracking";

const indicatorConfig = {
    ERROR: {
        icon: CloudOffIcon,
        text: "Error",
        description: "Something went wrong.",
        color: "text-error-500",
    },
    OFFLINE: {
        icon: CloudOffIcon,
        text: "Offline",
        description: "Connect to the internet to save resume online.",
        color: "text-neutral-900",
    },
    SAVING: {
        icon: CloudUploadIcon,
        text: "Saving",
        description: "Your resume is being saved online.",
        color: "text-neutral-900",
    },
    SAVED: {
        icon: Cloud,
        text: "Saved",
        description: "Your resume has been saved.",
        color: "text-neutral-900",
    },
};

const SavingIndicator = () => {
    const saveState = useAppSelector(getSaveTemplateState);
    const [localSaveState, setLocalSaveState] = useState(saveState);
    const { isOnline } = useNavigatorOnline();
    const prevSaveState = usePrevious(saveState);
    const resumeId = useAppSelector(getActiveResumeId);
    const prevId = usePrevious(resumeId);
    const {
        icon: Icon,
        text,
        color,
        description,
    } = useMemo(() => indicatorConfig[localSaveState], [localSaveState]);

    useEffect(() => {
        if (isOnline && saveState === SaveTemplateState.OFFLINE) {
            setLocalSaveState(SaveTemplateState.SAVED);
        } else if (!isOnline && saveState !== SaveTemplateState.OFFLINE) {
            setLocalSaveState(SaveTemplateState.OFFLINE);
        }
    }, [isOnline, saveState]);

    useEffect(() => {
        // If a user duplicates a resume, they don't leave the builder so reset to saved.
        if (resumeId !== prevId) {
            setLocalSaveState(SaveTemplateState.SAVED);
        }
    }, [resumeId, prevId]);

    useEffect(() => {
        let timeout: undefined | number;
        if (saveState !== prevSaveState) {
            if (prevSaveState === "SAVING" && saveState === "SAVED") {
                // Stall the update to keep the saving state for 1 second
                timeout = window.setTimeout(() => {
                    setLocalSaveState(SaveTemplateState.SAVED);
                }, 1000);
            } else {
                setLocalSaveState(saveState);
                if (saveState === SaveTemplateState.ERROR) {
                    trackClarityError("SavingIndicator");
                }
            }
        }

        return () => {
            window.clearTimeout(timeout);
        };
    }, [prevSaveState, saveState]);

    return (
        <Tooltip placement="bottom">
            <TooltipTrigger>
                <div
                    className={`group relative flex h-9 min-w-[125px] cursor-default items-center justify-center gap-2 rounded-lg px-6 py-2 ${color}`}
                >
                    <Icon className="h-5 w-5" />
                    <AppText
                        variant="labelsbuttons"
                        className="block"
                    >
                        {text}
                    </AppText>
                </div>
            </TooltipTrigger>
            <TooltipContent>
                <AppText variant="footnote">{description}</AppText>
            </TooltipContent>
        </Tooltip>
    );
};

export default SavingIndicator;
