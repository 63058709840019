import { useEffect, useState } from "react";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { useAppDispatch } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { closeModal, openModal } from "@/store/modal/slice";
import { DeviceContextType } from "@/types/device";
import { ModalTypes } from "@/types/modal";

export const DesktopOnly = () => {
    const { isDesktop } = useDevice() as DeviceContextType;
    const [showScreen, setShowScreen] = useState(!isDesktop);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isDesktop) {
            setShowScreen(true);
            dispatch(openModal({ modalType: ModalTypes.DESKTOP_ONLY }));
        } else {
            setShowScreen(false);
            dispatch(closeModal(ModalTypes.DESKTOP_ONLY));
        }
    }, [dispatch, isDesktop]);

    if (!showScreen) return null;

    return (
        <PageHelmet
            title="Desktop Only"
            robotsOverride="noindex, nofollow"
        />
    );
};
