import { CreditActionCode } from "@/types/creditAction";
import { apiRequest } from "./request";
import { ICredit, User } from "@/types/user";
import { setUserCredits } from "@/store/user/slice";
import { AppDispatch } from "@/store/store";

export interface AiResponse {
    output: string;
    usage?: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
        estimated_cost: number;
    };
    userUpdates?: Partial<User>;
}

export interface AiResumeSummaryRequest {
    content?: string;
    industries?: string[];
    jobRoles?: string[];
    experienceLevel?: string;
    overridePrompt?: string;
    temperature?: number;
    includeUsage?: boolean;
    mockResponse?: boolean;
    adminId?: string;
    sectionType?: string;
}

const mockDelay = () => new Promise(resolve => setTimeout(resolve, 1000));

const postProcessAiResponse = (userCredits: ICredit | undefined, dispatch: AppDispatch) => {
    if (userCredits) {
        dispatch(setUserCredits(userCredits));
    }
};

const generateParagraphPro = async (params: AiResumeSummaryRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: "Results-driven software engineer with 5+ years of experience in full-stack development. Specialized in building scalable web applications using React, Node.js, and cloud technologies. Proven track record of delivering high-quality solutions and mentoring junior developers. Strong focus on clean code practices and performance optimization.",
            usage: params.includeUsage ? {
                prompt_tokens: 150,
                completion_tokens: 89,
                total_tokens: 239,
                estimated_cost: 0.0239
            } : undefined
        });
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/paragraph-pro`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiBulletPointsRequest {
    content?: string;
    sectionName?: string;
    overridePrompt?: string;
    temperature?: number;
    includeUsage?: boolean;
    mockResponse?: boolean;
    sectionType?: string;
    adminId?: string;
    industries?: string[];
    jobRoles?: string[];
    experienceLevel?: string;
}

const generateBulletBuilder = async (params: AiBulletPointsRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: "• Developed and maintained multiple high-traffic web applications using React and TypeScript\n• Implemented CI/CD pipelines reducing deployment time by 40%\n• Led a team of 5 developers in delivering a major platform upgrade\n• Optimized database queries resulting in 30% improved performance",
            usage: params.includeUsage ? {
                prompt_tokens: 120,
                completion_tokens: 75,
                total_tokens: 195,
                estimated_cost: 0.0195
            } : undefined
        });
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/generate-bullet-points`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};


export interface AiQuickPromptRequest {
    content?: string;
    temperature?: number;
    includeUsage?: boolean;
    mockResponse?: boolean;
    creditActionCode?: CreditActionCode;
    adminId?: string;
    sectionType?: string;
}

const generateQuickPrompt = async (params: AiQuickPromptRequest, signal?: AbortSignal, dispatch?: AppDispatch) => {
    if (params.mockResponse) {
        await mockDelay();
        return Promise.resolve({
            output: `Mock response for ${params.creditActionCode}`,
            usage: params.includeUsage ? {
                prompt_tokens: 150,
                completion_tokens: 89,
                total_tokens: 239,
                estimated_cost: 0.0239
            } : undefined
        });
    }
    const { userUpdates, ...rest } = await apiRequest<AiResponse>(`/ai/write-prompt`, "POST", params, undefined, undefined, signal);
    if (dispatch && userUpdates?.credits) {
        postProcessAiResponse(userUpdates.credits, dispatch);
    }
    return rest;
};

export interface AiFeedbackRequest {
    creditActionCode: CreditActionCode;
    userInput: string;
    aiResponse: string;
    feedback: "thumbs_up" | "thumbs_down" | null;
    action: "copy" | "insert" | "thumb" | null;
    userEditedResponse: string | null;
}

const createAiFeedback = async (params: AiFeedbackRequest) => {
    await apiRequest<AiResponse>(`/ai/feedback`, "POST", params);
};

export default {
    generateParagraphPro,
    generateBulletBuilder,
    generateQuickPrompt,
    createAiFeedback
};