import { AxiosRequestConfig, AxiosResponse, isAxiosError } from "axios";
import { client } from "./client";
import { store } from "@/store/store";
import { setSaveTemplateState } from "@/store/app/slice";
import { SaveTemplateState } from "@/types/app";

export async function apiRequest<T>(
    url: string,
    method: string,
    params?: any,
    headers?: any,
    responseType?: any,
    signal?: AbortSignal,
): Promise<T> {
    const config: AxiosRequestConfig = {
        url,
        method,
        params: method.toUpperCase() === 'GET' ? params : undefined,  // Use 'params' for GET requests
        data: method.toUpperCase() !== 'GET' ? params : undefined,
        headers,
        responseType,
        signal,
    };
    const isUpdatingTemplate = url.includes("resume") && (method === "POST" || method === "PATCH");
    const currentSaveTemplateState = store.getState().app.saveTemplateState;

    if (isUpdatingTemplate && currentSaveTemplateState !== SaveTemplateState.SAVING) {
        store.dispatch(setSaveTemplateState(SaveTemplateState.SAVING));
    }

    try {
        const response: AxiosResponse<T> = await client(config);
        if (isUpdatingTemplate) {
            store.dispatch(setSaveTemplateState(SaveTemplateState.SAVED));
        }
        return response.data;
    } catch (error) {
        if (isUpdatingTemplate && currentSaveTemplateState !== SaveTemplateState.ERROR) {
            store.dispatch(setSaveTemplateState(SaveTemplateState.ERROR));
        }
        if (isAxiosError(error)) {
            // Access to config, request, and response
            const errorMessage = error.response?.data.message;
            console.error("errorMessage:", errorMessage);
            throw new Error(errorMessage);
        } else {
            console.error(`useApi Error: ${error}`);
            throw new Error("Unexpected Error!");
        }
    }
}
