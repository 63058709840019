import clsx from "clsx";
import {
    ArrowDown,
    ArrowUp,
    CircleCheck,
    ExternalLink,
    Mail,
    RefreshCcw,
    Shield,
} from "lucide-react";
import { useState } from "react";
import billingApi from "@/api/billing";
import { Button } from "@/components/Button/Button";
import { CreditsIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { CreditActionType, ICreditAction } from "@/types/creditAction";
import { ICreditTransaction } from "@/types/creditTransaction";
import { pluralise } from "@/utils/string";

const getLineItemDescriptionPrefix = (type: CreditActionType) => {
    switch (type) {
        case "PURCHASE":
            return "Purchased on";
        case "REFUND":
            return "Refunded on";
        case "EARN":
            return "Earned on";
        case "SPEND":
            return "Spent on";
        case "ADMIN":
            return "Admin adjusted on";
        case "SIGNUP":
            return "Signed up on";
        default:
            return "";
    }
};

const LineItemIcon = ({ type }: { type: CreditActionType }) => {
    switch (type) {
        case "PURCHASE":
            return <CreditsIcon className="h-4 w-4" />;
        case "REFUND":
            return (
                <RefreshCcw
                    size={12}
                    strokeWidth={3}
                />
            );
        case "EARN":
            return (
                <ArrowUp
                    size={12}
                    strokeWidth={3}
                />
            );
        case "SPEND":
            return (
                <ArrowDown
                    size={12}
                    strokeWidth={3}
                />
            );
        case "ADMIN":
            return (
                <Shield
                    size={12}
                    strokeWidth={3}
                />
            );
        case "SIGNUP":
            return (
                <CircleCheck
                    size={12}
                    strokeWidth={3}
                />
            );
        default:
            return <div />;
    }
};

interface TransactionLineItemProps {
    transaction: ICreditTransaction;
    creditAction?: ICreditAction;
}

const LineItemDetails = ({
    transaction,
    creditAction,
}: TransactionLineItemProps) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <div className="flex h-6 w-6 items-center justify-center rounded-lg bg-neutral-50 text-primary-700">
                    <LineItemIcon type={transaction.type} />
                </div>
                <AppText
                    variant="contextheading"
                    className="capitalize text-neutral-900"
                >
                    {creditAction?.name ||
                        transaction.type?.toLowerCase() ||
                        ""}
                </AppText>
            </div>
            <div className="flex items-center gap-2">
                <AppText
                    variant="labelsbuttons"
                    className="text-neutral-600"
                >
                    {getLineItemDescriptionPrefix(transaction.type)}{" "}
                    {new Date(transaction.createdAt).toLocaleString("en-GB", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                    })}
                </AppText>
            </div>
        </div>
    );
};

const LineItemAmount = ({
    transaction,
}: {
    transaction: ICreditTransaction;
}) => {
    const isPositive = transaction.amount > 0;
    return (
        <div
            className={clsx(
                isPositive
                    ? "bg-success-700 text-neutral-50"
                    : "bg-red-100 text-red-500",
                "flex w-full items-center justify-center gap-2 rounded-lg px-3 py-1",
            )}
        >
            <AppText
                variant="regular"
                className="!font-semibold"
            >
                {isPositive ? "+" : "-"} {Math.abs(transaction.amount)}{" "}
                {pluralise(transaction.amount, "Credit", "Credits")}
            </AppText>
        </div>
    );
};

export const TransactionLineItem = ({
    transaction,
    creditAction,
}: TransactionLineItemProps) => {
    const [isSendingReceipt, setIsSendingReceipt] = useState(false);
    const [isGettingReceipt, setIsGettingReceipt] = useState(false);

    const handleReceiptAction = async (asEmail: boolean) => {
        if (!transaction.metadata?.paymentIntentId) return;

        if (asEmail) {
            setIsSendingReceipt(true);
        } else {
            setIsGettingReceipt(true);
        }
        try {
            const response = await billingApi.sendReceipt({
                paymentIntentId: transaction.metadata.paymentIntentId as string,
                asEmail,
            });

            if (!asEmail) {
                window.open(response.receiptUrl, "_blank");
            }
        } catch (error) {
            console.error("Failed to send receipt:", error);
        } finally {
            setIsSendingReceipt(false);
            setIsGettingReceipt(false);
        }
    };
    return (
        <div className="flex  flex-row items-center justify-between gap-2 rounded-xl bg-neutral-100 p-4 sm:gap-4">
            <LineItemDetails
                transaction={transaction}
                creditAction={creditAction}
            />
            <div className="flex w-[150px] flex-col items-center gap-1 sm:w-[300px]">
                <LineItemAmount transaction={transaction} />
                {transaction.type === "PURCHASE" &&
                    transaction.metadata?.paymentIntentId && (
                        <div className="flex w-full flex-col items-center justify-between gap-2 lg:flex-row">
                            <Button
                                variant="text"
                                onClick={() => handleReceiptAction(false)}
                                disabled={isSendingReceipt}
                                className="flex items-center gap-1 rounded-md px-2 py-1 text-xs text-neutral-600 hover:bg-neutral-200"
                                loading={isGettingReceipt}
                                leftIcon={<ExternalLink size={14} />}
                                size="small"
                            >
                                View Receipt
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => handleReceiptAction(true)}
                                disabled={isSendingReceipt}
                                className="flex items-center gap-1 rounded-md px-2 py-1 text-xs text-neutral-600 hover:bg-neutral-200"
                                size="small"
                                loading={isSendingReceipt}
                                leftIcon={<Mail size={14} />}
                            >
                                Send Receipt
                            </Button>
                        </div>
                    )}
            </div>
        </div>
    );
};
