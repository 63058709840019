import clsx from "clsx";
import {
    Compass,
    Paintbrush,
    PanelRightClose,
    PanelRightOpen,
    Settings2,
} from "lucide-react";
import { useCallback, useEffect } from "react";
import { AdminBuilderSettings } from "@/components/Admin/AdminBuilderSettings";
import { AdminControlPanel } from "@/components/Admin/AdminControlPanel";
import { AiToolbox } from "@/components/AiToolbox/AiToolbox";
import { FeedbackSurveyButton } from "@/components/FeedbackSurvey/FeedbackSurveyButton";
import { HelpButton } from "@/components/Modals/HelpGuideModal/HelpButton";
import SecondaryNavigation from "@/components/Navigation/SecondaryNavigation";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { NavItem } from "@/components/Sidebar/NavItem/NavItem";
import { Sidebar } from "@/components/Sidebar/Sidebar";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { DOCUMENT_CONTAINER_CLASS } from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowSideBar, getSideBarState } from "@/store/app/selectors";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { getActiveResumeName } from "@/store/resume/selectors";
import { SideBarState } from "@/types/app";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

const navigation = [
    {
        name: "Styles",
        id: SideBarState.STYLES,
        icon: Paintbrush,
        tourStep: BuilderTourStep.STYLES_BUTTON,
        ariaLabel: "Styles",
        tooltipText: "Customize fonts, colors, and layout of your resume",
    },
    {
        name: "Assistant",
        id: SideBarState.ASSISTANT,
        icon: Compass,
        tourStep: BuilderTourStep.ASSISTANT_BUTTON,
        ariaLabel: "Assistant",
        tooltipText: "Get expert tips and guidelines for writing your resume",
    },
    {
        name: "Section Settings",
        id: SideBarState.EDIT,
        icon: Settings2,
        tourStep: BuilderTourStep.SECTION_BUTTON,
        ariaLabel: "Section settings",
        tooltipText: "Manage section and item settings",
    },
];

export const ResumeBuilderContainer = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const sideBarOpen = useAppSelector(getShowSideBar);
    const sideBarState = useAppSelector(getSideBarState);
    const dispatch = useAppDispatch();
    const activeResumeName = useAppSelector(getActiveResumeName);

    const setTab = useCallback(
        (tab: SideBarState) => {
            dispatch(setSideBarState(tab));
            switch (tab) {
                case SideBarState.STYLES:
                    trackClarityEvent(
                        CLARITY_EVENT_NAMES.SIDEBAR_STYLES_CLICKED,
                    );
                    break;
                case SideBarState.EDIT:
                    trackClarityEvent(
                        CLARITY_EVENT_NAMES.SIDEBAR_SETTINGS_CLICKED,
                    );
                    break;
                case SideBarState.ASSISTANT:
                    trackClarityEvent(
                        CLARITY_EVENT_NAMES.SIDEBAR_ASSISTANT_CLICKED,
                    );
                    break;
                default:
                    break;
            }
        },
        [dispatch],
    );

    const setSideBarOpen = useCallback(() => {
        dispatch(setShowSideBar(!sideBarOpen));
    }, [dispatch, sideBarOpen]);

    useEffect(() => {
        if (sideBarOpen && sideBarState === SideBarState.CLOSED) {
            setTab(SideBarState.STYLES);
        }
    }, [setTab, sideBarState, sideBarOpen]);

    return (
        <>
            <PageHelmet
                title={
                    activeResumeName
                        ? `${activeResumeName} | Resume Editor`
                        : "Resume Editor"
                }
                description="Create and edit your resume"
            />
            <div
                className={clsx(
                    DOCUMENT_CONTAINER_CLASS,
                    "fixed inset-y-0 left-0 top-navigationHeight z-50 flex w-16 flex-col border-r-[1px] border-t-[1px] border-neutral-300 bg-neutral-50 pb-4",
                )}
            >
                <nav className={clsx("relative mt-4")}>
                    <ul
                        role="list"
                        className="flex flex-col items-center px-1"
                    >
                        <li>
                            <NavItem
                                title="Toggle sidebar"
                                onClick={setSideBarOpen}
                            >
                                <div className="flex flex-col items-center justify-center gap-1">
                                    <div
                                        className={clsx(
                                            "group transform rounded-lg p-2 transition-all duration-300 hover:bg-primary-900 ",
                                        )}
                                    >
                                        {sideBarOpen ? (
                                            <PanelRightOpen
                                                className="h-5 w-5 shrink-0 transform text-neutral-900 transition-all duration-300 group-hover:scale-110 group-hover:text-neutral-50"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <PanelRightClose
                                                className="h-5 w-5 shrink-0 transform text-neutral-900 transition-all duration-300 group-hover:scale-110 group-hover:text-neutral-50"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </div>
                                </div>
                            </NavItem>
                            <div
                                className="mb-3 h-px w-full bg-neutral-200"
                                aria-hidden="true"
                            />
                        </li>
                        {navigation.map(item => {
                            const isActive =
                                sideBarState === item.id && sideBarOpen;
                            return (
                                <li key={item.name}>
                                    <Tooltip
                                        placement="right-start"
                                        openDelay={1000}
                                    >
                                        <TooltipTrigger>
                                            <NavItem
                                                title={item.name}
                                                onClick={() => {
                                                    setTab(item.id);
                                                    if (!sideBarOpen)
                                                        setSideBarOpen();
                                                }}
                                                ariaLabel={item.ariaLabel}
                                            >
                                                <div
                                                    className={`flex flex-col items-center justify-center gap-1 ${item.tourStep}`}
                                                >
                                                    <div
                                                        className={clsx(
                                                            "transform rounded-lg p-2 transition-all duration-300",
                                                            isActive
                                                                ? "bg-primary-900 text-neutral-50"
                                                                : "text-neutral-900 group-hover:bg-primary-900 group-hover:text-neutral-50",
                                                        )}
                                                    >
                                                        <item.icon
                                                            className={clsx(
                                                                "transform transition-all duration-300",
                                                                isActive
                                                                    ? "scale-110 text-neutral-50"
                                                                    : "text-neutral-900 group-hover:scale-110 group-hover:text-neutral-50",
                                                                "h-5 w-5 shrink-0",
                                                            )}
                                                            aria-hidden="true"
                                                        />
                                                    </div>
                                                    <AppText
                                                        variant="footnote"
                                                        className="!text-[10px] !leading-3 text-neutral-900"
                                                    >
                                                        {item.name}
                                                    </AppText>
                                                </div>
                                            </NavItem>
                                        </TooltipTrigger>
                                        <TooltipContent
                                            style={{
                                                maxWidth: "150px",
                                            }}
                                        >
                                            <AppText variant="footnote">
                                                {item.tooltipText}
                                            </AppText>
                                        </TooltipContent>
                                    </Tooltip>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </div>
            <div
                className={clsx(
                    "mt-navigationHeight flex flex-row overflow-x-hidden pl-16",
                    DOCUMENT_CONTAINER_CLASS,
                )}
            >
                <Sidebar />
                <main
                    className={clsx(
                        DOCUMENT_CONTAINER_CLASS,
                        "relative w-full",
                    )}
                >
                    <AiToolbox />
                    <SecondaryNavigation />
                    <div
                        className={clsx(
                            "relative mx-auto",
                            sideBarOpen && "xl:translate-x-[160px]", // half of sidebar
                            "transition-margin h-full transform pb-10 pt-24 duration-300 ease-in-out",
                        )}
                        style={{ width: A4_PAGE_WIDTH_PIXELS }}
                    >
                        {children}
                    </div>
                    <HelpButton />
                    <FeedbackSurveyButton />
                    <AdminControlPanel topPosition={120}>
                        <AdminBuilderSettings />
                    </AdminControlPanel>
                </main>
            </div>
        </>
    );
};
