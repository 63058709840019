import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import clsx from "clsx";
import {
    AlignCenter,
    AlignLeft,
    AlignRight,
    Bold,
    Italic,
    List,
    ListOrdered,
    Redo2,
    Underline,
    Undo2,
    AlignJustify,
    ChevronDown,
    Link2,
} from "lucide-react";
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { EditorToolbarButton } from "@/components/Editor/EditorToolbarButton";
import { getButtonCommand } from "@/components/Editor/utils/getButtonCommand";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { EditorContextType } from "@/types/editor";
import { ModalTypes } from "@/types/modal";
import { isMacPlatform } from "@/utils/env";

export const EditorToolbar = () => {
    const { activeEditor: editor } = useContext(
        EditorContext,
    ) as EditorContextType;

    // Button Active States
    const [isBoldActive, setIsBoldActive] = useState(false);
    const [isItalicActive, setIsItalicActive] = useState(false);
    const [isUnderlineActive, setIsUnderlineActive] = useState(false);
    const [isLeftAlignActive, setIsLeftAlignActive] = useState(false);
    const [isCenterAlignActive, setIsCenterAlignActive] = useState(false);
    const [isRightAlignActive, setIsRightAlignActive] = useState(false);
    const [isJustifyAlignActive, setIsJustifyAlignActive] = useState(false);
    const [isBulletListActive, setIsBulletListActive] = useState(false);
    const [isOrderedListActive, setIsOrderedListActive] = useState(false);
    const [isLinkActive, setIsLinkActive] = useState(false);
    const [hasSelection, setHasSelection] = useState(false);
    const dispatch = useAppDispatch();
    // Button Disabled States
    const [isAlignmentDisabled, setIsAlignmentDisabled] = useState(false);

    const openLinkModal = useCallback(() => {
        let url = "";
        if (editor) {
            url = editor.getAttributes("link").href;
        }

        dispatch(
            openModal({
                modalType: ModalTypes.ADD_LINK,
                props: { url },
            }),
        );
    }, [dispatch, editor]);

    const getHasIcon = useCallback(() => {
        if (!editor) return undefined;
        const icon = editor.options.editorProps.attributes?.["data-icon"];
        return !!icon;
    }, [editor]);

    const getIsStaticHeader = useCallback(() => {
        if (!editor) return undefined;
        const isStaticHeader =
            !!editor.options.editorProps.attributes?.["data-static-header"];
        return !!isStaticHeader;
    }, [editor]);

    const updateListButtons = useCallback(() => {
        if (!editor) return;
        const activeBullet = editor.isActive("bulletList");
        const activeOrdered = editor.isActive("orderedList");
        if (activeBullet || activeOrdered) {
            setIsAlignmentDisabled(true);
        } else {
            setIsAlignmentDisabled(false);
        }
        setIsBulletListActive(activeBullet);
        setIsOrderedListActive(activeOrdered);
    }, [editor]);

    const updateAlignmentButtons = useCallback(() => {
        if (!editor) return;
        const hasActiveList =
            editor.isActive("bulletList") || editor.isActive("orderedList");

        if (hasActiveList) {
            // Not allowed to apply alignment to lists
            setIsLeftAlignActive(false);
            setIsCenterAlignActive(false);
            setIsRightAlignActive(false);
            setIsJustifyAlignActive(false);
            setIsAlignmentDisabled(true);
            return;
        }
        setIsLeftAlignActive(editor.isActive({ textAlign: "left" }) || false);
        setIsCenterAlignActive(
            editor.isActive({ textAlign: "center" }) || false,
        );
        setIsRightAlignActive(editor.isActive({ textAlign: "right" }) || false);
        setIsJustifyAlignActive(
            editor.isActive({ textAlign: "justify" }) || false,
        );
    }, [editor]);

    const updateToolBar = useCallback(() => {
        if (!editor) return;
        setIsBoldActive(editor.isActive("bold"));
        setIsItalicActive(editor.isActive("italic"));
        setIsUnderlineActive(editor.isActive("underline"));
        updateListButtons();
        setIsLinkActive(editor.isActive("link"));
        updateAlignmentButtons();
        setHasSelection(!editor.state.selection.empty);
    }, [editor, updateAlignmentButtons, updateListButtons]);

    useEffect(() => {
        if (editor) {
            editor.on("selectionUpdate", updateToolBar);
            //   return () => editor.off('selectionUpdate', updateToolBar);
        }
    }, [editor, updateToolBar]);

    const [hasIcon, setHasIcon] = useState(getHasIcon());
    const [isStaticHeader, setIsStaticHeader] = useState(getIsStaticHeader());

    useEffect(() => {
        if (editor) {
            const hasIcon = getHasIcon();
            const isStaticHeader = getIsStaticHeader();
            setIsStaticHeader(isStaticHeader);
            setHasIcon(hasIcon);
        }
    }, [editor, getHasIcon, getIsStaticHeader]);

    const isHeading = useMemo(() => {
        if (!editor) return true;
        return editor.isActive("heading");
    }, [editor]);

    const isMac = useMemo(() => isMacPlatform(), []);

    return (
        <>
            <div
                id="editor-toolbar"
                className="no-scrollbar flex"
                role="toolbar"
            >
                <div
                    className={clsx(
                        "flex gap-1 border-r-[1px] border-[#CCCCCC] pr-1 xl:pr-2",
                        editor ? "border-[#CCCCCC]" : "border-[#CCCCCC]/25",
                    )}
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().undo().run();
                            }
                        }}
                        disabled={!editor}
                        isActive={false}
                        tooltip={`Undo (${getButtonCommand("undo", isMac)})`}
                        aria-label="Undo"
                    >
                        <Undo2 size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().redo().run();
                            }
                        }}
                        disabled={!editor}
                        isActive={false}
                        tooltip={`Redo (${getButtonCommand("redo", isMac)})`}
                        aria-label="Redo"
                    >
                        <Redo2 size={16} />
                    </EditorToolbarButton>
                </div>
                <div
                    className={clsx(
                        "flex gap-1 border-r-[1px] border-[#CCCCCC] px-1 xl:px-2",
                        editor ? "border-[#CCCCCC]" : "border-[#CCCCCC]/25",
                    )}
                    role="group"
                >
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleBold().run();
                                setIsBoldActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isBoldActive && !!editor}
                        tooltip={`Bold (${getButtonCommand("bold", isMac)})`}
                        aria-label="Bold"
                    >
                        <Bold size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleItalic().run();
                                setIsItalicActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isItalicActive && !!editor}
                        tooltip={`Italic (${getButtonCommand("italic", isMac)})`}
                        aria-label="Italic"
                    >
                        <Italic size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={() => {
                            if (editor) {
                                editor.chain().focus().toggleUnderline().run();
                                setIsUnderlineActive(true);
                            }
                        }}
                        disabled={!editor}
                        isActive={isUnderlineActive && !!editor}
                        tooltip={`Underline (${getButtonCommand("underline", isMac)})`}
                        aria-label="Underline"
                    >
                        <Underline size={16} />
                    </EditorToolbarButton>
                    <EditorToolbarButton
                        onClick={openLinkModal}
                        disabled={!editor || (!hasSelection && !isLinkActive)}
                        isActive={isLinkActive}
                        tooltip={`Insert link (${getButtonCommand("link", isMac)})`}
                        aria-label="Insert link"
                    >
                        <Link2 size={16} />
                    </EditorToolbarButton>
                </div>
                <div
                    className="flex gap-1 px-1 xl:px-1"
                    role="group"
                >
                    <Menu>
                        <Tooltip
                            placement="bottom"
                            openDelay={200}
                        >
                            <TooltipTrigger>
                                <MenuButton as={Fragment}>
                                    <div
                                        className={clsx(
                                            "flex h-9 w-12 cursor-pointer rounded-lg px-2 hover:bg-neutral-200/25",
                                            (!editor ||
                                                hasIcon ||
                                                isAlignmentDisabled ||
                                                isStaticHeader) &&
                                                "pointer-events-none text-neutral-900/10",
                                        )}
                                    >
                                        <div className="flex items-center justify-center gap-1">
                                            {isLeftAlignActive ? (
                                                <AlignLeft size={16} />
                                            ) : isCenterAlignActive ? (
                                                <AlignCenter size={16} />
                                            ) : isRightAlignActive ? (
                                                <AlignRight size={16} />
                                            ) : isJustifyAlignActive ? (
                                                <AlignJustify size={16} />
                                            ) : (
                                                <AlignLeft size={16} />
                                            )}
                                            <ChevronDown size={16} />
                                        </div>
                                    </div>
                                </MenuButton>
                            </TooltipTrigger>
                            <TooltipContent>
                                <AppText variant="footnote">Alignment</AppText>
                            </TooltipContent>
                        </Tooltip>
                        <MenuItems
                            anchor="bottom"
                            className="z-50 flex gap-1 !overflow-visible rounded-lg bg-neutral-50 p-1 shadow-lg"
                        >
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign("left")
                                                .run();
                                            updateAlignmentButtons();
                                        }
                                    }}
                                    disabled={
                                        !editor ||
                                        hasIcon ||
                                        isAlignmentDisabled ||
                                        isStaticHeader
                                    }
                                    isActive={!hasIcon && isLeftAlignActive}
                                    tooltip={`Left align (${getButtonCommand("align-left", isMac)})`}
                                    aria-label="Left align"
                                >
                                    <AlignLeft size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign("center")
                                                .run();
                                            updateAlignmentButtons();
                                        }
                                    }}
                                    disabled={
                                        !editor ||
                                        hasIcon ||
                                        isAlignmentDisabled ||
                                        isStaticHeader
                                    }
                                    isActive={!hasIcon && isCenterAlignActive}
                                    tooltip={`Center align (${getButtonCommand("align-center", isMac)})`}
                                    aria-label="Center align"
                                >
                                    <AlignCenter size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign("right")
                                                .run();
                                            updateAlignmentButtons();
                                        }
                                    }}
                                    disabled={
                                        !editor ||
                                        hasIcon ||
                                        isAlignmentDisabled ||
                                        isStaticHeader
                                    }
                                    isActive={!hasIcon && isRightAlignActive}
                                    tooltip={`Right align (${getButtonCommand("align-right", isMac)})`}
                                    aria-label="Right align"
                                >
                                    <AlignRight size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .setTextAlign("justify")
                                                .run();
                                            updateAlignmentButtons();
                                        }
                                    }}
                                    disabled={
                                        !editor ||
                                        hasIcon ||
                                        isAlignmentDisabled ||
                                        isStaticHeader
                                    }
                                    isActive={!hasIcon && isJustifyAlignActive}
                                    tooltip={`Justify align (${getButtonCommand("align-justify", isMac)})`}
                                    aria-label="Justify align"
                                >
                                    <AlignJustify size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                        </MenuItems>
                    </Menu>
                </div>
                <div
                    className="flex gap-1 px-1 xl:px-1"
                    role="group"
                >
                    <Menu>
                        <Tooltip
                            placement="bottom"
                            openDelay={200}
                        >
                            <TooltipTrigger>
                                <MenuButton as={Fragment}>
                                    <div
                                        className={clsx(
                                            "flex h-9 w-12 cursor-pointer rounded-lg px-2 hover:bg-neutral-200/25",
                                            (!editor ||
                                                hasIcon ||
                                                isHeading ||
                                                isStaticHeader) &&
                                                "pointer-events-none text-neutral-900/10",
                                        )}
                                    >
                                        <div className="flex items-center justify-center gap-1">
                                            {isOrderedListActive ? (
                                                <ListOrdered size={16} />
                                            ) : (
                                                <List size={16} />
                                            )}
                                            <ChevronDown size={16} />
                                        </div>
                                    </div>
                                </MenuButton>
                            </TooltipTrigger>
                            <TooltipContent>
                                <AppText variant="footnote">List type</AppText>
                            </TooltipContent>
                        </Tooltip>
                        <MenuItems
                            anchor="bottom"
                            className="z-50 flex gap-1 !overflow-visible rounded-lg bg-neutral-50 p-1 shadow-lg"
                        >
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleBulletList()
                                                .run();
                                            updateListButtons();
                                        }
                                    }}
                                    disabled={
                                        isHeading || hasIcon || isStaticHeader
                                    }
                                    isActive={isBulletListActive}
                                    tooltip={`Bulleted list (${getButtonCommand("bullet-list", isMac)})`}
                                    aria-label="Bulleted list"
                                >
                                    <List size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                            <MenuItem>
                                <EditorToolbarButton
                                    onClick={() => {
                                        if (editor) {
                                            editor
                                                .chain()
                                                .focus()
                                                .toggleOrderedList()
                                                .run();
                                            updateListButtons();
                                        }
                                    }}
                                    disabled={
                                        isHeading || hasIcon || isStaticHeader
                                    }
                                    isActive={isOrderedListActive}
                                    tooltip={`Numbered list (${getButtonCommand("ordered-list", isMac)})`}
                                    aria-label="Numbered list"
                                >
                                    <ListOrdered size={16} />
                                </EditorToolbarButton>
                            </MenuItem>
                        </MenuItems>
                    </Menu>
                </div>
            </div>
        </>
    );
};
