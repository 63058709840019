import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { motion, AnimatePresence } from "framer-motion";
import { AlertCircleIcon, ArrowLeftIcon, CircleHelpIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "../../Modal/Modal";
import { CheckoutForm } from "./CheckoutForm";
import { Alert } from "@/components/Alert/Alert";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import { CreditPackSummary } from "@/components/Credits/CreditPackSummary";
import { CreditPacks } from "@/components/Credits/CreditPacks";
import { CreditsIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { StripeItem } from "@/types/billing";
import { ICreditAction } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { trackClarityEvent, trackMetaEvent } from "@/utils/tracking";

const BuyCreditsModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.BUY_CREDITS));
    const modalProps = useAppSelector(getModalProps(ModalTypes.BUY_CREDITS));
    const { productDetails, creditAction } = modalProps ?? {};
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState<number | null>(null);
    const userBalance = useAppSelector(getUserCreditBalance);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const { createPaymentIntent } = useStripe();
    const stripePromise = loadStripe(
        import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    );
    const [selectedPack, setSelectedPack] = useState<StripeItem | null>(
        productDetails,
    );
    const [selectedCreditAction, setSelectedCreditAction] =
        useState<ICreditAction | null>(creditAction);
    const [isLoading, setIsLoading] = useState(false);
    const openCreditsModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_INFO }));
    }, [dispatch]);
    const [error, setError] = useState<string | null>(null);

    const openCreditsSuccessModal = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.CREDITS_SUCCESS,
                props: { mode: "buy" },
            }),
        );
        setClientSecret(null);
        setSelectedPack(null);
        setSelectedCreditAction(null);
        dispatch(closeModal(ModalTypes.BUY_CREDITS));
        trackClarityEvent(CLARITY_EVENT_NAMES.PURCHASE_SUCCESS);
    }, [dispatch]);

    useEffect(() => {
        if (productDetails && creditAction) {
            setIsLoading(true);
            setSelectedPack(productDetails);
            setSelectedCreditAction(creditAction);
        }
    }, [productDetails, creditAction]);

    useEffect(() => {
        if (selectedPack?.priceId) {
            createPaymentIntent(selectedPack.priceId)
                .then(response => {
                    if (response) {
                        setClientSecret(response?.clientSecret);
                        setAmount(response?.amount);
                    }
                })
                .catch(error => {
                    console.error("Error creating payment intent:", error);
                });
        }
    }, [selectedPack?.priceId, createPaymentIntent]);

    const handlePackSelect = async (
        creditAction: ICreditAction,
        product: StripeItem,
    ) => {
        trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);

        trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
            content_category: "Pack selection",
            content_name: "Select pack",
            num_items: 1,
            content_type: "product",
            content_ids: [product.id],
        });
        setIsLoading(true);
        setError(null);
        setSelectedPack(product);
        setSelectedCreditAction(creditAction);

        try {
            const response = await createPaymentIntent(product.priceId);
            if (response) {
                setClientSecret(response.clientSecret);
            }
        } catch (error) {
            console.error("Error creating payment intent:", error);
        }
    };

    return (
        <Modal
            modalType={ModalTypes.BUY_CREDITS}
            open={isOpen}
            title="Purchase Credits"
            width="5xl"
            gradientBackground
            mobileFullScreen
            onClose={() => {
                setSelectedPack(null);
                setClientSecret(null);
                setIsLoading(false);
            }}
        >
            <AppText variant="regular">
                Credits let you access and utilise more features and download
                your resume as you need them.
            </AppText>
            <div className="mt-2 flex flex-col gap-3 xs:flex-row xs:items-start xs:justify-between md:items-center lg:mt-4">
                <div className="flex items-center gap-2">
                    <AppText
                        variant="labelsbuttons"
                        className="my-2 whitespace-nowrap text-neutral-900 lg:my-4"
                    >
                        You have:
                    </AppText>
                    <div>
                        <CreditsChip
                            credits={userBalance}
                            type="gradient"
                            borderless
                            leftIcon={<CreditsIcon className="h-4 w-4" />}
                        />
                    </div>
                </div>
                <Button
                    color={"primary"}
                    variant={"text"}
                    rightIcon={<CircleHelpIcon size={16} />}
                    onClick={openCreditsModal}
                    className="whitespace-nowrap"
                >
                    What are Credits?
                </Button>
            </div>
            {error && (
                <Alert
                    type="error"
                    message={error}
                    className="mx-auto my-4 w-1/2"
                    icon={<AlertCircleIcon size={16} />}
                />
            )}
            <AnimatePresence mode="wait">
                {!selectedPack?.priceId ? (
                    <motion.div
                        key="credit-packs"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="h-full lg:h-[440px]"
                    >
                        <CreditPacks onPackSelect={handlePackSelect} />
                    </motion.div>
                ) : (
                    <motion.div
                        key="checkout"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="relative h-full pt-4 lg:h-[440px]"
                    >
                        {isLoading && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="absolute inset-0 z-10 flex items-center justify-center"
                            >
                                <div className="flex flex-col items-center gap-4">
                                    <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary-200 border-t-primary-700" />
                                    <AppText
                                        variant="regular"
                                        className="text-neutral-600"
                                    >
                                        Preparing checkout...
                                    </AppText>
                                </div>
                            </motion.div>
                        )}

                        {clientSecret && (
                            <div className="flex flex-col gap-6 md:flex-row md:items-start md:justify-center md:gap-12">
                                {/* Payment Form */}
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: isLoading ? 0 : 1 }}
                                    transition={{ duration: 0.3 }}
                                    className="mx-auto min-w-[300px] md:mx-0 md:min-w-0 lg:max-w-[400px] lg:flex-1"
                                >
                                    <Button
                                        color="softIndigo"
                                        className="mb-4"
                                        size="small"
                                        leftIcon={<ArrowLeftIcon size={16} />}
                                        onClick={() => {
                                            setSelectedPack(null);
                                            setClientSecret(null);
                                            setIsLoading(false);
                                            setError(null);
                                        }}
                                    >
                                        Return to Packs
                                    </Button>
                                    <Elements
                                        stripe={stripePromise}
                                        options={{ clientSecret }}
                                    >
                                        <CheckoutForm
                                            onSuccess={openCreditsSuccessModal}
                                            onLoadComplete={() =>
                                                setIsLoading(false)
                                            }
                                            onError={setError}
                                            selectedAction={
                                                selectedCreditAction!
                                            }
                                            selectedProduct={selectedPack}
                                            amount={amount ?? 0}
                                        />
                                    </Elements>
                                </motion.div>
                                {/* Selected Pack Summary */}
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: isLoading ? 0 : 1 }}
                                    transition={{ duration: 0.3 }}
                                    className="md:w-1/2 lg:w-1/3"
                                >
                                    <CreditPackSummary
                                        pack={selectedPack}
                                        purchaseCreditActions={[
                                            selectedCreditAction!,
                                        ]}
                                        isCheckout
                                    />
                                </motion.div>
                            </div>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </Modal>
    );
};

export default BuyCreditsModal;
