import {
    Dialog,
    DialogPanel,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import clsx from "clsx";
import { XIcon } from "lucide-react";
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { ModalHeader } from "@/components/Modal/ModalHeader";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { closeTopModal } from "@/store/modal/slice";
import { UsingReactChildren } from "@/types";
import { ModalWidth } from "@/types/app";
import { DeviceContextType } from "@/types/device";
import { ModalTypes } from "@/types/modal";

interface ModalProps extends UsingReactChildren {
    open: boolean;
    title: string;
    onClose?: () => void;
    width?: ModalWidth;
    closeOnOverlayClick?: boolean;
    noHeader?: boolean;
    afterClose?: () => void;
    mobileFullScreen?: boolean;
    footer?: ReactNode;
    forceFullHeight?: boolean;
    modalType: ModalTypes;
    overrideClose?: boolean;
    gradientBackground?: boolean;
    floatingCloseButton?: boolean;
}

const modalWidthMap = {
    xs: "w-[20rem]",
    sm: "w-[24rem]",
    md: "w-[28rem]",
    lg: "w-[32rem]",
    xl: "w-[36rem]",
    "2xl": "w-[42rem]",
    "3xl": "w-[48rem]",
    "4xl": "w-[56rem]",
    "5xl": "w-[64rem]",
    "6xl": "w-[72rem]",
    "7xl": "w-[80rem]",
};

export const Modal = ({
    open,
    onClose,
    title,
    children,
    width = "4xl",
    closeOnOverlayClick = true,
    noHeader,
    afterClose,
    mobileFullScreen,
    footer,
    forceFullHeight,
    modalType,
    overrideClose,
    gradientBackground,
    floatingCloseButton,
}: ModalProps) => {
    const { isMobile } = useDevice() as DeviceContextType;
    const applyMobileStyles = isMobile && mobileFullScreen;
    const modalWidthClass = useMemo(
        () => (applyMobileStyles ? "w-screen" : modalWidthMap[width]),
        [applyMobileStyles, width],
    );
    const dispatch = useAppDispatch();
    const modalStack = useAppSelector(state => state.modal.modalStack);

    useEffect(() => {
        if (isMobile) {
            if (open) {
                document.body.classList.add("overflow-hidden");
                document.body.classList.add("w-screen");
                document.documentElement.classList.add("!px-0");
            } else {
                document.body.classList.remove("overflow-hidden");
                document.body.classList.remove("w-screen");
                document.documentElement.classList.remove("!px-0");
            }
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isMobile, open]);

    const handleModalClose = useCallback(() => {
        onClose?.();
        if (!overrideClose) {
            dispatch(closeTopModal());
        }
    }, [dispatch, onClose, overrideClose]);

    const isTopModal = modalStack[modalStack.length - 1] === modalType;

    return (
        <Transition
            show={open && !!modalWidthClass && isTopModal}
            as={Fragment}
            afterLeave={() => {
                afterClose?.();
            }}
        >
            <Dialog
                as="div"
                className="relative z-50"
                onClose={() => {
                    if (closeOnOverlayClick) {
                        handleModalClose();
                    }
                }}
            >
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                <div className="z-60 fixed inset-0 overflow-y-auto">
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className={clsx(
                                "flex min-h-full p-1 lg:items-center lg:justify-center lg:p-4",
                                applyMobileStyles
                                    ? "items-start justify-start"
                                    : "items-center justify-center",
                            )}
                        >
                            <DialogPanel
                                className={clsx(
                                    "relative flex transform flex-col overflow-hidden bg-white p-4 text-left shadow-xl transition-all lg:max-h-[95vh] lg:p-6",
                                    modalWidthClass,
                                    mobileFullScreen
                                        ? `h-screen rounded-none sm:max-lg:w-screen lg:h-auto lg:rounded-lg`
                                        : `h-auto rounded-lg`,
                                    forceFullHeight ? "!h-[95vh]" : "",
                                    gradientBackground
                                        ? "bg-gradient-credits"
                                        : "",
                                )}
                            >
                                {!noHeader && (
                                    <ModalHeader
                                        onClose={handleModalClose}
                                        title={title}
                                    />
                                )}
                                {floatingCloseButton && (
                                    <IconButton
                                        onClick={handleModalClose}
                                        color={"grey"}
                                        className={clsx(
                                            "absolute right-6 top-6",
                                            gradientBackground &&
                                                "!bg-transparent hover:!bg-neutral-100",
                                        )}
                                    >
                                        <XIcon size={16} />
                                    </IconButton>
                                )}
                                <div
                                    className={clsx(
                                        noHeader ? "" : "mt-2",
                                        "flex-1",
                                        modalType === ModalTypes.DOWNLOAD_RESUME
                                            ? "overflow-hidden"
                                            : "overflow-y-auto",
                                        modalType === ModalTypes.EDIT_IMAGE
                                            ? ""
                                            : "pb-5",
                                    )}
                                >
                                    {children}
                                </div>
                                {footer}
                            </DialogPanel>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    );
};
