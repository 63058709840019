import { SECTION_CONTAINER_CLASS } from "@/constants/resume";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { AppDispatch } from "@/store/store";
import { setActiveSection } from "@/store/template/slice";
import { SideBarState } from "@/types/app";
import { StepType } from "@reactour/tour";

export enum BuilderTourStep {
    STYLES_BUTTON = "builder-tour-step-2",
    ASSISTANT_BUTTON = "builder-tour-step-4",
    SECTION_BUTTON = "builder-tour-step-7",
    SIDE_BAR_CONTAINER = "builder-tour-step-8",
    ADD_SECTIONS = "builder-tour-step-9",
    REARRANGE_SECTIONS = "builder-tour-step-10",
    SMART_SCRIBE = "builder-tour-step-11",
    PREVIEW_RESUME = "builder-tour-step-12",
    HELP_GUIDE = "builder-tour-step-13",
    HEADER_SECTION = "builder-tour-static-header",
}

export const createTourSteps = (dispatch: AppDispatch): StepType[] => [
    {
        selector: `.${BuilderTourStep.STYLES_BUTTON}`,
        content: BuilderTourStep.STYLES_BUTTON,
        position: [450, 120],
        action: () => {
            dispatch(setShowSideBar(true));
            dispatch(setSideBarState(SideBarState.STYLES));
        },
        stepInteraction: false,
        highlightedSelectors: [`.${BuilderTourStep.STYLES_BUTTON}`, `.${BuilderTourStep.SIDE_BAR_CONTAINER}`],
    },
    {
        selector: `.${BuilderTourStep.ASSISTANT_BUTTON}`,
        content: BuilderTourStep.ASSISTANT_BUTTON,
        position: [450, 200],
        action: () => {
            dispatch(setShowSideBar(true));
            dispatch(setSideBarState(SideBarState.ASSISTANT));
        },
        stepInteraction: false,
        highlightedSelectors: [`.${BuilderTourStep.ASSISTANT_BUTTON}`, `.${BuilderTourStep.SIDE_BAR_CONTAINER}`],
    },
    {
        selector: `.${BuilderTourStep.SECTION_BUTTON}`,
        content: BuilderTourStep.SECTION_BUTTON,
        position: [450, 300],
        action: () => {
            dispatch(setShowSideBar(true));
            dispatch(setSideBarState(SideBarState.EDIT));
            const headerSection = document.querySelector(`.${BuilderTourStep.HEADER_SECTION}`);
            const sectionContainer = headerSection?.querySelector(`.${SECTION_CONTAINER_CLASS}`);
            if (sectionContainer) {
                const headerSectionId = sectionContainer.id;
                dispatch(setActiveSection(headerSectionId));
            }
        },
        stepInteraction: false,
        highlightedSelectors: [`.${BuilderTourStep.SECTION_BUTTON}`, `.${BuilderTourStep.SIDE_BAR_CONTAINER}`],
    },
    {
        selector: `.${BuilderTourStep.ADD_SECTIONS}`,
        content: BuilderTourStep.ADD_SECTIONS,
        stepInteraction: false,
    },
    {
        selector: `.${BuilderTourStep.REARRANGE_SECTIONS}`,
        content: BuilderTourStep.REARRANGE_SECTIONS,
        stepInteraction: false,
    },
    {
        selector: `.${BuilderTourStep.SMART_SCRIBE}`,
        content: BuilderTourStep.SMART_SCRIBE,
        stepInteraction: false,
    },
    {
        selector: `.${BuilderTourStep.PREVIEW_RESUME}`,
        content: BuilderTourStep.PREVIEW_RESUME,
        stepInteraction: false,
    },
    {
        selector: `.${BuilderTourStep.HELP_GUIDE}`,
        content: BuilderTourStep.HELP_GUIDE,
        stepInteraction: false,
    },
];
