import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Search, Trash2 } from "lucide-react";
import { useMemo, useState, useCallback } from "react";
import { promptHistoryApi } from "@/api/promptHistory";
import { getAiFeatureTitle } from "@/components/AiToolbox/helper";
import { CopyButton } from "@/components/Button/CopyButton";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { Input } from "@/components/form/Input";
import { useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { ModalTypes } from "@/types/modal";
import { IPromptHistory } from "@/types/promptHistory";
import { pluralise } from "@/utils/string";
import debounce from "lodash/debounce";

const formatDateTime = (date: Date, useAgo = true) => {
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

    if (useAgo) {
        if (diffInMinutes < 1) {
            return "Just now";
        }

        if (diffInHours < 1) {
            return `${diffInMinutes} ${pluralise(diffInMinutes, "min", "mins")} ago`;
        }

        if (diffInHours < 24) {
            return `${diffInHours} ${pluralise(diffInHours, "hour", "hours")} ago`;
        }

        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays < 7) {
            return `${diffInDays} ${pluralise(diffInDays, "day", "days")} ago`;
        }

        const diffInWeeks = Math.floor(diffInDays / 7);
        return `${diffInWeeks} ${pluralise(diffInWeeks, "week", "weeks")} ago`;
    }

    return date.toLocaleDateString("en-GB");
};

const PromptHistoryModal = () => {
    const queryClient = useQueryClient();
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.PROMPT_HISTORY));
    const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");

    // Create memoized debounced function
    const debouncedSetSearch = useCallback(
        debounce((value: string) => {
            setDebouncedQuery(value);
        }, 300),
        [],
    );

    // Update search handler
    const handleSearchChange = useCallback(
        (name: string, value: string) => {
            setSearchQuery(value);
            debouncedSetSearch(value);
        },
        [debouncedSetSearch],
    );

    const { data: promptHistory, isLoading } = useQuery({
        queryKey: ["promptHistory"],
        queryFn: () => promptHistoryApi.getUserPromptHistory(),
        enabled: isOpen, // Only fetch when modal is open
        refetchOnMount: true, // Refetch every time modal opens
        staleTime: 0, // Consider data always stale to force refresh
        retry: false,
    });

    const deleteMutation = useMutation({
        mutationFn: (promptHistoryId: string) =>
            promptHistoryApi.deletePromptHistory(promptHistoryId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["promptHistory"] });
        },
    });

    const onDeletePromptHistory = (promptHistoryId: string) => {
        deleteMutation.mutate(promptHistoryId);
    };

    const today = useMemo(() => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    }, []);

    const yesterday = useMemo(() => {
        const date = new Date(today);
        date.setDate(date.getDate() - 1);
        return date;
    }, [today]);

    // Filter and group prompts based on search query
    const filteredAndGroupedPrompts = useMemo(() => {
        if (!promptHistory) return { today: [], yesterday: [], older: [] };

        const filteredPrompts = debouncedQuery
            ? promptHistory.filter(history => {
                  const searchTerm = debouncedQuery.toLowerCase();
                  return (
                      history.prompt.toLowerCase().includes(searchTerm) ||
                      history.response.toLowerCase().includes(searchTerm)
                  );
              })
            : promptHistory;

        return filteredPrompts.reduce(
            (acc, history) => {
                const historyDate = new Date(history.createdAt);
                historyDate.setHours(0, 0, 0, 0);

                if (historyDate.getTime() === today.getTime()) {
                    acc.today.push(history);
                } else if (historyDate.getTime() === yesterday.getTime()) {
                    acc.yesterday.push(history);
                } else {
                    acc.older.push(history);
                }
                return acc;
            },
            {
                today: [] as IPromptHistory[],
                yesterday: [] as IPromptHistory[],
                older: [] as IPromptHistory[],
            },
        );
    }, [promptHistory, debouncedQuery, today, yesterday]);

    const {
        today: todayPrompts,
        yesterday: yesterdayPrompts,
        older: olderPrompts,
    } = filteredAndGroupedPrompts;

    const activePrompt = useMemo(() => {
        return promptHistory?.find(history => history._id === selectedPrompt);
    }, [promptHistory, selectedPrompt]);

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.PROMPT_HISTORY}
            title="Smart Scribe History"
            width="4xl"
        >
            <AppText
                variant="regular"
                className="mb-4 text-neutral-700"
            >
                Showing last 50 prompt generations
            </AppText>
            <div className="flex h-[70vh] gap-4">
                {/* Left Sidebar */}
                <div className="flex w-2/5 flex-col">
                    {/* Updated search bar section */}
                    <div className="sticky top-0 z-10 bg-white pb-4">
                        <Input
                            placeholder="Search prompts and responses..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                            trailingIcon={<Search />}
                        />
                    </div>

                    {/* Scrollable content */}
                    <div className="no-scrollbar overflow-y-auto">
                        {debouncedQuery &&
                            todayPrompts.length +
                                yesterdayPrompts.length +
                                olderPrompts.length ===
                                0 && (
                                <div className="flex justify-center">
                                    <AppText
                                        variant="labelsbuttons"
                                        className="mx-auto mt-10 text-center text-neutral-600"
                                    >
                                        No Results Found
                                    </AppText>
                                </div>
                            )}
                        {isLoading ? (
                            <div className="flex justify-center p-4">
                                <LoadingSpinner />
                            </div>
                        ) : promptHistory?.length === 0 ? (
                            <div className="flex justify-center">
                                <AppText
                                    variant="labelsbuttons"
                                    className="mx-auto mt-10 px-4 text-center text-neutral-600"
                                >
                                    Start using Smart Scribe to view your
                                    previous generations
                                </AppText>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                {todayPrompts && todayPrompts.length > 0 && (
                                    <div className="space-y-2">
                                        <AppText variant="labelsbuttons">
                                            Today
                                        </AppText>
                                        {todayPrompts?.map(history => (
                                            <PromptHistoryItem
                                                key={history._id}
                                                history={history}
                                                selectedPrompt={selectedPrompt}
                                                setSelectedPrompt={
                                                    setSelectedPrompt
                                                }
                                                onDeletePromptHistory={
                                                    onDeletePromptHistory
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                                {yesterdayPrompts &&
                                    yesterdayPrompts.length > 0 && (
                                        <div className="space-y-2">
                                            <AppText
                                                variant="labelsbuttons"
                                                className="mt-4"
                                            >
                                                Yesterday
                                            </AppText>

                                            {yesterdayPrompts?.map(history => (
                                                <PromptHistoryItem
                                                    key={history._id}
                                                    history={history}
                                                    selectedPrompt={
                                                        selectedPrompt
                                                    }
                                                    setSelectedPrompt={
                                                        setSelectedPrompt
                                                    }
                                                    onDeletePromptHistory={
                                                        onDeletePromptHistory
                                                    }
                                                />
                                            ))}
                                        </div>
                                    )}
                                {olderPrompts && olderPrompts.length > 0 && (
                                    <div className="mt-4 space-y-2">
                                        <AppText
                                            variant="labelsbuttons"
                                            className="mt-4"
                                        >
                                            Older
                                        </AppText>
                                        {olderPrompts?.map(history => (
                                            <PromptHistoryItem
                                                key={history._id}
                                                history={history}
                                                selectedPrompt={selectedPrompt}
                                                setSelectedPrompt={
                                                    setSelectedPrompt
                                                }
                                                onDeletePromptHistory={
                                                    onDeletePromptHistory
                                                }
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Right Content */}
                <div className="no-scrollbar flex-1 overflow-y-auto">
                    {activePrompt ? (
                        <div className="h-full rounded-lg bg-neutral-100 p-4">
                            <div className="flex items-center gap-2">
                                <div className="rounded-lg bg-neutral-700 px-2 py-1 text-neutral-50">
                                    <AppText
                                        variant="footnote"
                                        className="block !font-medium"
                                    >
                                        {formatDateTime(
                                            new Date(
                                                activePrompt?.createdAt || "",
                                            ),
                                            false,
                                        )}
                                    </AppText>
                                </div>
                                <div className="rounded-lg bg-primary-700 px-2 py-1 text-neutral-50">
                                    <AppText
                                        variant="footnote"
                                        className="block !font-medium"
                                    >
                                        {getAiFeatureTitle(
                                            activePrompt?.creditActionCode,
                                        )}
                                    </AppText>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 pt-2">
                                <AppText variant="labelsbuttons">
                                    {activePrompt?.prompt}
                                </AppText>
                                <div className="ml-auto">
                                    <CopyButton
                                        content={activePrompt?.prompt}
                                        variant="full"
                                        size="small"
                                        buttonText="Copy Prompt"
                                    />
                                </div>
                            </div>
                            <div className="my-3 h-0.5 w-full rounded-lg bg-neutral-300" />
                            <div className="flex flex-col gap-3">
                                <AppText variant="regular">
                                    {activePrompt?.response}
                                </AppText>
                                <div className="ml-auto">
                                    <CopyButton
                                        content={activePrompt?.response}
                                        variant="full"
                                        size="small"
                                        buttonText="Copy Response"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex h-full items-center justify-center rounded-lg bg-neutral-100">
                            <AppText
                                variant="labelsbuttons"
                                className="text-gray-500"
                            >
                                Select a previous generation to view details
                            </AppText>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

const PromptHistoryItem = ({
    history,
    selectedPrompt,
    setSelectedPrompt,
    onDeletePromptHistory,
}: {
    history: IPromptHistory;
    selectedPrompt: string | null;
    setSelectedPrompt: (id: string | null) => void;
    onDeletePromptHistory: (id: string) => void;
}) => {
    return (
        <button
            key={history._id}
            onClick={() => {
                setSelectedPrompt(
                    selectedPrompt === history._id ? null : history._id,
                );
            }}
            className={clsx(
                "group relative w-full rounded-lg px-4 py-2 text-left transition-colors",
                selectedPrompt === history._id
                    ? "bg-primary-900 text-neutral-50"
                    : "bg-neutral-100 text-neutral-900 hover:bg-neutral-200",
            )}
        >
            <div className="flex flex-col justify-between gap-1">
                <div className="flex items-center justify-between">
                    <AppText
                        variant="labelsbuttons"
                        className="line-clamp-1 pr-1.5"
                    >
                        {history.prompt}
                    </AppText>

                    <AppText
                        variant="footnote"
                        className={clsx(
                            "mr-4 whitespace-nowrap",
                            selectedPrompt === history._id
                                ? "text-white"
                                : "text-neutral-800",
                        )}
                    >
                        {formatDateTime(new Date(history.createdAt))}
                    </AppText>
                </div>
                <AppText
                    variant="regular"
                    className="line-clamp-1"
                >
                    {history.response}
                </AppText>
            </div>
            <button
                onClick={e => {
                    e.stopPropagation();
                    onDeletePromptHistory(history._id);
                }}
                className={clsx(
                    "absolute right-2 top-2.5 hidden group-hover:block",
                    selectedPrompt === history._id
                        ? "text-white hover:text-neutral-200"
                        : "text-neutral-900 hover:text-neutral-700",
                )}
            >
                <Trash2 size={16} />
            </button>
        </button>
    );
};

export default PromptHistoryModal;
