import {
    PaymentElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import billingApi from "@/api/billing";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector, useAppDispatch } from "@/hooks/types";
import { getUserDetails } from "@/store/user/selectors";
import { setUserCredits } from "@/store/user/slice";
import { StripeItem } from "@/types/billing";
import { ICreditAction } from "@/types/creditAction";
import {
    CLARITY_EVENT_NAMES,
    GA_EVENT_ACTIONS,
    GA_EVENT_CATEGORIES,
} from "@/types/tracking";
import { pluralise } from "@/utils/string";
import { trackClarityEvent, trackGAEvent } from "@/utils/tracking";
import type { StripePaymentElementOptions } from "@stripe/stripe-js";

interface CheckoutFormProps {
    onSuccess: () => void;
    onError: (error: string) => void;
    onLoadComplete: () => void;
    selectedAction: ICreditAction;
    selectedProduct: StripeItem;
    amount: number;
}

export const CheckoutForm = ({
    onSuccess,
    onError,
    onLoadComplete,
    selectedAction,
    amount,
    selectedProduct,
}: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const userDetails = useAppSelector(getUserDetails);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!stripe || !elements) return;

        setIsProcessing(true);

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                redirect: "if_required",
            });

            if (error) {
                onError(
                    error.message ??
                        "An error occurred processing your payment. Please try again.",
                );
                return;
            }

            if (paymentIntent?.status === "succeeded") {
                const response = await billingApi.processPayment({
                    paymentIntentId: paymentIntent.id,
                });

                dispatch(setUserCredits(response.userCredits));

                onSuccess();
            } else {
                trackGAEvent(
                    GA_EVENT_CATEGORIES.STRIPE,
                    GA_EVENT_ACTIONS.SUBMIT,
                    "payment-failed",
                );
                trackClarityEvent(CLARITY_EVENT_NAMES.PURCHASE_FAILED);
            }
        } catch (err) {
            onError(
                "An error occurred processing your payment. Please try again.",
            );
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="mx-auto"
        >
            <PaymentElement
                options={
                    {
                        layout: { type: "tabs" },
                        paymentMethodOrder: ["card"],
                        defaultValues: {
                            billingDetails: {
                                email: userDetails?.email,
                            },
                        },
                    } as StripePaymentElementOptions
                }
                onReady={() => {
                    onLoadComplete();
                }}
            />
            <div className="mt-6 flex flex-col gap-4">
                <div className="flex items-center justify-between gap-2">
                    {/* Totals / tax etc */}
                    <AppText
                        variant="contextheading"
                        className="text-neutral-700"
                    >
                        Total
                    </AppText>
                    <AppText
                        variant="contextheading"
                        className="text-neutral-700"
                    >
                        {amount > 0 && selectedProduct.currencySymbol}
                        {amount}
                    </AppText>
                </div>
                <Button
                    type="submit"
                    disabled={isProcessing || !stripe || !elements}
                    loading={isProcessing}
                    className="w-full"
                >
                    {isProcessing
                        ? "Processing..."
                        : `BUY ${selectedAction?.credits} ${pluralise(
                              selectedAction?.credits ?? 0,
                              "CREDIT",
                              "CREDITS",
                          )}`}
                </Button>
                <div className="flex justify-center gap-3 text-xs text-neutral-500">
                    <a
                        href="https://stripe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neutral-700"
                    >
                        <AppText variant="footnote">Powered by Stripe</AppText>
                    </a>
                    <a
                        href="https://stripe.com/au/legal/consumer"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neutral-700"
                    >
                        <AppText variant="footnote">Terms</AppText>
                    </a>
                    <a
                        href="https://stripe.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-neutral-700"
                    >
                        <AppText variant="footnote">Privacy</AppText>
                    </a>
                </div>
            </div>
        </form>
    );
};
