import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { FilePlus2Icon } from "lucide-react";
import { Fragment, useCallback, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@/components/Button/IconButton";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { AppText } from "@/components/Text/AppText";
import { duplicateResume } from "@/helper/duplicateResume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { resetResumeBuilderState } from "@/routes/utils/resetResumeBuilderState";
import { getActiveResume } from "@/store/resume/selectors";
import { AppRoutes } from "@/types/routes";

export const CreateMenu = () => {
    const [show, setShow] = useState(false);
    const activeResume = useAppSelector(getActiveResume);
    const navigate = useNavigate();
    const [isDuplicating, setIsDuplicating] = useState(false);
    const dispatch = useAppDispatch();

    const onDuplicate = useCallback(() => {
        if (activeResume) {
            setIsDuplicating(true);
            duplicateResume({
                navigate,
                resume: activeResume,
                isExampleResume: false,
                resumeName: `${activeResume.name} - Copy`,
            }).finally(() => {
                setIsDuplicating(false);
            });
        }
    }, [navigate, activeResume]);

    const onCreateNew = useCallback(() => {
        navigate(AppRoutes.NewResume);
        resetResumeBuilderState(dispatch);
    }, [navigate, dispatch]);

    return (
        <Popover className="relative">
            <PopoverButton
                as="div"
                onMouseEnter={() => setShow(true)}
                onMouseLeave={() => setShow(false)}
                className="pl-2 outline-none"
                role="button"
                aria-expanded={show}
                aria-haspopup="menu"
                aria-label="Create menu"
            >
                <IconButton
                    variant={"text"}
                    color={"white"}
                    chevronDirection="up"
                    className={clsx(
                        show && "bg-neutral-100",
                        "!text-neutral-900",
                    )}
                    onClick={() => setShow(!show)}
                    aria-hidden="true"
                >
                    <FilePlus2Icon
                        size={16}
                        aria-hidden="true"
                    />
                </IconButton>
            </PopoverButton>
            {createPortal(
                <Transition
                    show={show}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <PopoverPanel
                        onMouseEnter={() => setShow(true)}
                        onMouseLeave={() => setShow(false)}
                        className="fixed left-14 top-[46px] z-50 bg-transparent"
                        role="menu"
                        aria-label="Create options"
                    >
                        <div className="mt-1 rounded-lg bg-white shadow-createMenu">
                            <button
                                className="text-neutrla-900 flex w-full items-center rounded-t-lg border-b border-neutral-200 px-4 py-2 hover:bg-neutral-100/50"
                                onClick={onCreateNew}
                                role="menuitem"
                                aria-label="New resume"
                            >
                                <AppText variant="labelsbuttons">
                                    New Resume
                                </AppText>
                            </button>
                            <button
                                className="flex w-full items-center rounded-b-lg px-4 py-2 text-neutral-900 hover:bg-neutral-100/50"
                                onClick={onDuplicate}
                                disabled={isDuplicating}
                                role="menuitem"
                                aria-busy={isDuplicating}
                                aria-label="Duplicate resume"
                            >
                                <AppText variant="labelsbuttons">
                                    Duplicate Resume
                                </AppText>
                                {isDuplicating && (
                                    <div
                                        className="ml-2"
                                        aria-hidden="true"
                                    >
                                        <LoadingSpinner size={12} />
                                    </div>
                                )}
                            </button>
                        </div>
                    </PopoverPanel>
                </Transition>,
                document.body,
            )}
        </Popover>
    );
};
