import { useTour } from "@reactour/tour";
import {
    ChevronRight,
    Compass,
    Edit,
    Eye,
    HelpCircle,
    LucideIcon,
    MoveIcon,
    Paintbrush,
    PlusSquare,
    Settings2,
} from "lucide-react";
import { useCallback, useState } from "react";
import userApi from "@/api/user";
import { Button } from "@/components/Button/Button";
import { SmartScribe } from "@/components/Icons/Icons";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { AppText } from "@/components/Text/AppText";
import { translate } from "@/helper/translate";
import { useAppSelector, useAppDispatch } from "@/hooks/types";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { getUserFlags, getUserDetails } from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { SideBarState } from "@/types/app";
import { USER_FLAGS } from "@/types/user";

const StepTitle = ({
    icon: Icon,
    title,
}: {
    icon: LucideIcon;
    title: string;
}) => (
    <div className="flex items-center gap-2">
        {title === "Smart Scribe" ? (
            <SmartScribe gradient />
        ) : (
            <Icon size={16} />
        )}
        <AppText
            variant="contextheading"
            className="text-neutral-900"
        >
            {title}
        </AppText>
    </div>
);

const stepTitleMap: Record<
    BuilderTourStep,
    { icon: LucideIcon; title: string }
> = {
    [BuilderTourStep.STYLES_BUTTON]: { icon: Paintbrush, title: "Styles Tab" },
    [BuilderTourStep.ASSISTANT_BUTTON]: { icon: Compass, title: "Assistant" },
    [BuilderTourStep.SECTION_BUTTON]: {
        icon: Settings2,
        title: "Section Settings",
    },
    [BuilderTourStep.ADD_SECTIONS]: { icon: PlusSquare, title: "Add Sections" },
    [BuilderTourStep.REARRANGE_SECTIONS]: {
        icon: MoveIcon,
        title: "Rearrange Sections",
    },
    [BuilderTourStep.SMART_SCRIBE]: {
        icon: Edit,
        title: "Smart Scribe",
    },
    [BuilderTourStep.PREVIEW_RESUME]: { icon: Eye, title: "Preview Resume" },
    [BuilderTourStep.HELP_GUIDE]: { icon: HelpCircle, title: "Help Guide" },
};

const getStepTitle = (content: BuilderTourStep) => {
    const stepInfo = stepTitleMap[content];
    return stepInfo ? (
        <StepTitle
            icon={stepInfo.icon}
            title={stepInfo.title}
        />
    ) : null;
};

const getStepContent = (content: BuilderTourStep) => {
    switch (content) {
        case BuilderTourStep.STYLES_BUTTON:
            return `${translate("customise", "title")} your resume's appearance with fonts, colors, and spacing for a unique look.`;
        case BuilderTourStep.ASSISTANT_BUTTON:
            return "Access AI-powered advice and tailored examples to enhance your resume.";
        case BuilderTourStep.SECTION_BUTTON:
            return "Fine-tune each section's content and visibility to showcase your best qualities.";
        case BuilderTourStep.ADD_SECTIONS:
            return "Enrich your resume with additional professional, personal, and technical sections.";
        case BuilderTourStep.REARRANGE_SECTIONS:
            return `Easily drag and drop sections to ${translate("prioritise")} your key strengths and experiences.`;
        case BuilderTourStep.SMART_SCRIBE:
            return "Use Smart Scribe to write, rewrite, or improve your content.";
        case BuilderTourStep.PREVIEW_RESUME:
            return "See your resume exactly as it will appear to employers in PDF format.";
        case BuilderTourStep.HELP_GUIDE:
            return "Explore our comprehensive guide for tips on how to get the most out of the resume builder.";
        default:
            return "";
    }
};

interface TourStepProps {
    content: BuilderTourStep;
    currentStep: number;
    setCurrentStep: (step: number) => void;
}

export const TourStep = ({
    content,
    currentStep,
    setCurrentStep,
}: TourStepProps) => {
    const { steps, setIsOpen } = useTour();
    const userFlags = useAppSelector(getUserFlags);
    const user = useAppSelector(getUserDetails);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onFinishTour = useCallback(() => {
        setIsLoading(true);
        userApi
            .saveUserApi(
                {
                    flags: {
                        ...userFlags,
                        [USER_FLAGS.HAS_SEEN_RESUME_BUILDER_TOUR]: true,
                    },
                },
                user?.id,
            )
            .then(user => {
                dispatch(setUserDetails(user));
                setIsOpen(false);
                dispatch(setShowSideBar(false));
                dispatch(setSideBarState(SideBarState.CLOSED));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [dispatch, setIsOpen, user?.id, userFlags]);

    const onNextStep = useCallback(() => {
        const goingToStep = currentStep + 1;
        if (goingToStep === steps.length) {
            onFinishTour();
        } else if (goingToStep === 3) {
            dispatch(setShowSideBar(false));
            const timeoutId = setTimeout(() => {
                setCurrentStep(goingToStep);
            }, 500);
            return () => clearTimeout(timeoutId);
        } else {
            setCurrentStep(goingToStep);
        }
    }, [currentStep, steps.length, onFinishTour, dispatch, setCurrentStep]);

    const onPreviousStep = useCallback(() => {
        const goingToStep = currentStep - 1;
        if (goingToStep === 2) {
            dispatch(setShowSideBar(true));
            const timeoutId = setTimeout(() => {
                setCurrentStep(goingToStep);
            }, 500);
            return () => clearTimeout(timeoutId);
        } else {
            setCurrentStep(goingToStep);
        }
    }, [currentStep, dispatch, setCurrentStep]);

    return (
        <div className="w-[300px]">
            <div className="flex justify-between px-2 py-2">
                {getStepTitle(content)}
                <AppText
                    variant="contextheading"
                    className="text-neutral-900"
                >
                    {currentStep + 1}/{steps.length}
                </AppText>
            </div>
            <AppText
                variant="regular"
                className="px-2 text-neutral-900"
            >
                {getStepContent(content)}
            </AppText>
            <div className="mt-4 flex justify-between">
                <Button
                    variant={"text"}
                    color={"primary"}
                    rightIcon={<ChevronRight size={16} />}
                    onClick={onFinishTour}
                    size="small"
                    disabled={isLoading}
                    className="!min-w-10 !justify-start !px-1 !pl-2"
                >
                    Skip
                </Button>
                <div className="flex gap-2">
                    {currentStep !== 0 && (
                        <Button
                            variant={"solid"}
                            color={"grey"}
                            onClick={onPreviousStep}
                            disabled={isLoading}
                            size="small"
                        >
                            Previous
                        </Button>
                    )}
                    <Button
                        variant={"text"}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onNextStep}
                        size="small"
                    >
                        {currentStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                </div>
            </div>
        </div>
    );
};
