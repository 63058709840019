import { TourProvider } from "@reactour/tour";
import { useEffect } from "react";
import { TourModal } from "@/components/Modals";
import { TourStep } from "@/components/Onboarding/TourStep";
import { createTourSteps } from "@/components/Onboarding/builder";
import { TemplateLoader } from "@/components/TemplateLoader/TemplateLoader";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { PageHeightDebugger } from "@/pages/ResumeBuilder/PageHeightDebugger";
import { ResumeBuilderContainer } from "@/pages/ResumeBuilder/ResumeBuilderContainer";
import { ResumePage } from "@/pages/ResumeBuilder/ResumePage";
import {
    getDebugBuilder,
    getTemplateLoadingState,
} from "@/store/app/selectors";
import { openModal } from "@/store/modal/slice";
import { getUserHasCompletedOnboarding } from "@/store/user/selectors";
import { TemplateLoadingState } from "@/types/app";
import { ModalTypes } from "@/types/modal";

export const ResumeBuilder = () => {
    const loadingState = useAppSelector(getTemplateLoadingState);
    const dispatch = useAppDispatch();
    const userHasCompletedOnboarding = useAppSelector(
        getUserHasCompletedOnboarding,
    );
    const tourSteps = createTourSteps(dispatch);
    const debugBuilder = useAppSelector(getDebugBuilder);

    useEffect(() => {
        if (!userHasCompletedOnboarding) {
            dispatch(openModal({ modalType: ModalTypes.TOUR }));
        }
    }, [dispatch, userHasCompletedOnboarding]);

    return (
        <TourProvider
            steps={tourSteps}
            disableKeyboardNavigation
            showBadge={false}
            showDots={false}
            showCloseButton={false}
            showPrevNextButtons={false}
            onClickMask={() => {}} // Prevent closing tour on mask click
            showNavigation={false}
            components={{
                Content: TourStep,
            }}
            styles={{
                maskArea: base => ({ ...base, rx: 8 }),
                popover: base => ({
                    ...base,
                    padding: "8px 8px",
                    borderRadius: "8px",
                }),
            }}
        >
            <ResumeBuilderContainer>
                <ResumePage />
                {loadingState !== TemplateLoadingState.READY && (
                    <TemplateLoader />
                )}
            </ResumeBuilderContainer>
            <TourModal />
            {debugBuilder && <PageHeightDebugger />}
        </TourProvider>
    );
};
