import clsx from "clsx";
import { CheckIcon } from "lucide-react";
import { Button } from "@/components/Button/Button";
import { AppText } from "@/components/Text/AppText";
import { StripeItem } from "@/types/billing";
import { ICreditAction } from "@/types/creditAction";
import { pluralise } from "@/utils/string";

interface CreditPackSummaryProps {
    pack: StripeItem;
    purchaseCreditActions: ICreditAction[];
    isCheckout?: boolean;
    onPackSelect?: (
        creditAction: ICreditAction,
        productDetails: StripeItem,
    ) => void;
}

export const CreditPackSummary = ({
    pack,
    purchaseCreditActions,
    isCheckout = false,
    onPackSelect,
}: CreditPackSummaryProps) => {
    const productDetails = pack;
    const creditAction = purchaseCreditActions.find(
        action => action.metadata?.stripePriceId === productDetails.priceId,
    );
    const productNameWithoutPack = productDetails.name.replace(" Pack", "");

    if (!creditAction) {
        return null;
    }
    const credits = creditAction.credits ?? 0;
    const metadata = creditAction.metadata ?? {};
    const shortSummary = (metadata.shortSummary as string) || "";
    const isPopular = !isCheckout && !!(metadata.mostPopular ?? false);
    return (
        <div
            key={productDetails.priceId}
            className="relative flex flex-1 basis-0 flex-col"
            onClick={() => onPackSelect?.(creditAction, productDetails)}
        >
            <div className={clsx("md:h-8", isCheckout && "hidden")}>
                {isPopular ? (
                    <div
                        className={clsx(
                            "h-full w-full rounded-t-xl px-4 text-center",
                            isPopular &&
                                "bg-primary-700 text-white lg:ring-2 lg:ring-primary-700",
                        )}
                    >
                        <AppText
                            variant="labelsbuttons"
                            className="mt-2 leading-8"
                        >
                            MOST POPULAR
                        </AppText>
                    </div>
                ) : null}
            </div>
            <div
                className={clsx(
                    "flex h-full flex-col rounded-xl shadow-md",
                    isPopular ? "rounded-t-none" : "",
                    isCheckout && "ring-2 ring-inset ring-primary-200",
                    "bg-neutral-50",
                    "p-4",
                    isPopular && "lg:ring-2 lg:ring-primary-700",
                )}
            >
                <div className="mb-6 flex h-full flex-col">
                    <AppText
                        variant="contextheading"
                        className="text-left text-primary-700"
                    >
                        {productNameWithoutPack}
                    </AppText>
                    <div className="flex items-baseline gap-1">
                        <AppText
                            variant="subheadings"
                            className="text-left text-neutral-900"
                        >
                            {productDetails.price > 0 &&
                                productDetails.currencySymbol}
                            {productDetails.price}
                        </AppText>
                        <AppText
                            variant="footnote"
                            className="flex items-center text-left !font-bold !leading-8 text-neutral-900"
                        >
                            / {credits}{" "}
                            {pluralise(credits, "credit", "credits")}
                        </AppText>
                    </div>

                    {shortSummary && (
                        <AppText
                            variant="regular"
                            className="mt-2 block text-left text-neutral-900"
                        >
                            {shortSummary}
                        </AppText>
                    )}
                </div>
                <Button
                    color={isCheckout ? "softIndigo" : "primary"}
                    className={clsx(
                        "mt-auto w-full !py-6",
                        isCheckout && "pointer-events-none",
                    )}
                >
                    {isCheckout ? "" : "BUY "}
                    {credits} {pluralise(credits, "CREDIT", "CREDITS")}
                </Button>
                <div className="mt-4 flex flex-col gap-1 border-t-2 border-neutral-100 pt-4 text-neutral-700">
                    <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-primary-700" />
                        <AppText variant="regular">Download Resumes</AppText>
                    </div>
                    <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-primary-700" />
                        <AppText variant="regular">Smart Scribe AI</AppText>
                    </div>
                    <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-primary-700" />
                        <AppText variant="regular">Bullet Builder AI</AppText>
                    </div>
                    <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-primary-700" />
                        <AppText variant="regular">Paragraph Pro AI</AppText>
                    </div>
                    <div className="flex items-center gap-2">
                        <CheckIcon className="h-5 w-5 text-primary-700" />
                        <AppText variant="regular">Additional Resumes</AppText>
                    </div>
                </div>
            </div>
        </div>
    );
};
