import { useCallback } from "react";
import { Modal } from "../../Modal/Modal";
import desktopOnlyImage from "@/assets/images/desktop-only.png";
import { Button } from "@/components/Button/Button";
import { Logo } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { getIsAdminUser } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";

const DesktopOnlyModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.DESKTOP_ONLY));
    const isAdmin = useAppSelector(getIsAdminUser);

    const goToHome = useCallback(() => {
        window.location.pathname = AppRoutes.Dashboard;
    }, []);

    return (
        <Modal
            open={isOpen}
            title=""
            width="4xl"
            mobileFullScreen
            closeOnOverlayClick={false}
            noHeader={!isAdmin}
            modalType={ModalTypes.DESKTOP_ONLY}
        >
            <div className="flex flex-col items-center px-5">
                <span onClick={goToHome}>
                    <Logo className="mb-6 h-8 w-40 cursor-pointer text-left" />
                </span>
                <div className="flex flex-1 flex-col items-center justify-center text-center">
                    <img
                        src={desktopOnlyImage}
                        alt="Desktop Only"
                        className="mx-auto mb-8 w-[280px]"
                    />
                    <div className="flex  max-w-[500px] flex-col items-center">
                        <AppText
                            variant="subheadings"
                            className="mb-4"
                        >
                            Switch to 1Template on desktop to create and edit
                            resumes
                        </AppText>
                        <AppText
                            variant="regular"
                            className="mb-6 leading-normal text-neutral-700"
                        >
                            You can preview and download your resumes on mobile
                            via the Dashboard, but to get the most out of
                            editing resumes, visit 1Template on a desktop
                            device.
                        </AppText>
                        <Button
                            className="w-full sm:w-auto"
                            onClick={goToHome}
                        >
                            Go to Dashboard
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DesktopOnlyModal;
