import { CircleHelpIcon } from "lucide-react";
import { useCallback } from "react";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import { CreditPackButtons } from "@/components/Credits/CreditPackButtons";
import { CreditsIcon } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { ProfileTransactions } from "@/pages/Profile/ProfileTransactions";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";

export const ProfileCredits = () => {
    const userBalance = useAppSelector(getUserCreditBalance);
    const dispatch = useAppDispatch();

    const openCreditsModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_INFO }));
    }, [dispatch]);

    return (
        <div className="flex flex-col">
            <AppText
                variant="headings"
                className="mb-4 hidden text-neutral-900 md:block"
            >
                Credits
            </AppText>
            <AppText
                variant="regular"
                className="mb-8 text-neutral-900"
            >
                Credits let you access more features and download your resume as
                you need them.
            </AppText>
            <div className="flex flex-col space-y-6">
                <div className="flex flex-col">
                    <AppText
                        variant="subheadings"
                        className="mb-4 text-neutral-900"
                    >
                        Balance
                    </AppText>
                    <div className="flex flex-col rounded-2xl bg-neutral-100 p-4">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex items-center">
                                <AppText
                                    variant="labelsbuttons"
                                    className="text-neutral-900"
                                >
                                    Your Credits Balance:
                                </AppText>
                                <div>
                                    <CreditsChip
                                        credits={userBalance}
                                        type="gradient"
                                        borderless
                                        leftIcon={
                                            <CreditsIcon className="h-4 w-4" />
                                        }
                                    />
                                </div>
                            </div>
                            <Button
                                color={"softIndigo"}
                                rightIcon={<CircleHelpIcon size={16} />}
                                onClick={openCreditsModal}
                            >
                                What are Credits?
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    <AppText
                        variant="subheadings"
                        className="text-neutral-900"
                    >
                        Buy Credits
                    </AppText>
                    <CreditPackButtons buttonPrefix="Buy" />
                </div>
                <ProfileTransactions />
            </div>
        </div>
    );
};
