import clsx from "clsx";
import { Gift, Settings } from "lucide-react";
import { useCallback, useState } from "react";
import { NavLink as RouterNavLink, useParams } from "react-router-dom";
import { Logo } from "../Icons";
import { BalanceIndicator } from "./BalanceIndicator";
import { IconButton } from "@/components/Button/IconButton";
import { OneLogo } from "@/components/Icons/Icons";
import { AccountSettingsButton } from "@/components/Navigation/AccountSettingsButton";
import { AdminNavigation } from "@/components/Navigation/AdminNavigation";
import { BurgerMenu } from "@/components/Navigation/BurgerMenu";
import { CreateMenu } from "@/components/Navigation/CreateMenu";
import { MobileMenu } from "@/components/Navigation/MobileMenu";
import { NavigationPill } from "@/components/Navigation/NavigationPill";
import SavingIndicator from "@/components/Navigation/SavingIndicator";
import { ResumeName } from "@/components/ResumeName/ResumeName";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowBonusNavbarButton } from "@/store/app/selectors";
import { setShowBonusNavbarButton } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { getActiveResume } from "@/store/resume/selectors";
import { getIsAdminUser } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";
import { getIsPreviewPage, getIsResumePage } from "@/utils/routes";

export const Navigation = () => {
    const { id } = useParams();
    const isAdminPage = window.location.pathname.includes("admin");
    const isAdmin = useAppSelector(getIsAdminUser);
    const isPreviewPage = getIsPreviewPage();
    const isResumePage = getIsResumePage();
    const activeResume = useAppSelector(getActiveResume);
    const showBonusNavbarButton = useAppSelector(getShowBonusNavbarButton);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const dispatch = useAppDispatch();

    const toggleMobileMenu = useCallback(() => {
        setIsMobileMenuOpen(prev => !prev);
    }, []);
    const openCreditsWelcomeModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_WELCOME }));
        dispatch(setShowBonusNavbarButton(false));
    }, [dispatch]);

    return (
        <>
            <div
                className={clsx(
                    "fixed top-0 z-20  border-b-[1px] border-neutral-300 bg-neutral-50",
                    isPreviewPage ? "w-auto lg:w-full" : "w-full",
                )}
            >
                <div className="bg-neutral-white mb-0 rounded-xl px-3">
                    <div className="relative flex h-navigationHeight items-center justify-between">
                        {/* Desktop Navigation Header */}
                        <div className="hidden items-center gap-3 lg:flex">
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                            >
                                {isResumePage ? (
                                    <OneLogo
                                        width={36}
                                        height={36}
                                        variant="dark"
                                    />
                                ) : (
                                    <Logo className="h-auto w-[150px]" />
                                )}
                            </RouterNavLink>
                            {isResumePage && <CreateMenu />}
                            <NavigationPill
                                to={AppRoutes.Dashboard}
                                label="Dashboard"
                                variant="white"
                            />
                            <NavigationPill
                                to={`/${AppRoutes.Examples}`}
                                label="Resume Examples"
                                variant="white"
                            />
                            {isResumePage && activeResume && (
                                <ResumeName
                                    resumeId={activeResume.id}
                                    resumeName={activeResume.name}
                                    isBuilderPage={true}
                                />
                            )}
                        </div>
                        <div className="hidden flex-row items-center gap-4 lg:flex">
                            {isResumePage && activeResume && (
                                <SavingIndicator />
                            )}
                            {showBonusNavbarButton && (
                                <button
                                    onClick={openCreditsWelcomeModal}
                                    className="group relative rounded-full p-2 transition-all duration-300 hover:scale-110"
                                >
                                    <span className="absolute inset-0 animate-ping rounded-full bg-primary-200/20" />
                                    <span className="absolute inset-0 rounded-full bg-primary-200/20" />
                                    <Gift className="relative h-6 w-6 text-primary-700 transition-transform duration-300 group-hover:rotate-12" />
                                </button>
                            )}
                            <BalanceIndicator />
                            <AccountSettingsButton />
                            {isAdmin && (
                                <IconButton
                                    variant={"solid"}
                                    color={"red"}
                                    to="/admin/stats"
                                >
                                    <Settings className="h-5 w-5" />
                                </IconButton>
                            )}
                        </div>
                        {/* Mobile Navigation Header */}
                        <div
                            className="flex w-full items-center justify-between gap-2 lg:hidden"
                            style={{
                                width: isPreviewPage
                                    ? "calc(100vw - 24px)"
                                    : "",
                            }}
                        >
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                                className="hidden xs:block"
                            >
                                <Logo className="h-5 w-auto sm:h-4" />
                            </RouterNavLink>
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                                className="block xs:hidden"
                            >
                                <OneLogo className="h-8 w-auto sm:h-4" />
                            </RouterNavLink>
                            <div className="flex items-center gap-2">
                                <BalanceIndicator />
                                <BurgerMenu
                                    isOpen={isMobileMenuOpen}
                                    toggle={toggleMobileMenu}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isAdminPage && <AdminNavigation />}
            </div>
            <MobileMenu
                isOpen={isMobileMenuOpen}
                onClose={toggleMobileMenu}
            />
            {/* Spacer for navigation */}
            <div
                className={clsx(
                    "navigation-spacer bg-neutral-100",
                    (!id || isAdminPage || isPreviewPage) &&
                        "h-navigationHeight",
                )}
            />
        </>
    );
};
