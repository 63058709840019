import clsx from "clsx";
import { SidebarIcon, SquareIcon } from "lucide-react";
import { useEffect, useState } from "react";
import resumeApi from "@/api/resume";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getMeasurements } from "@/store/pages/selectors";
import { updateMeasurements } from "@/store/pages/slice";
import {
    getSectionsByActiveDocumentType,
    getActiveResume,
} from "@/store/resume/selectors";
import { setActiveResume } from "@/store/resume/slice";
import {
    getSectionIdsWithNewColumnIndexes,
    setResumeSectionsIndexes,
} from "@/store/resume/utils";
import { ResumeSections } from "@/types";
import { ColumnLayout } from "@/types/resume";

interface LayoutTogglesProps {
    onLayoutChange?: (layout: ColumnLayout) => void;
    overrideActiveLayout?: ColumnLayout;
}

export const LayoutToggles = ({
    onLayoutChange,
    overrideActiveLayout,
}: LayoutTogglesProps) => {
    const sections = useAppSelector(getSectionsByActiveDocumentType);
    const measurements = useAppSelector(getMeasurements);
    const activeResume = useAppSelector(getActiveResume);
    const [activeLayout, setActiveLayout] = useState<ColumnLayout>(
        overrideActiveLayout ||
            activeResume?.columnLayout ||
            ColumnLayout.SINGLE,
    );
    const dispatch = useAppDispatch();

    useEffect(() => {
        setActiveLayout(
            overrideActiveLayout ||
                activeResume?.columnLayout ||
                ColumnLayout.SINGLE,
        );
    }, [overrideActiveLayout, activeResume]);

    const setColumnLayout = (layout: ColumnLayout) => {
        if (!activeResume?.id) {
            onLayoutChange?.(layout);
            setActiveLayout(layout);
            return;
        }
        const resumeSections: ResumeSections = JSON.parse(
            JSON.stringify(activeResume.sections),
        );

        const resumeSectionsWithIndex = setResumeSectionsIndexes(
            resumeSections,
            layout,
            sections,
            measurements,
        );

        // Update measurements to ensure they have the correct column index set
        const sectionsWithNewColumnIndexes = getSectionIdsWithNewColumnIndexes(
            measurements,
            resumeSectionsWithIndex,
            sections,
        );

        resumeApi
            .saveResume(activeResume.id, {
                columnLayout: layout,
                sections: resumeSectionsWithIndex,
            })
            .then(resume => {
                dispatch(setActiveResume({ ...resume }));
                dispatch(updateMeasurements(sectionsWithNewColumnIndexes));
                setActiveLayout(layout);
            });
    };

    return (
        <div className="grid h-10 grid-cols-2 gap-1 rounded bg-neutral-100 p-1">
            <Tooltip
                placement="bottom"
                openDelay={200}
            >
                <TooltipTrigger>
                    <button
                        onClick={() => setColumnLayout(ColumnLayout.SINGLE)}
                        className={clsx(
                            "flex w-full flex-col items-center justify-center gap-1 rounded-lg p-1",
                            activeLayout === ColumnLayout.SINGLE
                                ? "bg-primary-900 text-white hover:bg-primary-800"
                                : "bg-transparent text-neutral-700 hover:bg-primary-200 hover:text-neutral-900",
                        )}
                    >
                        <SquareIcon size={24} />
                    </button>
                </TooltipTrigger>
                <TooltipContent>
                    <AppText variant="footnote">Full Page</AppText>
                </TooltipContent>
            </Tooltip>

            <Tooltip
                placement="bottom"
                openDelay={200}
            >
                <TooltipTrigger>
                    <button
                        onClick={() => setColumnLayout(ColumnLayout.DOUBLE)}
                        className={clsx(
                            "flex w-full flex-col items-center justify-center gap-1 rounded-lg p-1",
                            activeLayout === ColumnLayout.DOUBLE
                                ? "bg-primary-900 text-white hover:bg-primary-800"
                                : "bg-transparent text-neutral-700 hover:bg-primary-200 hover:text-neutral-900",
                        )}
                    >
                        <SidebarIcon
                            size={24}
                            className="rotate-180"
                        />
                    </button>
                </TooltipTrigger>
                <TooltipContent>
                    <AppText variant="footnote">Split Page</AppText>
                </TooltipContent>
            </Tooltip>
        </div>
    );
};
