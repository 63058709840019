import { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import HtmlMapper from "react-html-map";
import { MemoBlock } from "@/components/EditorComponents/Block";
import { BodyText } from "@/components/EditorComponents/BodyText";
import { Image } from "@/components/EditorComponents/Image";
import { Title } from "@/components/EditorComponents/Title";
import { Widget } from "@/components/EditorComponents/Widget";
import { ComponentPropsObject, ResumeStyles } from "@/types/resume";
import { SectionFieldsConfig } from "@/types/section";

interface EditableMapperProps {
    html: string;
    sectionId: string;
    visibleProps: ComponentPropsObject;
    propType: "header" | "body";
    itemId?: string;
    onClick?: (ids: { sectionId: string; itemId?: string }) => void;
    fieldsConfig?: SectionFieldsConfig;
    showUnderline?: boolean;
    isStaticHeader?: boolean;
    stylesConfig: ResumeStyles;
    layoutProps?: ComponentPropsObject;
}

const EditableMapper = ({
    html,
    sectionId,
    visibleProps,
    isStaticHeader,
    propType,
    itemId,
    onClick,
    fieldsConfig = {},
    stylesConfig,
    layoutProps,
}: EditableMapperProps) => {
    return (
        <HtmlMapper html={html}>
            {{
                p: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLParagraphElement>,
                        HTMLParagraphElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <BodyText
                            id={props.id}
                            itemId={itemId}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            propType={propType}
                            onClick={onClick}
                            icon={fieldsConfig[props.id]?.icon}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                            stylesConfig={stylesConfig}
                            isStaticHeader={isStaticHeader}
                        />
                    );
                },
                h1: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Title
                            id={props.id}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            variant="h1"
                            propType={propType}
                            itemId={itemId}
                            icon={fieldsConfig[props.id].icon}
                            placeholder={fieldsConfig[props.id].placeholder}
                            isStaticHeader={!!isStaticHeader}
                            stylesConfig={stylesConfig}
                        />
                    );
                },
                h2: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Title
                            variant="h2"
                            id={props.id}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            propType={propType}
                            itemId={itemId}
                            icon={fieldsConfig[props.id]?.icon}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                        />
                    );
                },
                h3: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Title
                            variant="h3"
                            id={props.id}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            propType={propType}
                            itemId={itemId}
                            icon={fieldsConfig[props.id]?.icon}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                        />
                    );
                },
                h4: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Title
                            variant="h4"
                            id={props.id}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            propType={propType}
                            itemId={itemId}
                            icon={fieldsConfig[props.id]?.icon}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                        />
                    );
                },
                h5: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLHeadingElement>,
                        HTMLHeadingElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Title
                            variant="h5"
                            id={props.id}
                            sectionId={sectionId}
                            value={visibleProps?.[props.id]}
                            propType={propType}
                            itemId={itemId}
                            icon={fieldsConfig[props.id]?.icon}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                            stylesConfig={stylesConfig}
                            isStaticHeader={!!isStaticHeader}
                        />
                    );
                },
                div: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLDivElement>,
                        HTMLDivElement
                    >,
                ) => (
                    <MemoBlock
                        {...props}
                        className={props.class || props.className}
                        id={props.id}
                        value={layoutProps?.[props.id]}
                        stylesConfig={stylesConfig}
                        layoutProps={layoutProps}
                    />
                ),
                img: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLImageElement>,
                        HTMLImageElement
                    >,
                ) => {
                    return visibleProps?.[props.id] ? (
                        <Image
                            key={visibleProps?.[props.id]}
                            src={visibleProps?.[props.id]}
                            sectionId={sectionId}
                            propId={props.id}
                            propType={propType}
                            editable={true}
                            inlineStyles={props.style}
                            showPreview
                            stylesConfig={stylesConfig}
                        />
                    ) : null;
                },
                span: (
                    props: DetailedHTMLProps<
                        HTMLAttributes<HTMLSpanElement>,
                        HTMLSpanElement
                    >,
                ) => {
                    if (!props.id || visibleProps?.[props.id] === undefined)
                        return null;

                    return (
                        <Widget
                            sectionId={sectionId}
                            propId={props.id}
                            propType={propType}
                            editable={true}
                            type={fieldsConfig[props.id]?.type}
                            value={visibleProps?.[props.id]}
                            itemId={itemId}
                            icon={fieldsConfig[props.id]?.icon}
                            stylesConfig={stylesConfig}
                            placeholder={fieldsConfig[props.id]?.placeholder}
                        />
                    );
                },
            }}
        </HtmlMapper>
    );
};

export const MemoEditableMapper = memo(
    EditableMapper,
    (prevProps, nextProps) => {
        // Hacky way to force re-render on image section
        const isImageSection = nextProps.sectionId === "IMAGE";
        if (isImageSection) {
            const newImage = nextProps.visibleProps.image;
            const oldImage = prevProps.visibleProps.image;
            const isSameImage = newImage === oldImage;
            return isSameImage;
        }

        // Compare visible props deeply but only for changed values
        const prevVisibleProps = prevProps.visibleProps;
        const nextVisibleProps = nextProps.visibleProps;

        // Get all keys from both objects
        const allKeys = new Set([
            ...Object.keys(prevVisibleProps || {}),
            ...Object.keys(nextVisibleProps || {}),
        ]);

        // Check if any values have changed
        for (const key of allKeys) {
            if (prevVisibleProps?.[key] !== nextVisibleProps?.[key]) {
                // If this is a text content change, don't trigger a re-render
                // The editor will handle the update internally
                if (
                    typeof prevVisibleProps?.[key] === "string" &&
                    typeof nextVisibleProps?.[key] === "string"
                ) {
                    continue;
                }
                return false;
            }
        }

        return (
            prevProps.html === nextProps.html &&
            prevProps.stylesConfig === nextProps.stylesConfig
        );
    },
);
