import clsx from "clsx";
import { Eye } from "lucide-react";
import { useCallback } from "react";
import { AdminResumeDetails } from "@/components/Admin/AdminResumeDetails";
import { Button } from "@/components/Button/Button";
import { Page } from "@/components/Page/Page";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch } from "@/hooks/types";
import { ReadOnlyResumeView } from "@/pages/Dashboard/ReadOnlyResumeView/ReadOnlyResumeView";
import { TemplateDetails } from "@/pages/Dashboard/TemplateDetails";
import { resumeToPages } from "@/pages/Examples/utils";
import { openModal } from "@/store/modal/slice";
import { Resume } from "@/types";
import { ModalTypes } from "@/types/modal";
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from "@/types/tracking";
import { trackGAEvent } from "@/utils/tracking";

interface ResumeExampleProps {
    resume: Resume;
}

export const ResumeExample = ({ resume }: ResumeExampleProps) => {
    const dispatch = useAppDispatch();
    const resumePages = resumeToPages(resume);
    const openResumePreview = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.RESUME_PREVIEW,
                props: { resume, resumePages, isResumeExample: true },
            }),
        );
        trackGAEvent(
            GA_EVENT_CATEGORIES.BUTTON,
            GA_EVENT_ACTIONS.CLICK,
            "Example resume previewed",
        );
    }, [dispatch, resume, resumePages]);

    return (
        <div className="flex flex-col rounded-lg bg-white">
            <li className="resume-container group relative rounded-lg ring-2 ring-neutral-400">
                <AdminResumeDetails resume={resume} />
                <div className="resume-item group block cursor-pointer overflow-hidden rounded-lg ring-2 ring-neutral-400">
                    <Page
                        pageNumber={1}
                        overrideResumeStyles={resume.styles}
                    >
                        <ReadOnlyResumeView resume={resume} />
                    </Page>
                </div>
                <div className="absolute bottom-0 left-0 right-0 flex items-center justify-between gap-4 border-t-2 border-neutral-400 bg-white p-4">
                    <div className="min-w-0 flex-1 overflow-hidden">
                        <TemplateDetails
                            name={resume.name}
                            tags={resume.tags}
                            categories={resume.categories}
                        />
                    </div>
                </div>
                <div
                    className={clsx(
                        "absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100",
                    )}
                >
                    <Button
                        className="z-10 hidden lg:block"
                        onClick={openResumePreview}
                        leftIcon={<Eye size={16} />}
                    >
                        <AppText variant="labelsbuttons">View</AppText>
                    </Button>
                </div>
            </li>
        </div>
    );
};
