import clsx from "clsx";
import { useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Banner } from "@/components/Banner/Banner";
import { Logo } from "@/components/Icons";
import { AppText } from "@/components/Text/AppText";
import { useTransaction } from "@/hooks/useTransaction";
import { CreditActionCode } from "@/types/creditAction";
import { AppRoutes } from "@/types/routes";
import {
    getIsLoginPage,
    getIsNewResumePage,
    getIsPreferencesPage,
    getIsSignUpPage,
} from "@/utils/routes";

interface NoNavPageContainerProps {
    children: React.ReactNode;
    footer?: React.ReactNode;
}

export const NoNavPageContainer = ({
    children,
    footer,
}: NoNavPageContainerProps) => {
    const isPreferences = getIsPreferencesPage();
    const isNewResume = getIsNewResumePage();
    const isSignUp = getIsSignUpPage();
    const [searchParams] = useSearchParams();
    const hasRedirect = !!searchParams.get("redirect");
    const isLogin = getIsLoginPage();
    const { getCreditActionByCode } = useTransaction();
    const signUpBonusAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.SIGN_UP_BONUS),
        [getCreditActionByCode],
    );
    const signUpCreditAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.SIGN_UP),
        [getCreditActionByCode],
    );

    const totalCredits = useMemo(() => {
        return (
            (signUpCreditAction?.credits ?? 0) +
            (signUpBonusAction?.credits ?? 0)
        );
    }, [signUpCreditAction, signUpBonusAction]);

    const showBanner = signUpBonusAction && (isSignUp || isLogin);

    return (
        <div className="relative flex min-h-screen flex-col bg-neutral-100 px-4 lg:px-6">
            <div className="py-4">
                {isPreferences && !hasRedirect ? (
                    <span
                        aria-label="Preferences"
                        className="cursor-default"
                    >
                        <Logo className="m-auto h-auto w-[150px]" />
                    </span>
                ) : (
                    <Link
                        to={AppRoutes.Dashboard}
                        aria-label="Home"
                    >
                        <Logo className="m-auto h-auto w-[150px]" />
                    </Link>
                )}
            </div>

            <div
                className={clsx(
                    "mx-auto mb-4 flex flex-col rounded-lg bg-white",
                    isSignUp || isPreferences || isNewResume
                        ? "mt-4"
                        : "mt-4 lg:mt-16",
                    isPreferences
                        ? "relative max-w-[1000px] overflow-y-scroll p-6 md:w-full"
                        : isNewResume
                          ? "max-w-[1072px] p-6"
                          : "max-w-[800px] p-8 lg:min-w-[650px]",
                    showBanner && "relative !pt-16",
                )}
            >
                {showBanner && (
                    <Banner
                        gradient
                        className="absolute left-0 right-0 top-0 mx-auto w-full rounded-b-none"
                    >
                        <AppText variant="contextheading">
                            Limited offer: Receive {totalCredits} bonus credits
                            on sign up!
                        </AppText>
                    </Banner>
                )}
                <div className={clsx(!showBanner && "!pt-")}>{children}</div>
            </div>
            {footer}
        </div>
    );
};
