import {
    Atom,
    BarChart,
    Compass,
    FileType2,
    LayoutTemplate,
    Palette,
    UsersRound,
} from "lucide-react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { CreditsIcon } from "@/components/Icons/Icons";
import { getIsNonProductionEnv } from "@/utils/env";

export const AdminNavigation = () => {
    const currentPath = useLocation();

    const getButtonProps = useCallback(
        (tab: string) => {
            if (currentPath.pathname.includes(tab)) {
                return {
                    variant: "solid",
                    color: "softIndigo",
                    className: "rounded-b-none",
                };
            }
            return {
                variant: "text",
                color: "primary",
            };
        },
        [currentPath],
    );

    return (
        <div className="flex items-center overflow-x-auto">
            <Button
                {...getButtonProps("stats")}
                to="/admin/stats"
                leftIcon={<BarChart className="h-5 w-5" />}
            >
                Stats
            </Button>
            {getIsNonProductionEnv() && (
                <Button
                    {...getButtonProps("ai")}
                    to="/admin/ai"
                    leftIcon={<Atom className="h-5 w-5" />}
                >
                    AI
                </Button>
            )}
            <Button
                {...getButtonProps("assistant")}
                to="/admin/assistant"
                leftIcon={<Compass className="h-5 w-5" />}
            >
                Assistant Items
            </Button>
            <Button
                {...getButtonProps("credit-actions")}
                to="/admin/credit-actions"
                leftIcon={<CreditsIcon className="h-5 w-5" />}
            >
                Credit Actions
            </Button>
            <Button
                {...getButtonProps("document-types")}
                to="/admin/document-types"
                leftIcon={<FileType2 className="h-5 w-5" />}
            >
                Document Types
            </Button>
            <Button
                {...getButtonProps("sections")}
                to="/admin/sections"
                leftIcon={<LayoutTemplate className="h-5 w-5" />}
            >
                Sections
            </Button>
            <Button
                {...getButtonProps("templates")}
                to="/admin/templates"
                leftIcon={<LayoutTemplate className="h-5 w-5" />}
            >
                Templates
            </Button>
            <Button
                {...getButtonProps("users")}
                to="/admin/users"
                leftIcon={<UsersRound className="h-5 w-5" />}
            >
                Users
            </Button>
            <Button
                {...getButtonProps("styles")}
                to="/admin/styles"
                leftIcon={<Palette className="h-5 w-5" />}
            >
                Styles
            </Button>
        </div>
    );
};
