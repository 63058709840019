import { useNavigate } from "react-router-dom";
import { IconButton } from "@/components/Button/IconButton";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { getUserInitials } from "@/store/user/selectors";
import { AppRoutes } from "@/types/routes";

interface AccountSettingsButtonProps {
    onClick?: () => void;
}

export const AccountSettingsButton = ({
    onClick,
}: AccountSettingsButtonProps) => {
    const navigate = useNavigate();
    const goToProfile = () => {
        navigate(`/${AppRoutes.Profile}`);
        onClick?.();
    };
    const userInitials = useAppSelector(getUserInitials);

    return (
        <IconButton
            variant={"solid"}
            color={"grey"}
            onClick={goToProfile}
        >
            <AppText variant="labelsbuttons">{userInitials}</AppText>
        </IconButton>
    );
};
