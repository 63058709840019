import React from "react";
import { useTooltipContext } from "@/components/Tooltip/TooltipContext";
import { useMergeRefs, FloatingPortal } from "@floating-ui/react";

export const TooltipContent = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.open) return null;

    return (
        <FloatingPortal>
            <div
                className="z-50 flex items-center justify-center rounded-lg bg-neutral-700 p-2 text-center text-neutral-50 shadow-tooltip"
                ref={ref}
                style={{
                    ...context.floatingStyles,
                    ...style,
                }}
                {...context.getFloatingProps(props)}
            />
        </FloatingPortal>
    );
});
