import { useCallback } from 'react';
import billingApi from '@/api/billing';
import { useAppDispatch, useAppSelector } from '@/hooks/types';
import { getStripeCustomerId } from '@/store/user/selectors';
import { setProducts, setProductsError, setProductsLoading } from '@/store/billing/slice';

interface StripeHook {
    getStripeProducts: () => Promise<void>;
    createPaymentIntent: (priceId: string) => Promise<{ id: string; clientSecret: string; amount: number; } | null>;
}

export const useStripe = (): StripeHook => {
    const dispatch = useAppDispatch();
    const stripeCustomerId = useAppSelector(getStripeCustomerId) ?? "";

    const createPaymentIntent = useCallback(async (priceId: string) => {
        try {
            const intent = await billingApi.createPaymentIntent({ priceId, stripeCustomerId });
            return { id: intent.id, clientSecret: intent.clientSecret, amount: intent.amount };
        } catch (error) {
            console.error('Failed to create payment intent:', error);
            return null;
        }
    }, [stripeCustomerId]);

    const getStripeProducts = useCallback(async () => {
        try {
            dispatch(setProductsLoading());
            const products = await billingApi.getStripeProducts();
            dispatch(setProducts(products));
        } catch (error) {
            console.error('Failed to get Stripe products:', error);
            dispatch(setProductsError(error as string));
        }
    }, [dispatch]);

    return { getStripeProducts, createPaymentIntent };
};