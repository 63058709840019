import Chip from "@/components/Chip/Chip";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { getIsExamplesPage } from "@/utils/routes";

interface TemplateDetailsProps {
    name: string;
    updatedAt?: Date;
    tags?: string[];
    categories?: string[];
}

export const TemplateDetails = ({
    name,
    updatedAt,
    tags,
    categories,
}: TemplateDetailsProps) => {
    const allChips = [...(tags || []), ...(categories || [])];
    const isExamplePage = getIsExamplesPage();
    return (
        <>
            <Tooltip placement="top">
                <TooltipTrigger className="w-full text-left">
                    <div className="group relative w-full overflow-hidden">
                        <AppText
                            variant="subheadings"
                            className="block cursor-default truncate"
                        >
                            {name}
                        </AppText>
                    </div>
                </TooltipTrigger>
                <TooltipContent style={{ maxWidth: "300px" }}>
                    <AppText variant="footnote">{name}</AppText>
                </TooltipContent>
            </Tooltip>
            {updatedAt && (
                <AppText
                    variant="labelsbuttons"
                    className="truncate"
                >
                    Last edited: {updatedAt.toLocaleDateString()}
                </AppText>
            )}
            {isExamplePage && (
                <div className="flex min-h-[26px] flex-wrap gap-2">
                    {allChips.map(chip => (
                        <Chip
                            key={chip}
                            type="secondary"
                        >
                            <AppText
                                variant="footnote"
                                className="!font-semibold"
                            >
                                {chip}
                            </AppText>
                        </Chip>
                    ))}
                </div>
            )}
        </>
    );
};
