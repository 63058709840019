import { IPromptHistory } from "@/types/promptHistory";
import { apiRequest } from "./request";
import { ApiPaginationRequest } from "@/types";

const getUserPromptHistory = (params?: ApiPaginationRequest) => {
    return apiRequest<IPromptHistory[]>("/prompt-history", "GET", params);
};

const deletePromptHistory = (promptHistoryId: string) =>
    apiRequest<void>(`/prompt-history/${promptHistoryId}`, "DELETE");

export const promptHistoryApi = {
    getUserPromptHistory,
    deletePromptHistory,
};
