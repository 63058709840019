import clsx from "clsx";
import {
    Calendar,
    ChevronDown,
    ChevronUp,
    Maximize2Icon,
    Minimize2,
    PlusIcon,
    Settings2,
    Trash2Icon,
    Compass,
} from "lucide-react";
import { useCallback, useContext, useMemo, useState } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { VerticalDivider } from "@/components/Divider/VerticalDivider";
import { SmartScribe } from "@/components/Icons/Icons";
import { DateLocationPresets } from "@/components/Presets/DateLocation/DateLocationPresets";
import { PresetIconButton } from "@/components/Presets/PresetIconButton";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { EditorContext } from "@/context/EditorContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useContentEditor } from "@/hooks/useContentEditor";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import {
    getAiToolboxIsVisible,
    getDatePickerId,
    getShowSideBar,
    getSideBarState,
} from "@/store/app/selectors";
import {
    addNotification,
    resetAiToolbox,
    setDatePickerId,
    setShowSideBar,
    setSideBarState,
    updateAiToolbox,
} from "@/store/app/slice";
import { getAssistantTabState } from "@/store/assistant/selectors";
import { setAssistantTabState } from "@/store/assistant/slice";
import { addItemMeasurement, removeItemMeasurement } from "@/store/pages/slice";
import {
    getActiveResumeSections,
    getSectionsByActiveDocumentType,
} from "@/store/resume/selectors";
import { getActiveSection, getActiveItemId } from "@/store/template/selectors";
import { setActiveItemId, setActiveSection } from "@/store/template/slice";
import {
    NotificationMessageType,
    NotificationType,
    SideBarState,
} from "@/types/app";
import { AssistantTabState } from "@/types/assistant";
import { CreditActionCode } from "@/types/creditAction";
import { EditorContextType } from "@/types/editor";
import { SectionItemDetails } from "@/types/resume";
import { getNewEmptyItem, getSectionConfigBySectionId } from "@/utils/section";
import { FloatingDelayGroup } from "@floating-ui/react";

interface ActionsToolbarProps {
    sectionItemId?: string;
    itemIndex?: number;
    isLastItem?: boolean;
}

export const ActionsToolbar = ({
    sectionItemId,
    itemIndex,
    isLastItem,
}: ActionsToolbarProps) => {
    const datePickerId = useAppSelector(getDatePickerId);
    const dispatch = useAppDispatch();
    const { updateTemplateSectionAndSave, removeSection } = useUpdateTemplate();
    const [isAddingItem, setIsAddingItem] = useState<boolean>(false);
    const [isRemovingContent, setIsRemovingContent] = useState<boolean>(false);
    const activeSection = useAppSelector(getActiveSection);
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const resumeSection = activeSection
        ? activeResumeSections?.[activeSection]
        : undefined;
    const sideBarState = useAppSelector(getSideBarState);
    const sideBarIsOpen = useAppSelector(getShowSideBar);
    const assistantState = useAppSelector(getAssistantTabState);
    const sectionConfigId = resumeSection?.sectionConfigId;
    const activeItemId = useAppSelector(getActiveItemId);
    const sectionConfigs = useAppSelector(getSectionsByActiveDocumentType);
    const sectionConfig = getSectionConfigBySectionId(
        sectionConfigs,
        sectionConfigId,
    );
    const isAiToolboxOpen = useAppSelector(getAiToolboxIsVisible);
    const { activeEditor } = useContentEditor();
    const { setActiveEditor } = useContext(EditorContext) as EditorContextType;
    const hasDateField = useMemo(() => {
        if (activeItemId) {
            // This means this is a toolbar for an item, not the section as a whole
            return sectionConfig?.bodyFields?.some(
                field => field.type.toLowerCase() === "date",
            );
        } else {
            return sectionConfig?.headerFields?.some(
                field => field.type.toLowerCase() === "date",
            );
        }
    }, [activeItemId, sectionConfig?.bodyFields, sectionConfig?.headerFields]);

    const hasVisibleAiFieldInSection = useMemo(() => {
        const aiFields = ["description", "accomplishmentdescription"];
        const hasField = sectionConfig?.bodyFields?.some(field =>
            aiFields.includes(field.name.toLowerCase()),
        );
        if (!hasField) return false;
        const sectionItem = resumeSection?.body?.find(
            item => item.__id === activeItemId,
        );
        if (!sectionItem) return false;
        const hiddenFields = sectionItem.hiddenFields;
        if (hiddenFields?.some(field => aiFields.includes(field.toLowerCase())))
            return false;
        return true;
    }, [sectionConfig?.bodyFields, resumeSection?.body, activeItemId]);

    const isActiveAiField = useMemo(() => {
        const editorAttributes = activeEditor?.options.editorProps.attributes;
        return editorAttributes?.["data-smart-scribe"] === "true";
    }, [activeEditor]);

    const canChangeWidth = false; // Disabling functionality as not supported yet.
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const totalItems = useMemo(
        () => resumeSection?.body?.length ?? 0,
        [resumeSection?.body?.length],
    );

    const handleToggleDatePicker = useCallback(() => {
        if (!datePickerId || (datePickerId && datePickerId !== sectionItemId)) {
            dispatch(setDatePickerId(sectionItemId));
            dispatch(resetAiToolbox());
        } else {
            dispatch(setDatePickerId(""));
        }
    }, [sectionItemId, dispatch, datePickerId]);

    const handleAddNewItem = useCallback(() => {
        setIsAddingItem(true);
        const newEmptyItem = getNewEmptyItem(sectionConfig?.bodyFields ?? []);
        const newItemIndex = (itemIndex ?? 0) + 1;
        dispatch(resetAiToolbox());

        if (activeResumeSections && activeSection) {
            updateTemplateSectionAndSave(
                activeSection,
                "body",
                newEmptyItem,
                newEmptyItem.__id,
                newItemIndex,
                success => {
                    if (success) {
                        dispatch(setActiveItemId(newEmptyItem.__id));
                        dispatch(
                            addItemMeasurement({
                                sectionId: activeSection,
                                item: newEmptyItem,
                                sectionConfigId: sectionConfigId ?? "",
                                itemIndex: newItemIndex,
                                columnIndex: resumeSection?.columnIndex ?? 0,
                            }),
                        );
                        setIsAddingItem(false);
                    } else {
                        dispatch(
                            addNotification({
                                messageType:
                                    NotificationMessageType.RESUME_ADD_ITEM,
                                title: "Error adding item",
                                desc: "An error occurred while adding the item, please try again.",
                                type: NotificationType.ERROR,
                            }),
                        );
                        setIsAddingItem(false);
                    }
                },
            );
        }
    }, [
        sectionConfig?.bodyFields,
        itemIndex,
        activeResumeSections,
        activeSection,
        updateTemplateSectionAndSave,
        dispatch,
        sectionConfigId,
        resumeSection?.columnIndex,
    ]);

    const removeItem = useCallback(
        (sectionId: string) => {
            if (!activeResumeSections) return;
            setIsRemovingContent(true);
            const body: SectionItemDetails[] = JSON.parse(
                JSON.stringify(activeResumeSections[sectionId].body),
            );
            const itemIndex = body.findIndex(b => b.__id === activeItemId);
            body.splice(itemIndex, 1);
            updateTemplateSectionAndSave(
                sectionId,
                "body",
                body,
                undefined,
                undefined,
                success => {
                    if (success) {
                        dispatch(removeItemMeasurement({ id: activeItemId }));
                        setIsRemovingContent(false);
                    } else {
                        dispatch(
                            addNotification({
                                messageType:
                                    NotificationMessageType.RESUME_REMOVE_ITEM,
                                title: "Error removing item",
                                desc: "An error occurred while removing the item, please try again.",
                                type: NotificationType.ERROR,
                            }),
                        );
                    }
                    dispatch(setActiveItemId(null));
                    dispatch(resetAiToolbox());
                },
            );
        },
        [
            activeItemId,
            activeResumeSections,
            dispatch,
            updateTemplateSectionAndSave,
        ],
    );

    const onDelete = useCallback(() => {
        if (!activeSection || !activeResumeSections) return;
        const deleteSectionId = activeSection;
        const deleteItemId = activeItemId;

        setIsRemovingContent(true);
        const shouldDeleteSection =
            !deleteItemId ||
            !activeResumeSections?.[deleteSectionId].body ||
            activeResumeSections?.[deleteSectionId].body.length === 1;

        if (shouldDeleteSection) {
            removeSection(deleteSectionId, success => {
                if (success) {
                    dispatch(setActiveSection());
                    setIsRemovingContent(false);
                } else {
                    dispatch(
                        addNotification({
                            messageType:
                                NotificationMessageType.RESUME_REMOVE_SECTION,
                            title: "Error removing section",
                            desc: "An error occurred while removing the section, please try again.",
                            type: NotificationType.ERROR,
                        }),
                    );
                }
            });
        } else {
            removeItem(deleteSectionId);
        }
        dispatch(setActiveItemId(null));
        dispatch(resetAiToolbox());
        setActiveEditor();
    }, [
        activeSection,
        activeResumeSections,
        dispatch,
        activeItemId,
        removeSection,
        setActiveEditor,
        removeItem,
    ]);

    const moveItem = useCallback(
        (direction: "up" | "down", itemId: string) => {
            if (
                !activeSection ||
                !activeResumeSections ||
                activeItemId === null
            ) {
                return;
            }

            const body: SectionItemDetails[] = JSON.parse(
                JSON.stringify(activeResumeSections[activeSection].body),
            );

            const currentIndex = body.findIndex(item => item.__id === itemId);

            if (direction === "up" && currentIndex > 0) {
                const temp = body[currentIndex];
                body[currentIndex] = body[currentIndex - 1];
                body[currentIndex - 1] = temp;
            } else if (
                direction === "down" &&
                currentIndex < (body?.length ?? 0) - 1
            ) {
                const temp = body[currentIndex];
                body[currentIndex] = body[currentIndex + 1];
                body[currentIndex + 1] = temp;
            }

            dispatch(setActiveItemId(itemId));
            updateTemplateSectionAndSave(activeSection, "body", body);
        },
        [
            activeSection,
            dispatch,
            activeResumeSections,
            activeItemId,
            updateTemplateSectionAndSave,
        ],
    );

    const handleShowToolbox = useCallback(() => {
        dispatch(
            updateAiToolbox({
                promptActionCode: CreditActionCode.AI_GENERATE_SUMMARY,
                source: "actions-toolbar",
                stage: "input",
                isVisible: !isAiToolboxOpen,
            }),
        );
    }, [dispatch, isAiToolboxOpen]);

    const handleResize = useCallback(() => {
        const newWidth =
            resumeSection?.activeWidth === "full" ? "half" : "full";
        updateTemplateSectionAndSave(activeSection!, "activeWidth", newWidth);
    }, [
        activeSection,
        resumeSection?.activeWidth,
        updateTemplateSectionAndSave,
    ]);

    return (
        <div
            id="actions-toolbar"
            className="absolute -top-16 left-1/2 z-10 flex h-[52px] w-max -translate-x-1/2 transform items-center justify-center gap-2 rounded-lg bg-neutral-50 px-2 font-body shadow-tooltip ring-2 ring-neutral-300"
        >
            <FloatingDelayGroup delay={{ open: 100, close: 0 }}>
                {isDeleting ? (
                    <>
                        <AppText variant="regular">
                            Confirm delete{" "}
                            {activeItemId && totalItems > 1
                                ? "item"
                                : "section"}
                            ?
                        </AppText>
                        <VerticalDivider className="max-h-9" />
                    </>
                ) : (
                    <>
                        {hasVisibleAiFieldInSection && activeItemId && (
                            <Tooltip>
                                <TooltipTrigger>
                                    <IconButton
                                        id="actions-toolbar-smart-scribe-button"
                                        variant={
                                            isAiToolboxOpen
                                                ? "solid"
                                                : "outline"
                                        }
                                        color={"gradient"}
                                        onClick={handleShowToolbox}
                                        disabled={!isActiveAiField}
                                    >
                                        <SmartScribe
                                            className="h-5 w-5"
                                            gradient={!isAiToolboxOpen}
                                        />
                                    </IconButton>
                                </TooltipTrigger>
                                <TooltipContent style={{ maxWidth: "200px" }}>
                                    <div className="flex flex-col items-center">
                                        <AppText
                                            variant="footnote"
                                            className="!font-semibold"
                                        >
                                            Smart Scribe
                                        </AppText>
                                        {isActiveAiField ? (
                                            <AppText variant="footnote">
                                                Write, rewrite, or improve your
                                                section item content.
                                            </AppText>
                                        ) : (
                                            <AppText variant="footnote">
                                                Select the description field to
                                                enable for this item.
                                            </AppText>
                                        )}
                                    </div>
                                </TooltipContent>
                            </Tooltip>
                        )}
                        {totalItems > 0 && (
                            <>
                                <Button
                                    variant={"outline"}
                                    color={"primary"}
                                    leftIcon={<PlusIcon size={16} />}
                                    onClick={handleAddNewItem}
                                    loading={isAddingItem}
                                    disabled={isAddingItem}
                                >
                                    New item
                                </Button>
                                <VerticalDivider className="max-h-9" />
                            </>
                        )}
                        {canChangeWidth && (
                            <>
                                <IconButton
                                    variant={"solid"}
                                    color={"white"}
                                    onClick={handleResize}
                                >
                                    {resumeSection?.activeWidth === "full" ? (
                                        <Minimize2
                                            size={16}
                                            className="rotate-45"
                                        />
                                    ) : (
                                        <Maximize2Icon
                                            size={16}
                                            className="rotate-45"
                                        />
                                    )}
                                </IconButton>
                                <VerticalDivider className="max-h-9" />
                            </>
                        )}
                        {totalItems > 1 && activeItemId && sectionItemId && (
                            <>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <IconButton
                                            variant={"solid"}
                                            color={"white"}
                                            disabled={itemIndex === 0}
                                            onClick={() =>
                                                moveItem("up", sectionItemId)
                                            }
                                        >
                                            <ChevronUp size={16} />
                                        </IconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <AppText variant="footnote">
                                            Move item up
                                        </AppText>
                                    </TooltipContent>
                                </Tooltip>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <IconButton
                                            variant={"solid"}
                                            color={"white"}
                                            disabled={isLastItem}
                                            onClick={() =>
                                                moveItem("down", sectionItemId)
                                            }
                                        >
                                            <ChevronDown size={16} />
                                        </IconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <AppText variant="footnote">
                                            Move item down
                                        </AppText>
                                    </TooltipContent>
                                </Tooltip>
                                <VerticalDivider className="max-h-9" />
                            </>
                        )}
                    </>
                )}
                {!isDeleting && (
                    <>
                        <Tooltip>
                            <TooltipTrigger>
                                <IconButton
                                    onClick={() => {
                                        dispatch(resetAiToolbox());
                                        if (
                                            sideBarState ===
                                                SideBarState.EDIT &&
                                            sideBarIsOpen
                                        ) {
                                            dispatch(setShowSideBar(false));
                                        } else {
                                            dispatch(
                                                setSideBarState(
                                                    SideBarState.EDIT,
                                                ),
                                            );
                                            dispatch(setShowSideBar(true));
                                        }
                                    }}
                                    variant={"solid"}
                                    color={"white"}
                                    className={clsx(
                                        sideBarState === SideBarState.EDIT &&
                                            sideBarIsOpen &&
                                            "!bg-neutral-200",
                                    )}
                                >
                                    <Settings2 size={16} />
                                </IconButton>
                            </TooltipTrigger>
                            <TooltipContent>
                                <AppText variant="footnote">
                                    Section settings
                                </AppText>
                            </TooltipContent>
                        </Tooltip>
                        <Tooltip>
                            <TooltipTrigger>
                                <IconButton
                                    onClick={() => {
                                        dispatch(resetAiToolbox());
                                        if (
                                            sideBarState ===
                                                SideBarState.ASSISTANT &&
                                            assistantState ===
                                                AssistantTabState.SECTION &&
                                            sideBarIsOpen
                                        ) {
                                            dispatch(setShowSideBar(false));
                                        } else {
                                            dispatch(
                                                setAssistantTabState(
                                                    AssistantTabState.SECTION,
                                                ),
                                            );
                                            dispatch(
                                                setSideBarState(
                                                    SideBarState.ASSISTANT,
                                                ),
                                            );
                                            dispatch(setShowSideBar(true));
                                        }
                                    }}
                                    variant={"solid"}
                                    color={"white"}
                                    className={clsx(
                                        sideBarState ===
                                            SideBarState.ASSISTANT &&
                                            assistantState ===
                                                AssistantTabState.SECTION &&
                                            sideBarIsOpen &&
                                            "!bg-neutral-200",
                                    )}
                                >
                                    <Compass size={16} />
                                </IconButton>
                            </TooltipTrigger>
                            <TooltipContent>
                                <AppText variant="footnote">Assistant</AppText>
                            </TooltipContent>
                        </Tooltip>
                        {hasDateField && (
                            <>
                                <VerticalDivider className="max-h-9" />
                                <Tooltip>
                                    <TooltipTrigger>
                                        <IconButton
                                            variant={"solid"}
                                            color={"white"}
                                            onClick={handleToggleDatePicker}
                                            className={clsx(
                                                datePickerId ===
                                                    sectionItemId &&
                                                    "!bg-neutral-200",
                                            )}
                                        >
                                            <Calendar size={16} />
                                        </IconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <AppText variant="footnote">
                                            Date picker
                                        </AppText>
                                    </TooltipContent>
                                </Tooltip>
                                <Tooltip>
                                    <TooltipTrigger>
                                        <PresetIconButton
                                            title="Section Presets"
                                            anchorTo="top"
                                            type="section"
                                        >
                                            <DateLocationPresets />
                                        </PresetIconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <AppText variant="footnote">
                                            Section layout presets
                                        </AppText>
                                    </TooltipContent>
                                </Tooltip>
                            </>
                        )}
                        <VerticalDivider className="max-h-9" />
                    </>
                )}
                <Tooltip>
                    <TooltipTrigger>
                        <IconButton
                            onClick={() => {
                                if (isDeleting) {
                                    onDelete();
                                } else {
                                    setIsDeleting(true);
                                }
                            }}
                            variant={"solid"}
                            loading={isRemovingContent}
                            disabled={isRemovingContent}
                            color={isDeleting ? "red" : "white"}
                        >
                            <Trash2Icon size={16} />
                        </IconButton>
                    </TooltipTrigger>
                    <TooltipContent>
                        <AppText variant="footnote">Delete</AppText>
                    </TooltipContent>
                </Tooltip>
            </FloatingDelayGroup>
        </div>
    );
};
