import { pdf } from "@react-pdf/renderer";
import clsx from "clsx";
import { Download, Files, Send } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import "../../../pages/ResumeBuilder/resumeStyles.css";
import { Button } from "@/components/Button/Button";
import { CreditActionSummary } from "@/components/Credits/CreditActionSummary";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { Modal } from "@/components/Modal/Modal";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { PdfDocumentPreview } from "@/components/PdfPreview/PdfDocumentPreview";
import { AppText } from "@/components/Text/AppText";
import { sendDocumentAttachmentEmail } from "@/email/send";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { addNotification, setShowPlansModal } from "@/store/app/slice";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import {
    A4_PAGE_HEIGHT_PIXELS,
    A4_PAGE_WIDTH_PIXELS,
} from "@/store/pages/constants";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResume,
    getActiveResumeHeaderImage,
    getSectionsByDocumentTypeId,
} from "@/store/resume/selectors";
import {
    getUserCreditBalance,
    getUserDetails,
    getUserFeatureByActionCode,
    getUserFlags,
    getUserId,
} from "@/store/user/selectors";
import { handleShowSurvey } from "@/store/user/utils";
import {
    NotificationMessageType,
    NotificationType,
    Page,
    Resume,
} from "@/types";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { pluralise } from "@/utils/string";
import { trackClarityEvent } from "@/utils/tracking";

const LoadingComponent = () => (
    <div
        className="loading-page mt-20 flex justify-center"
        style={{
            height: A4_PAGE_HEIGHT_PIXELS,
            width: A4_PAGE_WIDTH_PIXELS,
        }}
    >
        <LoadingSpinner />
    </div>
);

const DownloadResumeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.DOWNLOAD_RESUME));
    const activeResumeImageSignedUrl = useAppSelector(
        getActiveResumeHeaderImage,
    );
    const [error, setError] = useState<string | null>(null);
    const [documentLoaded, setDocumentLoaded] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const activeResume = useAppSelector(getActiveResume);
    const resume: Resume = activeResume;
    const [localResumeName, setLocalResumeName] = useState(resume?.name ?? "");
    const activeResumePages = useAppSelector(getPages);
    const pages: Page[] = activeResumePages;
    const sectionsConfig = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, resume?.documentType),
    );
    const dispatch = useAppDispatch();
    const {
        getAndFormatCreditActionCostByCode,
        validateCreditAction,
        validateAndAddTransaction,
        getCreditActionCostByCode,
        getCreditActionByCode,
    } = useTransaction();
    const isSurveyAvailable = useAppSelector(state =>
        getUserFeatureByActionCode(state, CreditActionCode.APP_FEEDBACK_SURVEY),
    );
    const userId = useAppSelector(getUserId);
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const userFlags = useAppSelector(getUserFlags);
    const userDetails = useAppSelector(getUserDetails);
    const downloadAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.DOWNLOAD_DOCUMENT),
        [getCreditActionByCode],
    );
    const downloadActionCost = useMemo(
        () => getCreditActionCostByCode(CreditActionCode.DOWNLOAD_DOCUMENT),
        [getCreditActionCostByCode],
    );
    const formattedDownloadCost = useMemo(
        () =>
            getAndFormatCreditActionCostByCode(
                CreditActionCode.DOWNLOAD_DOCUMENT,
            ),
        [getAndFormatCreditActionCostByCode],
    );

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.DOWNLOAD_RESUME));
    }, [dispatch]);

    useEffect(() => {
        setLocalResumeName(resume?.name ?? "");
    }, [resume]);

    const onDownload = useCallback(
        async (method: "file" | "email") => {
            if (!resume) return;
            if (method === "email") {
                setIsSendingEmail(true);
            } else {
                setButtonLoading(true);
            }

            try {
                const { creditActionError, hasInsufficientFunds } =
                    validateCreditAction({
                        actionCode: CreditActionCode.DOWNLOAD_DOCUMENT,
                    });

                if (creditActionError && !hasInsufficientFunds) {
                    throw new Error(creditActionError);
                }

                if (!creditActionError && !hasInsufficientFunds) {
                    const blob = await pdf(
                        <PdfDocumentViewer
                            resume={resume}
                            sectionsConfig={sectionsConfig}
                            pages={pages}
                            imageSrc={activeResumeImageSignedUrl}
                        />,
                    ).toBlob();

                    if (method === "file") {
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = `${localResumeName || "resume"}.pdf`;
                        document.body.appendChild(link);
                        await validateAndAddTransaction({
                            actionCode: CreditActionCode.DOWNLOAD_DOCUMENT,
                        });
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    }

                    if (method === "email") {
                        await sendDocumentAttachmentEmail({
                            documentFile: blob,
                            fileName: `${localResumeName || "Resume"}.pdf`,
                            user: userDetails!,
                        });
                        await validateAndAddTransaction({
                            actionCode: CreditActionCode.DOWNLOAD_DOCUMENT,
                        });
                        trackClarityEvent(
                            CLARITY_EVENT_NAMES.FILE_SENT_TO_EMAIL,
                        );
                    }

                    trackClarityEvent(CLARITY_EVENT_NAMES.FILE_DOWNLOADED);
                    dispatch(closeModal(ModalTypes.DOWNLOAD_RESUME));
                    dispatch(
                        openModal({
                            modalType: ModalTypes.CREDITS_SUCCESS,
                            props: { mode: "download", method },
                        }),
                    );
                    if (userId && isSurveyAvailable) {
                        await handleShowSurvey(dispatch, userId, userFlags);
                    }
                } else {
                    if (!hasInsufficientFunds) {
                        dispatch(setShowPlansModal("download"));
                    }
                }
            } catch (error) {
                dispatch(
                    addNotification({
                        title: "Something went wrong",
                        desc: "Unable to download document. Please refresh and try again.",
                        messageType: NotificationMessageType.DOWNLOAD_FAILED,
                        type: NotificationType.ERROR,
                    }),
                );
            } finally {
                setButtonLoading(false);
                setIsSendingEmail(false);
            }
        },
        [
            resume,
            validateCreditAction,
            sectionsConfig,
            pages,
            activeResumeImageSignedUrl,
            dispatch,
            userId,
            isSurveyAvailable,
            localResumeName,
            validateAndAddTransaction,
            userDetails,
            userFlags,
        ],
    );

    useEffect(() => {
        if (error) {
            dispatch(
                addNotification({
                    title: "Something went wrong",
                    desc: "Unable to preview document.",
                    messageType: NotificationMessageType.PREVIEW_FAILED,
                    type: NotificationType.ERROR,
                }),
            );
            setError(null);
        }
    }, [dispatch, error]);

    const canDownload = useMemo(() => {
        return userCreditBalance - downloadActionCost >= 0;
    }, [userCreditBalance, downloadActionCost]);

    if (!resume) return null;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            title="Download Resume"
            modalType={ModalTypes.DOWNLOAD_RESUME}
            width="5xl"
        >
            <div className="flex flex-col lg:grid lg:h-[calc(100vh-10rem)] lg:grid-cols-5 lg:gap-8">
                <div className="col-span-5 flex h-full flex-col lg:col-span-2">
                    <div className="min-h-0 flex-1 overflow-y-auto">
                        <div className="flex flex-col gap-6 pb-4">
                            <div className="space-y-3">
                                <AppText
                                    variant="contextheading"
                                    className="font-medium"
                                >
                                    Resume Details
                                </AppText>
                                <div className="space-y-3">
                                    <div className="flex flex-col justify-between">
                                        <AppText
                                            variant="labelsbuttons"
                                            className="text-neutral-700"
                                        >
                                            File Name
                                        </AppText>
                                        <div className="relative mt-1">
                                            <input
                                                type="text"
                                                value={localResumeName}
                                                onChange={e =>
                                                    setLocalResumeName(
                                                        e.target.value,
                                                    )
                                                }
                                                className={clsx(
                                                    "block w-full rounded-lg border-0 py-1.5 text-app-labelsbuttons-4-mobile text-neutral-900 shadow-sm ring-2 ring-inset placeholder:text-neutral-400 focus:ring-2 focus:ring-inset md:text-app-labelsbuttons-4-desktop",
                                                    localResumeName
                                                        ? "ring-neutral-300 focus:ring-primary-700"
                                                        : "ring-error-500 focus:ring-error-400",
                                                )}
                                            />
                                            <AppText
                                                variant="labelsbuttons"
                                                className="absolute right-3 top-1/2 -translate-y-1/2 text-neutral-500"
                                            >
                                                .pdf
                                            </AppText>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <AppText
                                            variant="labelsbuttons"
                                            className="text-neutral-700"
                                        >
                                            Number of Pages
                                        </AppText>
                                        <div className="flex items-center gap-2 text-neutral-900">
                                            <Files size={16} />
                                            <AppText variant="labelsbuttons">
                                                {pages.length}{" "}
                                                {pluralise(
                                                    pages.length,
                                                    "page",
                                                    "pages",
                                                )}
                                            </AppText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {downloadAction && (
                                <CreditActionSummary
                                    creditAction={downloadAction}
                                    insufficientCreditsMessage="Purchase additional credits to download your resume"
                                    balanceAfterMessage="Balance after download:"
                                    buttonsOnly
                                />
                            )}
                        </div>
                    </div>

                    <div className="sticky bottom-0 mt-4 flex flex-col gap-2 bg-white pt-2">
                        <Button
                            leftIcon={<Download size={16} />}
                            onClick={() => onDownload("file")}
                            loading={buttonLoading}
                            disabled={
                                buttonLoading ||
                                !documentLoaded ||
                                !localResumeName ||
                                !canDownload ||
                                isSendingEmail
                            }
                            className="w-full"
                        >
                            Download for {formattedDownloadCost}
                        </Button>
                        <Button
                            color="softIndigo"
                            leftIcon={<Send size={16} />}
                            onClick={() => onDownload("email")}
                            loading={isSendingEmail}
                            disabled={
                                isSendingEmail ||
                                !documentLoaded ||
                                !localResumeName ||
                                !canDownload ||
                                buttonLoading
                            }
                            className="w-full"
                        >
                            Send as Email for {formattedDownloadCost}
                        </Button>
                    </div>
                </div>
                <div className="col-span-3 hidden max-h-full overflow-y-auto rounded-lg bg-neutral-100 px-4 py-3 lg:block">
                    <div
                        className="mx-auto flex flex-col items-center gap-4"
                        style={{
                            minHeight: A4_PAGE_HEIGHT_PIXELS * 0.6,
                        }}
                    >
                        <>
                            <PdfDocumentPreview
                                numberOfPages={pages.length}
                                loadingComponent={<LoadingComponent />}
                                onLoadSuccess={() => {
                                    setDocumentLoaded(true);
                                }}
                                scale={0.55}
                            >
                                <PdfDocumentViewer
                                    resume={resume}
                                    sectionsConfig={sectionsConfig}
                                    pages={pages}
                                    imageSrc={activeResumeImageSignedUrl ?? ""}
                                />
                            </PdfDocumentPreview>
                        </>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default DownloadResumeModal;
