export enum GA_EVENT_CATEGORIES {
    BUTTON = "button",
    NAVIGATION = "navigation",
    FORM = "form",
    MODAL = "modal",
    STRIPE = "stripe",
    ERROR = "error",
    NOTIFICATION = "notification",
}

export enum GA_EVENT_ACTIONS {
    CLICK = "click",
    SUBMIT = "submit",
    OPEN = "open",
    CLOSE = "close",
    ERROR = "error",
}

export enum META_EVENT_ACTIONS {
    COMPLETED_REGISTRATION = "CompletedRegistration",
    INITIATE_CHECKOUT = "InitiateCheckout",
    START_TRIAL = "StartTrial",
    SUBSCRIBE = "Subscribe",
}

export enum META_CUSTOM_EVENT_ACTIONS {
    UPGRADE = "Upgrade",
    MANAGE = "Manage",
    CANCEL_PURCHASE = "CancelPurchase",
}

export enum CLARITY_EVENT_NAMES {
    AI_TOOLBOX_THUMB = "AI_TOOLBOX_THUMB_UP",
    AI_TOOLBOX_THUMB_DOWN = "AI_TOOLBOX_THUMB_DOWN",
    AI_TOOLBOX_OPENED_NAVBAR = "AI_TOOLBOX_OPENED_NAVBAR",
    AI_TOOLBOX_OPENED_ACTIONS_TOOLBAR = "AI_TOOLBOX_OPENED_ACTIONS_TOOLBAR",
    AI_TOOLBOX_OPENED_BUBBLE_MENU = "AI_TOOLBOX_OPENED_BUBBLE_MENU",
    DOWNLOAD_RESUME_BUTTON_CLICKED = "DOWNLOAD_RESUME_BUTTON_CLICKED",
    PREVIEW_DOWNLOADED = "PREVIEW_DOWNLOADED",
    PREVIEW_RESUME_BUTTON_CLICKED = "PREVIEW_RESUME_BUTTON_CLICKED",
    FREE_TRIAL_BUTTON_CLICKED = "FREE_TRIAL_BUTTON_CLICKED",
    UPGRADE_BUTTON_CLICKED = "UPGRADE_BUTTON_CLICKED",
    INITIATE_CHECKOUT = "INITIATE_CHECKOUT",
    NEW_RESUME_BUTTON_CLICKED = "NEW_RESUME_BUTTON_CLICKED",
    NEW_RESUME_CREATED = "NEW_RESUME_CREATED",
    EXAMPLE_RESUME_USED = "EXAMPLE_RESUME_USED",
    PRESET_HEADER_USED = "PRESET_HEADER_USED",
    PRESET_SECTION_USED = "PRESET_SECTION_USED",
    SIDEBAR_STYLES_CLICKED = "SIDEBAR_STYLES_CLICKED",
    SIDEBAR_ASSISTANT_CLICKED = "SIDEBAR_ASSISTANT_CLICKED",
    SIDEBAR_SETTINGS_CLICKED = "SIDEBAR_SETTINGS_CLICKED",
    ASSISTANT_JOB_ROLE_CLICKED = "ASSISTANT_JOB_ROLE_CLICKED",
    ASSISTANT_SECTION_TAB_CLICKED = "ASSISTANT_SECTION_TAB_CLICKED",
    ASSISTANT_RESUME_TAB_CLICKED = "ASSISTANT_RESUME_TAB_CLICKED",
    ASSISTANT_SECTION_EXAMPLE_CLICKED = "ASSISTANT_SECTION_EXAMPLE_CLICKED",
    AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS",
    AUTH_SIGNUP_SUCCESS = "AUTH_SIGNUP_SUCCESS",
    FILE_DOWNLOADED = "FILE_DOWNLOADED",
    FILE_SENT_TO_EMAIL = "FILE_SENT_TO_EMAIL",
    RESUME_LIMIT_REACHED = "RESUME_LIMIT_REACHED",
    PRESET_HEADER_LEFT_COLUMN_USED = "PRESET_HEADER_LEFT_COLUMN_USED",
    PRESET_HEADER_LEFT_ROW_USED = "PRESET_HEADER_LEFT_ROW_USED",
    PRESET_HEADER_CENTERED_USED = "PRESET_HEADER_CENTERED_USED",
    PRESET_SECTION_LEFT_COLUMN_USED = "PRESET_SECTION_LEFT_COLUMN_USED",
    PRESET_SECTION_RIGHT_COLUMN_USED = "PRESET_SECTION_RIGHT_COLUMN_USED",
    PRESET_SECTION_LEFT_ROW_USED = "PRESET_SECTION_LEFT_ROW_USED",
    PRESET_SECTION_RIGHT_ROW_USED = "PRESET_SECTION_RIGHT_ROW_USED",
    FEEDBACK_SURVEY_OPENED = "FEEDBACK_SURVEY_OPENED",
    FEEDBACK_SURVEY_SUBMITTED = "FEEDBACK_SURVEY_SUBMITTED",
    ERROR_RESUME_SAVE_WRAPPER = "ERROR_RESUME_SAVE_WRAPPER",
    PURCHASE_SUCCESS = "PURCHASE_SUCCESS",
    PURCHASE_CANCEL = "PURCHASE_CANCEL",
    PURCHASE_FAILED = "PURCHASE_FAILED",
}
