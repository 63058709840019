import { useCallback } from "react";
import { Button } from "@/components/Button/Button";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";

const PaymentSuccessModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.PAYMENT_SUCCESS));
    const credits = useAppSelector(getUserCreditBalance);
    const dispatch = useAppDispatch();
    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.PAYMENT_SUCCESS));
    }, [dispatch]);

    return (
        <Modal
            noHeader
            open={isOpen}
            modalType={ModalTypes.PAYMENT_SUCCESS}
            title=""
        >
            <div className="flex flex-col items-center gap-4 p-6">
                <AppText variant="headings">Payment Successful!</AppText>
                <AppText variant="regular">
                    Your credits have been added to your account.
                </AppText>
                <AppText variant="subheadings">
                    Current Balance: {credits} credits
                </AppText>
                <Button
                    className="w-full"
                    color={"primary"}
                    variant={"solid"}
                    onClick={onClose}
                >
                    Continue
                </Button>
            </div>
        </Modal>
    );
};

export default PaymentSuccessModal;
