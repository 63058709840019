import { HelpCircleIcon, Plus } from "lucide-react";
import { useCallback, useMemo } from "react";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import { CreditPackButtons } from "@/components/Credits/CreditPackButtons";
import { CreditsIcon } from "@/components/Icons/Icons";
import { PresetsMenu } from "@/components/Presets/PresetsMenu";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance } from "@/store/user/selectors";
import { ICreditAction } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";

interface CreditActionSummaryProps {
    creditAction: ICreditAction;
    insufficientCreditsMessage?: string;
    balanceAfterMessage?: string;
    buttonsOnly?: boolean;
}

export const CreditActionSummary = ({
    creditAction,
    insufficientCreditsMessage = "Purchase additional credits to proceed",
    balanceAfterMessage = "Balance after:",
    buttonsOnly = false,
}: CreditActionSummaryProps) => {
    const dispatch = useAppDispatch();
    const onHelp = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.CREDITS_INFO }));
    }, [dispatch]);
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const onBuyCredits = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.BUY_CREDITS }));
    }, [dispatch]);

    const hasEnoughCredits = useMemo(() => {
        if (!creditAction) {
            return false;
        }
        if (userCreditBalance === 0) {
            return false;
        }
        const positiveCredits = Math.abs(creditAction.credits ?? 0);

        return userCreditBalance - positiveCredits >= 0;
    }, [creditAction, userCreditBalance]);

    return (
        <div className="space-y-3">
            {hasEnoughCredits ? (
                <>
                    <div className="flex items-center justify-between">
                        <AppText
                            variant="contextheading"
                            className="font-medium"
                        >
                            Credits
                        </AppText>
                        <Button
                            color="gradient"
                            variant="solid"
                            onClick={onBuyCredits}
                            size="small"
                            leftIcon={<Plus size={16} />}
                        >
                            Add Credits
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-700"
                        >
                            Current Balance:
                        </AppText>
                        <CreditsChip
                            type="gradient"
                            leftIcon={<CreditsIcon className="h-4 w-4" />}
                            credits={userCreditBalance}
                            borderless
                        />
                    </div>

                    <div className="flex items-center justify-between">
                        <AppText
                            variant="labelsbuttons"
                            className="text-neutral-700"
                        >
                            {balanceAfterMessage}
                        </AppText>
                        <CreditsChip
                            credits={
                                userCreditBalance + (creditAction.credits ?? 0)
                            }
                            type="gradient"
                            leftIcon={<CreditsIcon className="h-4 w-4" />}
                            borderless
                        />
                    </div>
                </>
            ) : (
                <PresetsMenu
                    type="alert"
                    title={
                        <div className="flex w-full items-center justify-between gap-2">
                            <AppText
                                variant="labelsbuttons"
                                className="block text-neutral-900"
                            >
                                {insufficientCreditsMessage}
                            </AppText>
                            <HelpCircleIcon
                                size={16}
                                className="cursor-pointer text-primary-700 hover:text-primary-900"
                                onClick={onHelp}
                            />
                        </div>
                    }
                >
                    <div className="py-3">
                        <div className="mb-3 flex w-full flex-col gap-2 px-3">
                            <div className="flex items-center justify-between text-neutral-700">
                                <AppText
                                    variant="labelsbuttons"
                                    className="text-neutral-900"
                                >
                                    Current Balance:
                                </AppText>
                                <CreditsChip
                                    credits={userCreditBalance}
                                    type="gradient"
                                    leftIcon={
                                        <CreditsIcon className="h-4 w-4" />
                                    }
                                    borderless
                                />
                            </div>
                            <div className="flex items-center justify-between text-neutral-700">
                                <AppText
                                    variant="labelsbuttons"
                                    className="text-neutral-900"
                                >
                                    Credits Required:
                                </AppText>
                                <CreditsChip
                                    credits={Math.abs(
                                        creditAction?.credits ?? 0,
                                    )}
                                    type="gradient"
                                    leftIcon={
                                        <CreditsIcon className="h-4 w-4" />
                                    }
                                    borderless
                                />
                            </div>
                        </div>
                        <CreditPackButtons
                            className="px-3"
                            buttonsOnly={buttonsOnly}
                            buttonPrefix="Buy"
                        />
                    </div>
                </PresetsMenu>
            )}
        </div>
    );
};
