import { StripeItem } from "@/types/billing";
import { apiRequest } from "./request";
import { ICredit } from "@/types/user";

const getStripeProducts = () => apiRequest<StripeItem[]>("/billing/products", "GET");

const createPaymentIntent = (data: { priceId: string; stripeCustomerId: string; }) => apiRequest<{ id: string; clientSecret: string; amount: number; }>("/billing/create-payment-intent", "POST", data);

const processPayment = (data: { paymentIntentId: string; }) => apiRequest<{ success: boolean; alreadyProcessed: boolean; userCredits: ICredit; }>("/billing/process", "POST", data);

const sendReceipt = (data: { paymentIntentId: string; asEmail: boolean; }) =>
    apiRequest<{ receiptUrl: string; }>("/billing/send-receipt", "POST", data);

export default {
    getStripeProducts,
    createPaymentIntent,
    processPayment,
    sendReceipt,
};