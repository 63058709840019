import { bulletBuilderSectionTypeToPlaceholder, paragraphProSectionTypeToPlaceholder } from "@/components/AiToolbox/constants";
import { updateAiToolbox } from "@/store/app/slice";
import { AppDispatch } from "@/store/store";
import { CreditActionCode } from "@/types/creditAction";
import aiApi from "@/api/ai";
import { UserPreferences } from "@/types/user";

export const getAiFeaturePlaceholder = (
  creditActionCode?: CreditActionCode,
  sectionType: string = "default",
) => {
  switch (creditActionCode) {
    case CreditActionCode.AI_GENERATE_BULLET_POINTS:
      return bulletBuilderSectionTypeToPlaceholder[sectionType] || bulletBuilderSectionTypeToPlaceholder.default;
    case CreditActionCode.AI_GENERATE_SUMMARY:
      return paragraphProSectionTypeToPlaceholder[sectionType] || paragraphProSectionTypeToPlaceholder.default;
    case CreditActionCode.AI_PROMPT_REWRITE:
      return "Enter text you’d like to refine or rephrase for improved clarity and flow.";
    case CreditActionCode.AI_PROMPT_SHORTEN:
      return "Add text you’d like to make more concise while retaining its meaning.";
    case CreditActionCode.AI_PROMPT_FORMAL:
      return "Provide text you’d like to elevate to a professional or formal tone.";
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return "Insert text you’d like checked for grammar, punctuation, and spelling errors.";
    default:
      return "";
  }
};

export const getAiFeatureTitle = (creditActionCode?: CreditActionCode) => {
  switch (creditActionCode) {
    case CreditActionCode.AI_PROMPT_REWRITE:
      return "Rewrite";
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return "Fix grammar";
    case CreditActionCode.AI_PROMPT_SHORTEN:
      return "Shorten";
    case CreditActionCode.AI_PROMPT_FORMAL:
      return "More formal";
    case CreditActionCode.AI_GENERATE_SUMMARY:
      return "Paragraph Pro";
    case CreditActionCode.AI_GENERATE_BULLET_POINTS:
      return "Bullet Builder";
    default:
      return "Smart Scribe";
  }
};

export const getAiFeatureHelp = (
  creditActionCode: CreditActionCode,
) => {
  switch (creditActionCode) {
    case CreditActionCode.AI_GENERATE_BULLET_POINTS:
      return "Generate powerful, concise bullet points that highlight your key achievements and skills. Perfect for making your resume stand out with clear, impactful statements";
    case CreditActionCode.AI_GENERATE_SUMMARY:
      return "Create polished, professional paragraphs that effectively showcase your experience, strengths, and career highlights. Ideal for crafting compelling resume sections";
    case CreditActionCode.AI_PROMPT_REWRITE:
      return "Refine or rephrase text for improved clarity and flow.";
    case CreditActionCode.AI_PROMPT_SHORTEN:
      return "Make text more concise while retaining its meaning.";
    case CreditActionCode.AI_PROMPT_FORMAL:
      return "Elevate text to a professional or formal tone.";
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return "Check text for grammar, punctuation, and spelling errors.";
    default:
      return "";
  }
};

export const handleAiToolboxGenerate = async ({
  creditActionCode,
  input,
  dispatch,
  sectionType,
  userPreferences,
  signal,
}: {
  creditActionCode: CreditActionCode;
  input: string;
  dispatch: AppDispatch;
  sectionType?: string;
  userPreferences?: UserPreferences;
  signal?: AbortSignal;
}) => {
  dispatch(updateAiToolbox({ stage: "output" }));

  switch (creditActionCode) {
    case CreditActionCode.AI_GENERATE_SUMMARY:
      return await aiApi.generateParagraphPro(
        {
          temperature: 0.7,
          includeUsage: false,
          content: input,
          industries: userPreferences?.industries ?? [],
          jobRoles: userPreferences?.jobRoles ?? [],
          experienceLevel: userPreferences?.experienceLevel ?? "Any",
          sectionType,
          // mockResponse: true,
        },
        signal,
        dispatch,
      );
    case CreditActionCode.AI_GENERATE_BULLET_POINTS:
      return await aiApi.generateBulletBuilder(
        {
          temperature: 0.7,
          includeUsage: false,
          content: input,
          // mockResponse: true,
          sectionType,
          industries: userPreferences?.industries ?? [],
          jobRoles: userPreferences?.jobRoles ?? [],
          experienceLevel: userPreferences?.experienceLevel ?? "Any",
        },
        signal,
        dispatch,
      );
    case CreditActionCode.AI_PROMPT_REWRITE:
    case CreditActionCode.AI_PROMPT_SHORTEN:
    case CreditActionCode.AI_PROMPT_FORMAL:
    case CreditActionCode.AI_PROMPT_GRAMMAR:
      return await aiApi.generateQuickPrompt(
        {
          temperature: 0.7,
          includeUsage: false,
          content: input,
          // mockResponse: true,
          creditActionCode,
        },
        signal,
        dispatch,
      );
    default:
      return Promise.resolve({
        output: "",
      });
  }
};
