import clsx from "clsx";
import { PlusSquare, Move } from "lucide-react";
import { useCallback } from "react";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { EditorToolbar } from "@/components/Editor/EditorToolbar";
import { SmartScribe } from "@/components/Icons/Icons";
import { PreviewResumeButton } from "@/components/Modals/PreviewResumeModal/PreviewResumeButton";
import { DownloadButton } from "@/components/Navigation/DownloadButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getAiToolboxIsVisible,
    getAiToolboxPromptActionCode,
    getShowSideBar,
    getTemplateLoadingState,
} from "@/store/app/selectors";
import { resetAiToolbox, updateAiToolbox } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { setActiveEntities } from "@/store/template/slice";
import { TemplateLoadingState } from "@/types/app";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";

const SecondaryNavigation = () => {
    const sideBarOpen = useAppSelector(getShowSideBar);
    const dispatch = useAppDispatch();
    const aiToolBoxIsVisible = useAppSelector(getAiToolboxIsVisible);
    const aiToolboxPromptActionCode = useAppSelector(
        getAiToolboxPromptActionCode,
    );

    const handleOpenModal = useCallback(
        (modalType: ModalTypes) => {
            dispatch(openModal({ modalType }));
            dispatch(resetAiToolbox());
        },
        [dispatch],
    );

    const openAiToolbox = useCallback(() => {
        dispatch(
            updateAiToolbox({
                source: "navbar",
                isVisible: !aiToolBoxIsVisible,
                promptActionCode:
                    aiToolboxPromptActionCode ||
                    CreditActionCode.AI_GENERATE_SUMMARY,
            }),
        );
        dispatch(
            setActiveEntities({
                activeSection: "",
                activeItemId: "",
            }),
        );
    }, [dispatch, aiToolBoxIsVisible, aiToolboxPromptActionCode]);

    const templateLoadingState = useAppSelector(getTemplateLoadingState);

    return (
        <div
            className={clsx(
                "transition-margin fixed left-1/2 top-[calc(1.2*theme(spacing.navigationHeight))] z-10 flex shrink-0 -translate-x-1/2 transform items-center rounded-lg bg-white p-2 shadow-lg ring-2 ring-neutral-300 duration-300 ease-in-out",
                sideBarOpen ? "ml-[30px] xl:ml-[190px]" : "ml-[30px]",
            )}
        >
            <div className="relative flex w-full flex-row items-center justify-between">
                <div className="flex items-center gap-3">
                    <Button
                        onClick={() =>
                            handleOpenModal(ModalTypes.ADD_NEW_SECTION)
                        }
                        leftIcon={<PlusSquare className="h-5 w-5" />}
                        disabled={
                            templateLoadingState !== TemplateLoadingState.READY
                        }
                        className={BuilderTourStep.ADD_SECTIONS}
                    >
                        <span className="block whitespace-nowrap">
                            Add Section
                        </span>
                    </Button>
                    <Button
                        variant={"solid"}
                        color={"softIndigo"}
                        onClick={() => handleOpenModal(ModalTypes.REARRANGE)}
                        leftIcon={<Move className="h-5 w-5" />}
                        disabled={
                            templateLoadingState !== TemplateLoadingState.READY
                        }
                        className={BuilderTourStep.REARRANGE_SECTIONS}
                    >
                        <span className="block whitespace-nowrap">
                            Rearrange
                        </span>
                    </Button>
                    <Tooltip
                        placement="bottom"
                        openDelay={200}
                    >
                        <TooltipTrigger>
                            <IconButton
                                id="smart-scribe-nav-button"
                                variant={
                                    aiToolBoxIsVisible ? "solid" : "outline"
                                }
                                color="gradient"
                                onClick={openAiToolbox}
                                className={BuilderTourStep.SMART_SCRIBE}
                            >
                                <SmartScribe gradient={!aiToolBoxIsVisible} />
                            </IconButton>
                        </TooltipTrigger>
                        <TooltipContent style={{ maxWidth: "230px" }}>
                            <div className="flex flex-col items-center">
                                <AppText
                                    variant="footnote"
                                    className="!font-semibold"
                                >
                                    Smart Scribe
                                </AppText>
                                <AppText variant="footnote">
                                    Use Smart Scribe to write, rewrite, or
                                    improve your resume content.
                                </AppText>
                            </div>
                        </TooltipContent>
                    </Tooltip>
                </div>
                <div className="flex-1 pl-1 xl:pl-3">
                    <EditorToolbar />
                </div>
            </div>
            <div
                className={clsx(
                    "ml-2 flex transform items-center gap-2 custom-1370:ml-4",
                )}
            >
                <PreviewResumeButton />
                <DownloadButton responsive />
            </div>
        </div>
    );
};

export default SecondaryNavigation;
