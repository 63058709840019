import { useCallback, useEffect, useMemo, useState } from "react";
import resumeApi from "@/api/resume";
import { Button } from "@/components/Button/Button";
import { Dropdown } from "@/components/Dropdown/Dropdown";
import {
    MultiSelect,
    MultiSelectItem,
} from "@/components/MultiSelect/MultiSelect";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { AppText } from "@/components/Text/AppText";
import { visibilityItems } from "@/constants/adminControls";
import { RESUME_CATEGORIES, RESUME_TAGS } from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getDebugBuilder,
    getDebugPageMargins,
    getDebugSectionSpacing,
} from "@/store/app/selectors";
import {
    setDebugBuilder,
    setDebugPageMargins,
    setDebugSectionSpacing,
} from "@/store/app/slice";
import { getActiveResume } from "@/store/resume/selectors";
import { setActiveResume } from "@/store/resume/slice";
import { DocumentVisibility, Resume } from "@/types/resume";

export const AdminBuilderSettings = ({
    resume,
    afterSave,
}: {
    resume?: Resume;
    afterSave?: () => void;
}) => {
    const activeResume = useAppSelector(getActiveResume);
    const debugBuilder = useAppSelector(getDebugBuilder);
    const debugPageMargins = useAppSelector(getDebugPageMargins);
    const debugSectionSpacing = useAppSelector(getDebugSectionSpacing);
    const dispatch = useAppDispatch();
    const sourceResume = resume ?? activeResume;
    const [visibility, setVisibility] = useState(
        sourceResume?.visibility ?? DocumentVisibility.PRIVATE,
    );
    const [isTemplate, setIsTemplate] = useState(!!sourceResume?.isTemplate);
    const [isSaving, setIsSaving] = useState(false);
    const [selectedTags, setSelectedTags] = useState<MultiSelectItem[]>(
        sourceResume?.tags?.map(tag => ({
            id: tag,
            name: tag,
        })) ?? [],
    );
    const [selectedCategories, setSelectedCategories] = useState<
        MultiSelectItem[]
    >(
        sourceResume?.categories?.map(category => ({
            id: category,
            name: category,
        })) ?? [],
    );

    const tagOptions = useMemo(() => {
        return (
            RESUME_TAGS?.map(tag => ({
                id: tag,
                name: tag,
            })) ?? []
        );
    }, []);

    const categoryOptions = useMemo(() => {
        return (
            RESUME_CATEGORIES?.map(category => ({
                id: category,
                name: category,
            })) ?? []
        );
    }, []);

    useEffect(() => {
        setVisibility(sourceResume?.visibility ?? DocumentVisibility.PRIVATE);
        setIsTemplate(!!sourceResume?.isTemplate);
    }, [sourceResume]);

    const toggleDebugBuilder = () => {
        dispatch(setDebugBuilder(!debugBuilder));
    };

    const onSaveSettings = useCallback(() => {
        if (!sourceResume) return;
        setIsSaving(true);

        const newTags = selectedTags.map(tag => tag.id);
        const newCategories = selectedCategories.map(category => category.id);

        if (activeResume) {
            dispatch(
                setActiveResume({
                    ...sourceResume,
                    visibility,
                    isTemplate,
                    tags: newTags,
                    categories: newCategories,
                }),
            );
        }

        resumeApi
            .saveResume(sourceResume.id, {
                visibility,
                isTemplate,
                tags: newTags,
                categories: newCategories,
            })
            .finally(() => {
                setIsSaving(false);
                afterSave?.();
            });
    }, [
        sourceResume,
        dispatch,
        isTemplate,
        visibility,
        afterSave,
        activeResume,
        selectedTags,
        selectedCategories,
    ]);

    return (
        <div className="flex min-h-96 flex-col space-y-4">
            <div className="flex flex-col gap-2">
                <MultiSelect
                    items={tagOptions}
                    onChange={setSelectedTags}
                    selectedValues={selectedTags}
                    label="Tags"
                />
                <MultiSelect
                    items={categoryOptions}
                    onChange={setSelectedCategories}
                    selectedValues={selectedCategories}
                    label="Categories"
                />
            </div>
            <div className="flex items-center gap-4">
                <AppText variant="labelsbuttons">Visibility</AppText>
                <Dropdown
                    items={visibilityItems}
                    activeId={visibility}
                    handleChange={id => setVisibility(id as DocumentVisibility)}
                    title={
                        visibilityItems.find(item => item.id === visibility)
                            ?.title
                    }
                />
                <AppText variant="labelsbuttons">Use as Template?</AppText>
                <SwitchToggle
                    enabled={isTemplate ?? false}
                    onToggle={checked => setIsTemplate(checked)}
                />
                <AppText variant="labelsbuttons">
                    Usage Count: {sourceResume?.templateUsageCount}
                </AppText>
            </div>

            <Button
                disabled={isSaving}
                onClick={onSaveSettings}
                loading={isSaving}
                className="mr-auto"
            >
                Save Resume
            </Button>
            <div className="flex flex-row items-center gap-4 border-t border-neutral-400 pt-2">
                <Button onClick={toggleDebugBuilder}>
                    {debugBuilder ? "Hide Debugger" : "Show Debugger"}
                </Button>
                <AppText variant="labelsbuttons">Debug Page Margins</AppText>
                <SwitchToggle
                    enabled={debugPageMargins ?? false}
                    onToggle={checked => dispatch(setDebugPageMargins(checked))}
                />
                <AppText variant="labelsbuttons">Debug Section Spacing</AppText>
                <SwitchToggle
                    enabled={debugSectionSpacing ?? false}
                    onToggle={checked =>
                        dispatch(setDebugSectionSpacing(checked))
                    }
                />
            </div>
        </div>
    );
};
