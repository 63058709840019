import { ColumnDef } from "@tanstack/react-table";

export const industryColumns: ColumnDef<{
    industry: string;
    count: number;
    percentage: number;
}>[] = [
    {
        header: "Industry",
        accessorKey: "industries",
        size: 150,
        sortingFn: "alphanumeric",
    },
    {
        header: "Count",
        accessorKey: "count",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">{getValue() as number}</div>
        ),
    },
    {
        header: "Percentage",
        accessorKey: "percentage",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const jobRoleColumns: ColumnDef<{
    jobRoles: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "jobRoles",
        header: "Job Role",
        size: 150,
        sortingFn: "alphanumeric",
    },
    {
        accessorKey: "count",
        header: "Count",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">{getValue() as number}</div>
        ),
    },
    {
        accessorKey: "percentage",
        header: "Percentage",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const experienceLevelColumns: ColumnDef<{
    experienceLevel: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "experienceLevel",
        header: "Experience Level",
        size: 150,
        sortingFn: "alphanumeric",
    },
    {
        accessorKey: "count",
        header: "Count",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">{getValue() as number}</div>
        ),
    },
    {
        accessorKey: "percentage",
        header: "Percentage",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const jobTypeColumns: ColumnDef<{
    jobTypes: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "jobTypes",
        header: "Job Type",
        size: 150,
        sortingFn: "alphanumeric",
    },
    {
        accessorKey: "count",
        header: "Count",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">{getValue() as number}</div>
        ),
    },
    {
        accessorKey: "percentage",
        header: "Percentage",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const resumesPerUserColumns: ColumnDef<{
    user: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "numResumes",
        header: "# of Resumes",
        size: 80,
        sortingFn: "alphanumeric",
    },
    {
        accessorKey: "count",
        header: "# of Users",
        size: 80,
    },
    {
        accessorKey: "percentage",
        header: "% of Users",
        size: 80,
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const resumeLayoutColumns: ColumnDef<{
    layout: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "layout",
        header: "Layout",
        sortingFn: "alphanumeric",
        size: 80,
    },
    {
        accessorKey: "count",
        header: "Count",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">{getValue() as number}</div>
        ),
    },
    {
        accessorKey: "percentage",
        header: "Percentage",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const documentLimitColumns: ColumnDef<{
    documentLimit: string;
    count: number;
    percentage: number;
}>[] = [
    {
        accessorKey: "documentLimit",
        header: "Document Limit",
        size: 80,
    },
    {
        accessorKey: "count",
        header: "Count",
        size: 80,
        sortingFn: "basic",
    },
    {
        accessorKey: "percentage",
        header: "% of Users",
        size: 80,
        sortingFn: "basic",
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
];

export const creditActionCodeColumns: ColumnDef<{
    code: string;
    count: number;
    amount: number;
    percentage: number;
}>[] = [
    { accessorKey: "code", header: "Code", size: 150 },
    { accessorKey: "count", header: "Count", size: 80 },
    {
        accessorKey: "percentage",
        header: "% of Total",
        size: 80,
        cell: ({ getValue }) => (
            <div className="text-right">
                {(getValue() as number).toFixed(1)}%
            </div>
        ),
    },
    { accessorKey: "amount", header: "Amount", size: 80 },
];

export const aiFeedbackColumns: ColumnDef<{
    feedbackAction: string;
    count: number;
    percentage: number;
}>[] = [
    { accessorKey: "action", header: "Feedback Action", size: 150 },
    { accessorKey: "count", header: "Count", size: 80 },
];
