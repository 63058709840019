import { useNavigate, useSearchParams } from "react-router-dom";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppText } from "@/components/Text/AppText";
import { VerifyCode } from "@/components/VerifyCode/VerifyCode";
import { useAppDispatch } from "@/hooks/types";
import { NoNavPageContainer } from "@/layouts/NoNavPageContainer";
import { setShowBonusNavbarButton } from "@/store/app/slice";
import { AppRoutes } from "@/types/routes";

export const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const email = searchParams.get("email") ?? "";
    const dispatch = useAppDispatch();

    return (
        <NoNavPageContainer>
            <PageHelmet
                title="Verify Email"
                robotsOverride="noindex, nofollow"
            />
            <div>
                <AppText
                    variant="headings"
                    className="mb-8 text-center"
                >
                    Verify your email
                </AppText>
                <AppText
                    variant="subheadings"
                    className="mb-2 text-center"
                >
                    Verification code
                </AppText>
                <AppText
                    variant="labelsbuttons"
                    className="block text-center text-neutral-500"
                >
                    Enter the verification code sent to{" "}
                    <span className="text-neutral-700">
                        {email || "your email"}
                    </span>
                    .
                </AppText>
                <div>
                    <VerifyCode
                        email={email}
                        onSuccess={() => {
                            dispatch(setShowBonusNavbarButton(true));
                            navigate("/" + AppRoutes.Preferences, {
                                replace: true,
                            });
                        }}
                    />
                </div>
            </div>
        </NoNavPageContainer>
    );
};
