import { Token, User } from "@/types";
import { CreditActionCode } from "@/types/creditAction";
import { ICredit } from "@/types/user";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserState {
    userDetails: User | undefined;
    accessToken: Token | null;
    refreshToken: Token | null;
    role?: "admin" | "user";
    isEmailVerified: boolean;
    id?: string;
    name?: string;
    features: Record<CreditActionCode, boolean>;
}

const initialState: UserState = {
    userDetails: undefined,
    accessToken: null,
    refreshToken: null,
    role: undefined,
    isEmailVerified: false,
    id: undefined,
    name: undefined,
    features: {} as Record<CreditActionCode, boolean>,
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetails: (state, { payload }) => {
            return {
                ...state,
                userDetails: {
                    ...payload,
                },
            };
        },
        setUserEmail: (state, { payload }) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    email: payload,
                },
            };
        },
        setAccessToken: (state, { payload }: PayloadAction<Token | null>) => {
            return {
                ...state,
                accessToken: payload,
            };
        },
        setRefreshToken: (state, { payload }: PayloadAction<Token | null>) => {
            return {
                ...state,
                refreshToken: payload,
            };
        },
        resetTokens: state => {
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
            };
        },
        setUserCredits: (state, { payload }: PayloadAction<ICredit>) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    credits: {
                        ...state.userDetails!.credits,
                        balance: payload.balance,
                        lastUpdated: payload.lastUpdated,
                    },
                },
            };
        },
        setUserFeatures: (state, { payload }: PayloadAction<Record<CreditActionCode, boolean>>) => {
            return {
                ...state,
                features: payload,
            };
        },
        updateUserDetails: (state, { payload }: PayloadAction<Partial<User>>) => {
            return {
                ...state,
                userDetails: {
                    ...state.userDetails!,
                    ...payload,
                },
            };
        },
    },
});

export const { setUserDetails, resetTokens, setAccessToken, setRefreshToken, setUserEmail, setUserCredits, setUserFeatures, updateUserDetails } =
    userSlice.actions;
export const { reducer: userReducer } = userSlice;
