import { useCallback } from "react";
import { AdminBuilderSettings } from "@/components/Admin/AdminBuilderSettings";
import { Modal } from "@/components/Modal/Modal";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { ModalTypes } from "@/types/modal";

const ResumeAdminModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.RESUME_ADMIN));
    const modalProps = useAppSelector(getModalProps(ModalTypes.RESUME_ADMIN));
    const dispatch = useAppDispatch();

    const onClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.RESUME_ADMIN));
    }, [dispatch]);

    return !modalProps?.resume ? null : (
        <Modal
            modalType={ModalTypes.RESUME_ADMIN}
            open={isOpen}
            title="Resume Admin"
            width="2xl"
        >
            <AdminBuilderSettings
                resume={modalProps.resume}
                afterSave={onClose}
            />
        </Modal>
    );
};

export default ResumeAdminModal;
