import {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";

export interface MultiSelectItem {
    id: string;
    name: string;
}

interface MultiSelectProps {
    items: MultiSelectItem[];
    label?: string;
    onChange: (items: MultiSelectItem[]) => void;
    selectedValues: MultiSelectItem[];
}

export const MultiSelect = ({
    items,
    label,
    onChange,
    selectedValues,
}: MultiSelectProps) => {
    const [query, setQuery] = useState("");

    const filteredItems =
        query === ""
            ? items
            : items.filter(item => {
                  return item.name.toLowerCase().includes(query.toLowerCase());
              });

    return (
        <Combobox
            as="div"
            value={selectedValues}
            onChange={onChange}
            multiple
        >
            {label && (
                <Label className="block text-sm font-medium leading-6 text-neutral-900">
                    {label}
                </Label>
            )}
            <div className="relative mt-2">
                <ComboboxInput
                    className="w-full rounded-lg border-0 bg-white py-1.5 pl-3 pr-10 text-neutral-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={event => setQuery(event.target.value)}
                    displayValue={(activeItems: MultiSelectItem[]) => {
                        return activeItems.map(({ name }) => name).join(", ");
                    }}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-lg px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>

                {filteredItems.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredItems.map(item => (
                            <ComboboxOption
                                key={item.id}
                                value={item}
                                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-neutral-900 data-[focus]:bg-primary-600 data-[focus]:text-white"
                            >
                                {({ selected }) => (
                                    <>
                                        <span
                                            className={clsx(
                                                "block truncate",
                                                selected && "font-semibold",
                                            )}
                                        >
                                            {item.name}
                                        </span>

                                        {selected && (
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 ">
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        )}
                                    </>
                                )}
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    );
};
