import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button/Button";
import { CreditsChip } from "@/components/Chip/CreditsChip";
import {
    CreditsIcon,
    GradientDownloadIcon,
    GradientFilesIcon,
    GradientSendIcon,
} from "@/components/Icons/Icons";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsModalOpen, getModalProps } from "@/store/modal/selectors";
import { closeModal, openModal } from "@/store/modal/slice";
import {
    getUserCreditBalance,
    getUserDocumentLimit,
} from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";
import { AppRoutes } from "@/types/routes";

type ModalMode = "download" | "buy" | "document-limit";

const CreditsSuccessModal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.CREDITS_SUCCESS));
    const modalProps = useAppSelector(
        getModalProps(ModalTypes.CREDITS_SUCCESS),
    );
    const { mode, method }: { mode: ModalMode; method: "email" | "file" } =
        modalProps ?? {};
    const userCreditBalance = useAppSelector(getUserCreditBalance);
    const documentLimit = useAppSelector(getUserDocumentLimit);

    const [activeMode, setActiveMode] = useState<ModalMode | undefined>(mode);

    useEffect(() => {
        if (isOpen && mode) {
            setActiveMode(mode);
        }
    }, [isOpen, mode]);

    const handleClose = useCallback(() => {
        dispatch(closeModal(ModalTypes.CREDITS_SUCCESS));
    }, [dispatch]);

    const handleLimitIncrease = useCallback(() => {
        handleClose();
        dispatch(
            openModal({
                modalType: ModalTypes.DOCUMENT_LIMIT,
            }),
        );
    }, [dispatch, handleClose]);

    const navigateAndClose = useCallback(
        (route: AppRoutes) => {
            navigate(route);
            dispatch(closeModal(ModalTypes.CREDITS_SUCCESS));
        },
        [dispatch, navigate],
    );

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.CREDITS_SUCCESS}
            noHeader
            width="2xl"
            gradientBackground
            title=""
            floatingCloseButton
            afterClose={() => setActiveMode(undefined)}
        >
            {activeMode === "download" && (
                <div className="flex flex-col items-center">
                    <div className="mb-2 mt-5">
                        {method === "email" ? (
                            <GradientSendIcon className="h-10 w-10" />
                        ) : (
                            <GradientDownloadIcon className="h-10 w-10" />
                        )}
                    </div>
                    <AppText
                        variant="headings"
                        className="mb-4 w-full text-center text-2xl font-bold text-primary-900"
                        applyGradient
                    >
                        {method === "email"
                            ? "RESUME SENT"
                            : "RESUME DOWNLOADED"}
                    </AppText>
                    <AppText
                        variant="regular"
                        className="text-center text-neutral-900"
                    >
                        {method === "email"
                            ? "Success! Your resume has been sent to your email."
                            : "Success! Your resume has been downloaded as a PDF."}
                    </AppText>
                </div>
            )}

            {activeMode === "buy" && (
                <div className="flex flex-col items-center">
                    <div className="mb-5 mt-5">
                        <CreditsIcon className="h-10 w-10" />
                    </div>
                    <AppText
                        variant="headings"
                        className="mb-4 w-full text-center text-2xl font-bold text-primary-900"
                        applyGradient
                    >
                        PURCHASE SUCCESSFUL
                    </AppText>

                    <AppText
                        variant="regular"
                        className="w-2/3 text-center text-neutral-900"
                    >
                        Credits let you access more features and download your
                        resume as you need them.
                    </AppText>
                </div>
            )}

            {activeMode === "document-limit" && (
                <div className="flex flex-col items-center">
                    <div className="mb-5 mt-5">
                        <GradientFilesIcon className="h-10 w-10" />
                    </div>
                    <AppText
                        variant="headings"
                        className="mb-4 w-full text-center text-2xl font-bold text-primary-900"
                        applyGradient
                    >
                        RESUME LIMIT INCREASED
                    </AppText>

                    <AppText
                        variant="regular"
                        className="w-2/3 text-center text-neutral-900"
                    >
                        Success! You can now create up to {documentLimit}{" "}
                        Resumes.
                    </AppText>
                </div>
            )}

            <div className="mt-6 flex items-center justify-center gap-2">
                <AppText
                    variant="labelsbuttons"
                    applyGradient
                >
                    Your new credit balance:
                </AppText>
                <CreditsChip
                    credits={userCreditBalance}
                    type="gradient"
                    borderless
                    leftIcon={<CreditsIcon className="h-4 w-4" />}
                />
            </div>

            <div className="mt-10 flex items-center justify-center gap-3">
                {activeMode === "download" && (
                    <>
                        <Button
                            variant="solid"
                            onClick={handleClose}
                        >
                            Return to Editor
                        </Button>
                        <Button
                            color="white"
                            variant="solid"
                            className="!text-primary-700"
                            onClick={() =>
                                navigateAndClose(AppRoutes.Dashboard)
                            }
                        >
                            Go to Dashboard
                        </Button>
                    </>
                )}

                {activeMode === "buy" && (
                    <Button
                        variant="solid"
                        onClick={handleClose}
                        className="w-[200px]"
                    >
                        Continue
                    </Button>
                )}

                {activeMode === "document-limit" && (
                    <>
                        <Button
                            variant="solid"
                            onClick={handleClose}
                        >
                            Continue
                        </Button>
                        <Button
                            color="white"
                            variant="solid"
                            className="!text-primary-700"
                            onClick={handleLimitIncrease}
                        >
                            Add More Resumes
                        </Button>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default CreditsSuccessModal;
