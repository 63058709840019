import { HelpCircle, History, XIcon } from "lucide-react";
import {
    getAiFeatureHelp,
    getAiFeatureTitle,
} from "@/components/AiToolbox/helper";
import { SmartScribe } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getAiToolboxStage } from "@/store/app/selectors";
import { resetAiToolbox } from "@/store/app/slice";
import { openModal } from "@/store/modal/slice";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
interface AiToolboxHeaderProps {
    subtitle?: string;
    creditActionCode?: CreditActionCode;
}

export const AiToolboxHeader = ({
    subtitle,
    creditActionCode,
}: AiToolboxHeaderProps) => {
    const stage = useAppSelector(getAiToolboxStage);
    const dispatch = useAppDispatch();
    const onClose = () => {
        dispatch(resetAiToolbox());
    };
    const openHistoryModal = () => {
        dispatch(openModal({ modalType: ModalTypes.PROMPT_HISTORY }));
    };

    return (
        <div className="flex items-center justify-between rounded-t-lg border-b-2 border-neutral-300 bg-gradient-primary p-2  text-neutral-50">
            <div className="flex items-center gap-1">
                <SmartScribe className="h-5 w-5" />
                {stage === "output" && subtitle ? (
                    <div className="max-w-[480px]">
                        <AppText
                            variant="regular"
                            className="truncate"
                        >
                            {subtitle}
                        </AppText>
                    </div>
                ) : (
                    <AppText variant="labelsbuttons">Smart Scribe</AppText>
                )}
            </div>
            <div className="flex items-center gap-3">
                <Tooltip>
                    <TooltipTrigger>
                        <button
                            id="history-button"
                            onClick={openHistoryModal}
                            className="flex cursor-pointer items-center justify-center gap-1 hover:text-neutral-200"
                        >
                            <History className="pointer-events-none h-4 w-4" />
                            <AppText
                                variant="labelsbuttons"
                                className="pointer-events-none"
                            >
                                History
                            </AppText>
                        </button>
                    </TooltipTrigger>
                    <TooltipContent
                        style={{
                            maxWidth: "400px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                        }}
                    >
                        <AppText variant="footnote">Prompt History</AppText>
                    </TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger>
                        <HelpCircle className="h-5 w-5 cursor-pointer hover:text-neutral-200" />
                    </TooltipTrigger>
                    {creditActionCode && (
                        <TooltipContent
                            style={{
                                maxWidth: "400px",
                                display: "flex",
                                flexDirection: "column",
                                gap: "4px",
                            }}
                        >
                            <AppText
                                variant="regular"
                                className="block !font-medium"
                            >
                                {getAiFeatureTitle(creditActionCode)}
                            </AppText>
                            <AppText variant="footnote">
                                {getAiFeatureHelp(creditActionCode)}
                            </AppText>
                        </TooltipContent>
                    )}
                </Tooltip>
                <button onClick={onClose}>
                    <XIcon className="h-5 w-5 cursor-pointer hover:text-neutral-200" />
                </button>
            </div>
        </div>
    );
};
