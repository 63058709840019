import clsx from "clsx";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import {
    buttonColours,
    buttonSharedStyles,
    buttonSizes,
    buttonVariantStyles,
} from "./buttonStyles";
import { InlineLoader } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { UsingReactChildren } from "@/types";

export type ButtonSize = "small" | "default";
export type ButtonColour =
    | "primary"
    | "grey"
    | "white"
    | "red"
    | "softIndigo"
    | "gradient"
    | "alert"
    | "secondary";
export type ButtonVariant = "solid" | "outline" | "text";

interface ButtonProps extends UsingReactChildren {
    variant?: ButtonVariant;
    color?: ButtonColour;
    className?: string;
    to?: string;
    loading?: boolean;
    onClick?: (e: React.MouseEvent) => void;
    type?: "submit" | "reset" | "button";
    disabled?: boolean;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    target?: "_blank";
    contentClassName?: string;
    size?: ButtonSize;
}

export const Button = ({
    variant = "solid",
    color = "primary",
    className,
    to,
    loading,
    children,
    onClick,
    type = "button",
    disabled,
    leftIcon,
    rightIcon,
    target,
    contentClassName,
    size = "default",
}: ButtonProps) => {
    const buttonClassName = clsx(
        buttonSharedStyles,
        buttonVariantStyles[variant],
        buttonColours[variant][color],
        buttonSizes[size],
        className,
    );

    return to ? (
        <Link
            to={to}
            className={clsx(buttonClassName, "button-as-link")}
            onClick={onClick}
            target={target}
            rel={target === "_blank" ? "noreferrer" : undefined}
        >
            {leftIcon && <span className="mr-2">{leftIcon}</span>}
            <AppText variant="labelsbuttons">{children}</AppText>
        </Link>
    ) : (
        <button
            onClick={onClick}
            type={type}
            className={clsx(buttonClassName, "button-as-button", "relative")}
            disabled={disabled || loading}
        >
            <span
                className={clsx(
                    "flex items-center justify-center",
                    loading && "opacity-0",
                    contentClassName,
                )}
            >
                {leftIcon && <span className="mr-2">{leftIcon}</span>}
                <AppText
                    variant="labelsbuttons"
                    applyGradient={color === "gradient" && variant !== "solid"}
                >
                    {children}
                </AppText>
                {rightIcon && <span className="ml-2">{rightIcon}</span>}
            </span>
            {loading && (
                <span className="absolute inset-0 flex items-center justify-center">
                    <InlineLoader />
                </span>
            )}
        </button>
    );
};
