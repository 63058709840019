import { ColumnLayout, DateFormat, PageMarginSize, SectionSpacingSize } from "@/types/resume";
import { SquareIcon, SidebarIcon } from "lucide-react";

// This is used to ignore elements in the on click listener
export const DOCUMENT_CONTAINER_CLASS = "document__container";
export const SECTION_ITEM_CLASS = "section__item";
export const SECTION_CONTAINER_CLASS = "section__container";

export const resumeAccentColours = [
    "#FFFFFF",
    "#79eafa",
    "#7773f5",
    "#82a584",
    "#bae0fc",
    "#bcbaff",
    "#cca3d8",
    "#ceedcb",
    "#cfcecc",
    "#d2b48c",
    "#d3e1a4",
    "#da5666",
    "#f5f5f5",
    "#eeddfe",
    "#f9615a",
    "#f9e3e8",
    "#fac28c",
    "#fce04a",
    "#ff87d0",
    "#ff9f45",
    "#c7cb18",
    "#FFEC99",
];

export const GLOBAL_STYLE_KEY = "GLOBAL";
export const PROFILE_IMAGE_KEY = "PROFILE_IMAGE";

export const resumeLayouts = [
    {
        id: ColumnLayout.SINGLE,
        name: "Full",
        icon: SquareIcon,
    },
    {
        id: ColumnLayout.DOUBLE,
        name: "Split",
        icon: SidebarIcon,
    },
];

export const resumeFonts = [
    { value: "Arial, sans-serif", displayName: "Arial", },
    { value: "Cambria, serif", displayName: "Cambria" },
    { value: "Georgia, serif", displayName: "Georgia" },
    { value: "Montserrat, sans-serif", displayName: "Montserrat" },
    { value: "Poppins, sans-serif", displayName: "Poppins" },
    { value: "Roboto, sans-serif", displayName: "Roboto" },
    { value: "Tahoma, sans-serif", displayName: "Tahoma" },
    { value: "Times New Roman, serif", displayName: "Times New Roman" },
];

export const resumeFontSizes = [
    { displayName: "Small", value: "small", pxValue: 11 },
    { displayName: "Medium", value: "medium", pxValue: 12 },
    { displayName: "Large", value: "large", pxValue: 13 },
    { displayName: "Extra Large", value: "extra-large", pxValue: 14 },
];

export const resumeHeadingFontSizes = [
    { displayName: "Small", value: "small", pxValue: 13 },
    { displayName: "Medium", value: "medium", pxValue: 14 },
    { displayName: "Large", value: "large", pxValue: 15 },
    { displayName: "Extra Large", value: "extra-large", pxValue: 16 },
];

export const resumePageMarginSizeMap: { [key: string]: number; } = {
    [PageMarginSize.XS]: 1,
    [PageMarginSize.SM]: 2,
    [PageMarginSize.MD]: 3,
    [PageMarginSize.LG]: 4,
    [PageMarginSize.XL]: 5,
};

export const resumeSectionSpacingSizeMap: { [key: string]: number; } = {
    [SectionSpacingSize.XS]: 1,
    [SectionSpacingSize.SM]: 2,
    [SectionSpacingSize.MD]: 3,
    [SectionSpacingSize.LG]: 4,
    [SectionSpacingSize.XL]: 5,
};

// Icon sizes are measures based on the resume font sizes
export const resumeIconSizes: { [size: string]: number; } = {
    "small": 12,
    "medium": 13,
    "large": 14,
    "extra-large": 16,
};

export const DATE_LOCATION_POSITION_KEY = "dateLocationPosition";
export const DATE_LOCATION_LAYOUT_KEY = "dateLocationLayout";
export const DATE_LOCATION_ALIGNMENT_KEY = "dateLocationAlignment";
export const DATE_LOCATION_CONTENT_KEY = "dateLocationContentLayout";
export const DETAILS_POSITION_KEY = "detailsPosition";
export const PROFILE_ALIGNMENT_KEY = "profileAlignment";

export const resumeDateLocationPositions = [
    { displayName: "Inline", value: "column" },
    { displayName: "Split", value: "row" },
];

export const resumeDateLocationLayouts = [
    { displayName: "Vertical", value: "column" },
    { displayName: "Horizontal", value: "row" },
];

export const dateFormatOptions = [
    { displayName: "01/2024", value: DateFormat.SM },
    { displayName: "01/24", value: DateFormat.XS },
    { displayName: "Jan 2024", value: DateFormat.MED },
    { displayName: "January 2024", value: DateFormat.LRG },
];

export const pdfFontSizeMap = {
    "small": {
        h1: {
            px: 24,
        },
        h2: {
            px: 16,
        },
        h3: {
            px: 13,
        },
        h4: {
            px: 12.5,
        },
        h5: {
            px: 12.5,
        },
        h6: {
            px: 12.5,
        },
        p: {
            px: 12.5,
        },
    },
    "medium": {
        h1: {
            px: 28,
        },
        h2: {
            px: 20,
        },
        h3: {
            px: 15,
        },
        h4: {
            px: 13,
        },
        h5: {
            px: 13,
        },
        h6: {
            px: 13,
        },
        p: {
            px: 13,
        },
    },
    "large": {
        h1: {
            px: 32,
        },
        h2: {
            px: 22,
        },
        h3: {
            px: 16,
        },
        h4: {
            px: 14,
        },
        h5: {
            px: 14,
        },
        h6: {
            px: 14,
        },
        p: {
            px: 14,
        },
    },
    "extra-large": {
        h1: {
            px: 36,
        },
        h2: {
            px: 24,
        },
        h3: {
            px: 18,
        },
        h4: {
            px: 16,
        },
        h5: {
            px: 16,
        },
        h6: {
            px: 16,
        },
        p: {
            px: 16,
        },
    }
};

export const resumeFontWeights: { [key: string]: "bold" | "semibold" | "normal"; } = {
    h1: "bold",
    h2: "bold",
    h3: "bold",
    h4: "semibold",
    h5: "semibold",
    h6: "semibold",
    p: "normal",
};

export const RESUME_CATEGORIES = [
    "Modern",
    "Classic",
    "Compact",
    "Harvard",
    "ATS",
    "Double Column",
];

export const RESUME_TAGS = [
    "Executive",
    "Sales",
    "Digital Marketer",
    "Data Scientist",
    "Product Manager",
    "Finance",
    "Software Engineer",
    "Analyst",
    "Operations",
];
