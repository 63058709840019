import { useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { getSectionItemMarginYStyle } from "@/components/PdfDocument/utils/getStyles";
import { ActionsToolbar } from "@/components/Section/ActionsToolbar";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { AdminPip } from "@/pages/Admin/components/AdminPip";
import { getDebugBuilder } from "@/store/app/selectors";
import { setDefaultSectionHeight, setSectionHeight } from "@/store/pages/slice";
import {
    getActiveResumeSectionIds,
    getResumeSectionColumnIndexById,
} from "@/store/resume/selectors";
import { getActiveItemId } from "@/store/template/selectors";
import { SectionSpacingSize } from "@/types/resume";

interface SectionItemContainerProps {
    children: React.ReactNode;
    shouldMeasure?: boolean;
    itemId: string;
    sectionId: string;
    sectionConfigId: string;
    itemIndex: number;
    isEditable: boolean;
    sectionSpacingSize: SectionSpacingSize;
    isLastItem: boolean;
    isDefaultMeasurement?: boolean;
}

export const SectionItemContainer = ({
    children,
    shouldMeasure,
    itemId,
    sectionId,
    sectionConfigId,
    itemIndex,
    isEditable,
    sectionSpacingSize,
    isLastItem,
    isDefaultMeasurement,
}: SectionItemContainerProps) => {
    const sectionItemStyle = getSectionItemMarginYStyle(
        sectionSpacingSize,
        isLastItem,
    );
    const debugBuilder = useAppSelector(getDebugBuilder);
    const [ref, { height }] = useMeasure();
    const dispatch = useAppDispatch();
    const activeResumeSectionIds = useAppSelector(getActiveResumeSectionIds);
    const sectionColumnIndex = useAppSelector(state =>
        getResumeSectionColumnIndexById(state, sectionId),
    );

    const activeItemId = useAppSelector(getActiveItemId);
    const [currentHeight, setCurrentHeight] = useState(height);

    useEffect(() => {
        if (shouldMeasure && height > 0 && height !== currentHeight) {
            const addToPage =
                activeResumeSectionIds.includes(sectionId) &&
                !isDefaultMeasurement;

            const measurementDetails = {
                type: "item" as const,
                itemId,
                section: sectionId,
                sectionConfigId,
                height: height + (sectionItemStyle.marginBottom ?? 0),
                columnIndex: sectionColumnIndex ?? 0,
                positionIndex: null,
                isStaticHeader: false,
                itemIndex,
            };

            if (addToPage) {
                dispatch(setSectionHeight(measurementDetails));
                setCurrentHeight(height);
            }

            if (isDefaultMeasurement) {
                dispatch(setDefaultSectionHeight(measurementDetails));
            }
        }
    }, [
        shouldMeasure,
        height,
        currentHeight,
        activeResumeSectionIds,
        sectionId,
        itemId,
        sectionConfigId,
        sectionItemStyle.marginBottom,
        itemIndex,
        dispatch,
        isDefaultMeasurement,
        sectionColumnIndex,
    ]);

    const isActiveItem = isEditable && activeItemId === itemId;

    return (
        <div
            ref={shouldMeasure ? ref : undefined}
            style={sectionItemStyle}
            className="relative"
        >
            {isActiveItem && (
                <div className="pointer-events-none absolute inset-0 rounded-sm ring-2 ring-neutral-300 ring-offset-2 " />
            )}
            {isActiveItem && (
                <ActionsToolbar
                    sectionItemId={itemId}
                    itemIndex={itemIndex}
                    isLastItem={isLastItem}
                />
            )}
            {children}
            {debugBuilder && (
                <div className="absolute right-0 top-0">
                    <AdminPip colour="bg-primary-100">
                        <AppText
                            variant="footnote"
                            className="font-poppins text-xs"
                        >
                            idx: {itemIndex} | {height}px
                        </AppText>
                    </AdminPip>
                </div>
            )}
        </div>
    );
};
