import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { LayoutList } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { IconButton } from "@/components/Button/IconButton";
import { PresetsMenu } from "@/components/Presets/PresetsMenu";
import { useAppDispatch } from "@/hooks/types";
import { resetAiToolbox } from "@/store/app/slice";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

export const PresetIconButton = ({
    children,
    title,
    setIsPresetsMenuOpen,
    isPresetsMenuOpen,
    anchorTo = "top",
    type,
}: {
    children: React.ReactNode;
    title: string;
    setIsPresetsMenuOpen?: (isOpen: boolean) => void;
    isPresetsMenuOpen?: boolean;
    anchorTo?: "top" | "bottom";
    type: "header" | "section";
}) => {
    const popoverElement = useRef<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(!!isPresetsMenuOpen);
    const dispatch = useAppDispatch();
    useEffect(() => {
        setIsMenuOpen(!!isPresetsMenuOpen);
    }, [isPresetsMenuOpen]);

    useEffect(() => {
        if (!popoverElement.current) {
            setIsPresetsMenuOpen?.(false);
        }
    }, [popoverElement, setIsPresetsMenuOpen]);

    const handleOutsideClick = useCallback(
        (event: MouseEvent) => {
            if (
                popoverElement.current &&
                !popoverElement.current.contains(event.target as Node)
            ) {
                setIsPresetsMenuOpen?.(false);
            }
        },
        [setIsPresetsMenuOpen],
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [handleOutsideClick, setIsPresetsMenuOpen]);

    return (
        <Popover className="relative">
            <PopoverButton
                as="div"
                className="outline-none"
                onClick={() => {
                    if (!isMenuOpen) {
                        trackClarityEvent(
                            type === "header"
                                ? CLARITY_EVENT_NAMES.PRESET_HEADER_USED
                                : CLARITY_EVENT_NAMES.PRESET_SECTION_USED,
                        );
                    }
                    const isOpening = !isMenuOpen;
                    if (setIsPresetsMenuOpen) {
                        setIsPresetsMenuOpen?.(isOpening);
                    } else {
                        setIsMenuOpen(isOpening);
                    }
                    if (isOpening) {
                        dispatch(resetAiToolbox());
                    }
                }}
            >
                <IconButton
                    variant={"solid"}
                    color={"white"}
                    className={clsx(isMenuOpen ? "!bg-neutral-200" : "")}
                    chevronDirection={
                        anchorTo === "bottom"
                            ? isMenuOpen
                                ? "up"
                                : "down"
                            : isMenuOpen
                              ? "down"
                              : "up"
                    }
                >
                    <LayoutList size={16} />
                </IconButton>
            </PopoverButton>
            <AnimatePresence>
                {isMenuOpen && (
                    <PopoverPanel
                        static
                        as={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        anchor={{
                            to: anchorTo,
                            gap: 16,
                        }}
                        className="flex flex-col"
                        ref={popoverElement}
                    >
                        <PresetsMenu title={title}>{children}</PresetsMenu>
                    </PopoverPanel>
                )}
            </AnimatePresence>
        </Popover>
    );
};
