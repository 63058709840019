import { Download, ExternalLink, Files } from "lucide-react";
import { useMemo } from "react";
import { CreditsIcon, SmartScribe } from "@/components/Icons/Icons";
import { Modal } from "@/components/Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { useTransaction } from "@/hooks/useTransaction";
import { getIsModalOpen } from "@/store/modal/selectors";
import { CreditActionCode } from "@/types/creditAction";
import { ModalTypes } from "@/types/modal";
import { SiteRoutes } from "@/types/routes";
import { formatCreditAmount } from "@/utils/string";
import { addUTMParams } from "@/utils/tracking";

const CreditsWelcomeModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.CREDITS_WELCOME));
    const { getCreditActionByCode } = useTransaction();
    const additionalResumeAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.INCREASE_DOCUMENT_LIMIT),
        [getCreditActionByCode],
    );
    const rewriteAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.AI_PROMPT_REWRITE),
        [getCreditActionByCode],
    );
    const downloadAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.DOWNLOAD_DOCUMENT),
        [getCreditActionByCode],
    );
    const signUpAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.SIGN_UP),
        [getCreditActionByCode],
    );
    const signUpBonusAction = useMemo(
        () => getCreditActionByCode(CreditActionCode.SIGN_UP_BONUS),
        [getCreditActionByCode],
    );

    const totalSignUpCredits = useMemo(() => {
        return (signUpAction?.credits ?? 0) + (signUpBonusAction?.credits ?? 0);
    }, [signUpAction, signUpBonusAction]);

    return (
        <Modal
            open={isOpen}
            modalType={ModalTypes.CREDITS_WELCOME}
            noHeader
            width="3xl"
            gradientBackground
            title=""
            floatingCloseButton
        >
            <div className="flex flex-col items-center">
                <AppText
                    variant="headings"
                    className="my-4 w-full text-center text-2xl font-bold text-primary-900 lg:w-2/3"
                >
                    Welcome to 1Template!
                </AppText>

                <AppText
                    variant="subheadings"
                    className="mb-4 w-full text-center text-neutral-900 lg:w-4/5"
                >
                    You&apos;ve received{" "}
                    <AppText
                        variant="subheadings"
                        applyGradient
                        className="inline"
                    >
                        {totalSignUpCredits} bonus credits
                    </AppText>{" "}
                    to get started
                </AppText>

                <AppText
                    variant="regular"
                    className="mb-6 w-full text-center text-neutral-700 lg:w-4/5"
                >
                    Use credits to unlock premium features and craft your
                    perfect resume. Each feature has a different credit
                    cost—here&apos;s what you can do:
                </AppText>

                <div className="grid grid-cols-1 gap-8 px-2 md:grid-cols-2 lg:w-full lg:grid-cols-3">
                    {rewriteAction && (
                        <FeatureCard
                            icon={<SmartScribe gradient />}
                            title="SMART SCRIBE"
                            description="Craft a better resume with AI prompts"
                            creditLine="Per Use"
                            creditCost={rewriteAction?.credits ?? 0}
                        />
                    )}
                    {additionalResumeAction && (
                        <FeatureCard
                            icon={<Files size={19} />}
                            title="MULTIPLE RESUMES"
                            description="Create more specialised resumes for different jobs"
                            creditLine="Per Resume"
                            creditCost={additionalResumeAction?.credits ?? 0}
                        />
                    )}
                    {downloadAction && (
                        <FeatureCard
                            icon={<Download size={19} />}
                            title="PDF DOWNLOAD"
                            description="Download your resume as a PDF"
                            creditLine="Per Download"
                            creditCost={downloadAction?.credits ?? 0}
                            className="md:col-span-2 lg:col-span-1"
                        />
                    )}
                </div>
                <a
                    href={addUTMParams(SiteRoutes.Pricing, {
                        source: "app",
                        medium: "internal_link",
                        campaign: "credits_welcome_modal",
                        content: "learn_more_about_credits",
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary mt-10 flex items-center justify-center gap-2 text-neutral-500 hover:underline"
                >
                    <ExternalLink size={14} />
                    <AppText
                        variant="regular"
                        className="underline"
                    >
                        Learn more about Credits
                    </AppText>
                </a>
            </div>
        </Modal>
    );
};

interface FeatureCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    creditLine: string;
    creditCost: number;
    className?: string;
}

const FeatureCard = ({
    icon,
    title,
    description,
    creditLine,
    creditCost,
    className,
}: FeatureCardProps) => {
    return (
        <div className={`flex flex-col items-center ${className || ""}`}>
            <div className="bg-primary mb-3 flex h-8 w-8 items-center justify-center rounded-lg bg-neutral-50 text-primary-700">
                {icon}
            </div>
            <AppText
                variant="labelsbuttons"
                className="mb-2 text-center font-semibold text-neutral-900"
            >
                {title}
            </AppText>
            <AppText
                variant="regular"
                className="mb-3 text-center text-neutral-900"
            >
                {description}
            </AppText>
            <div className="mt-2 flex flex-row items-center gap-1 rounded-lg bg-neutral-50 px-2 py-[2px]">
                <AppText
                    variant="regular"
                    applyGradient
                    className="flex items-center gap-2 !font-semibold"
                >
                    <CreditsIcon className="h-4 w-4" />
                    {formatCreditAmount(creditCost, true, true)}
                </AppText>
                <AppText
                    variant="regular"
                    className="!font-semibold text-neutral-700"
                >
                    {creditLine}
                </AppText>
            </div>
        </div>
    );
};

export default CreditsWelcomeModal;
