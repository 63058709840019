import {
    Popover,
    PopoverButton,
    PopoverPanel,
    Transition,
} from "@headlessui/react";
import { MessageCircle, XCircle } from "lucide-react";
import { Fragment, useCallback } from "react";
import userApi from "@/api/user";
import { IconButton } from "@/components/Button/IconButton";
import { FeedbackSurvey } from "@/components/FeedbackSurvey/FeedbackSurvey";
import { AppText } from "@/components/Text/AppText";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { TooltipContent } from "@/components/Tooltip/TooltipContent";
import { TooltipTrigger } from "@/components/Tooltip/TooltipTrigger";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getShowFeedbackSurvey } from "@/store/app/selectors";
import { setShowFeedbackSurvey } from "@/store/app/slice";
import {
    getUserFlags,
    getUserId,
    getUserFeatureByActionCode,
} from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { CreditActionCode } from "@/types/creditAction";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { USER_FLAGS } from "@/types/user";
import { trackClarityEvent } from "@/utils/tracking";

export const FeedbackSurveyButton = () => {
    const showFeedbackSurvey = useAppSelector(getShowFeedbackSurvey);
    const dispatch = useAppDispatch();
    const userFlags = useAppSelector(getUserFlags);
    const userId = useAppSelector(getUserId);
    const isSurveyAvailable = useAppSelector(state =>
        getUserFeatureByActionCode(state, CreditActionCode.APP_FEEDBACK_SURVEY),
    );

    const onOpenFeedbackSurvey = useCallback(async () => {
        if (!showFeedbackSurvey) {
            trackClarityEvent(CLARITY_EVENT_NAMES.FEEDBACK_SURVEY_OPENED);
        }
        const newUser = await userApi.saveUserApi(
            {
                flags: {
                    ...userFlags,
                    [USER_FLAGS.FEEDBACK_SURVEY_TIMESTAMP]:
                        new Date().toISOString(),
                },
            },
            userId,
        );
        dispatch(setUserDetails(newUser));
        dispatch(setShowFeedbackSurvey(!showFeedbackSurvey));
    }, [dispatch, showFeedbackSurvey, userFlags, userId]);

    return isSurveyAvailable ? (
        <div className="fixed bottom-4 right-4 z-30 rounded-lg shadow-2xl">
            <Popover className="relative">
                <Tooltip>
                    <TooltipTrigger>
                        <PopoverButton
                            as={IconButton}
                            size="36px"
                            className={`feedback-survey-button group ring-2 ring-neutral-200`}
                            onClick={onOpenFeedbackSurvey}
                        >
                            <div className="pointer-events-none relative h-5 w-5">
                                <Transition
                                    show={!showFeedbackSurvey}
                                    as={Fragment}
                                    enter="transform transition duration-200"
                                    enterFrom="opacity-0 rotate-45"
                                    enterTo="opacity-100 rotate-0"
                                    leave="transform transition duration-200"
                                    leaveFrom="opacity-100 rotate-0"
                                    leaveTo="opacity-0 rotate-45"
                                >
                                    <MessageCircle
                                        className="absolute inset-0"
                                        size={20}
                                    />
                                </Transition>
                                <Transition
                                    show={showFeedbackSurvey}
                                    as={Fragment}
                                    enter="transform transition duration-200"
                                    enterFrom="opacity-0 -rotate-45"
                                    enterTo="opacity-100 rotate-0"
                                    leave="transform transition duration-200"
                                    leaveFrom="opacity-100 rotate-0"
                                    leaveTo="opacity-0 -rotate-45"
                                >
                                    <XCircle
                                        className="absolute inset-0"
                                        size={20}
                                    />
                                </Transition>
                            </div>
                        </PopoverButton>
                    </TooltipTrigger>
                    <TooltipContent>
                        <AppText variant="footnote">Submit Feedback</AppText>
                    </TooltipContent>
                </Tooltip>
                <Transition
                    show={showFeedbackSurvey}
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel
                        static
                        className="absolute bottom-full right-0 mb-2 w-[250px] rounded-lg bg-neutral-50 shadow-xl ring-primary-900"
                    >
                        <FeedbackSurvey />
                    </PopoverPanel>
                </Transition>
            </Popover>
        </div>
    ) : null;
};
