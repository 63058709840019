import { useEffect, useMemo } from "react";
import { PageContent } from "@/components/Page/PageContent";
import {
    getColumnStyle,
    getPageHorizontalMarginStyle,
} from "@/components/PdfDocument/utils/getStyles";
import { ReadOnlySection } from "@/components/Section/ReadOnlySection";
import { LoadingDocumentPage } from "@/components/TemplateLoader/LoadingDocumentPage";
import { getSectionContainerWidth } from "@/components/TemplateLoader/utils/getSectionContainerWidth";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { setTemplateLoadingState } from "@/store/app/slice";
import { getMeasurements, getPages } from "@/store/pages/selectors";
import { buildInitialPages } from "@/store/pages/slice";
import {
    getActiveResume,
    getActiveResumeGlobalStyles,
    getLoaderSections,
} from "@/store/resume/selectors";
import {
    getSectionDetailsBySectionConfigId,
    getSectionDetailsIdBySectionConfigId,
} from "@/store/sections/utils";
import { Section } from "@/types";
import { TemplateLoadingState } from "@/types/app";
import { ColumnLayout } from "@/types/resume";
import { getIsPreviewPage } from "@/utils/routes";

const READY_DELAY = 1000;

export const TemplateLoader = ({ overlay }: { overlay?: React.ReactNode }) => {
    const activeResume = useAppSelector(getActiveResume);
    const columnLayout = activeResume?.columnLayout;
    const isPreviewPage = getIsPreviewPage();
    const { resumeSections, sectionConfigs, allDefaultSections } =
        useAppSelector(getLoaderSections);
    const measurements = useAppSelector(getMeasurements);
    const pages = useAppSelector(getPages);
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);

    const isTwoColumn = useMemo(
        () => columnLayout === ColumnLayout.DOUBLE,
        [columnLayout],
    );
    const headerSectionConfigId = sectionConfigs.find(
        section => section.isStaticHeader,
    )?.id;

    const measurementSectionIds = useMemo(() => {
        return measurements.map(measurement => measurement.section);
    }, [measurements]);

    const numberOfResumeSectionItems = useMemo(() => {
        return Object.values(resumeSections ?? {}).reduce((acc, section) => {
            const bodyItemsWithValues = section.body?.filter(
                item => Object.keys(item).length,
            );
            return acc + (bodyItemsWithValues?.length ?? 0);
        }, 0);
    }, [resumeSections]);

    const everySectionHasMeasurement = useMemo(() => {
        return (
            measurements.length > 0 &&
            measurements.length >= numberOfResumeSectionItems
        );
    }, [measurements.length, numberOfResumeSectionItems]);

    const everyMeasurementSectionIsOnAPage = useMemo(() => {
        return measurementSectionIds.every(sectionId => {
            return pages.some(page =>
                page.sections.some(section => section.sectionId === sectionId),
            );
        });
    }, [pages, measurementSectionIds]);

    const leftColumnStyle = useMemo(() => {
        return getColumnStyle(isTwoColumn, 0);
    }, [isTwoColumn]);

    const rightColumnStyle = useMemo(() => {
        return getColumnStyle(isTwoColumn, 1);
    }, [isTwoColumn]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        let timeout: null | number = null;
        if (!everySectionHasMeasurement) return;

        if (everyMeasurementSectionIsOnAPage) {
            // All section measurements are on a page
            timeout = window.setTimeout(() => {
                dispatch(setTemplateLoadingState(TemplateLoadingState.READY));
            }, READY_DELAY);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [
        dispatch,
        everyMeasurementSectionIsOnAPage,
        everySectionHasMeasurement,
    ]);

    useEffect(() => {
        if (everySectionHasMeasurement) {
            dispatch(buildInitialPages());
        }
    }, [dispatch, everySectionHasMeasurement]);

    const allSections = useMemo(() => {
        if (isPreviewPage) {
            return { ...resumeSections };
        }
        return { ...resumeSections, ...allDefaultSections };
    }, [isPreviewPage, resumeSections, allDefaultSections]);

    const headerSectionId = getSectionDetailsIdBySectionConfigId(
        resumeSections ?? {},
        headerSectionConfigId ?? "",
    );

    const pageHorizontalMargins = getPageHorizontalMarginStyle(
        globalStyles?.pageMarginSize,
    );

    const memoizedSections = useMemo(() => {
        return Object.keys(allSections)
            .map(sectionId => {
                if (sectionId === headerSectionId) return null;
                const isUsedSection = !!resumeSections[sectionId];
                const sectionDetails = { ...allSections[sectionId] };

                if (!sectionDetails) return null;

                if (!isUsedSection) {
                    sectionDetails.columnIndex = 0;
                    sectionDetails.positionIndex = 0;
                    sectionDetails.activeWidth = "full";
                } else {
                    if (sectionDetails?.body) {
                        // If a value in a field is empty or an empty html tag, add in a space just to make the field height correct
                        const emptyTags = [
                            "<p></p>",
                            "<h1></h1>",
                            "<h2></h2>",
                            "<h3></h3>",
                            "<h4></h4>",
                            "<h5></h5>",
                            "<h6></h6>",
                            "<span></span>",
                        ];
                        sectionDetails.body = sectionDetails.body.map(item => {
                            const newItem = { ...item };
                            Object.keys(newItem).forEach(key => {
                                if (
                                    !newItem[key] ||
                                    emptyTags.includes(newItem[key])
                                ) {
                                    newItem[key] = "<p>Blank</p>";
                                }
                            });

                            return newItem;
                        });
                    }
                }

                const columnStyle = getColumnStyle(
                    isTwoColumn,
                    isUsedSection ? sectionDetails.columnIndex : 0,
                );
                const sectionConfig = sectionConfigs?.find(
                    section => section.id === sectionDetails.sectionConfigId,
                );
                const sectionWidthClass = getSectionContainerWidth(
                    columnLayout,
                    sectionDetails,
                    sectionConfig,
                );

                return {
                    sectionId,
                    sectionDetails,
                    columnStyle,
                    sectionConfig,
                    sectionWidthClass,
                } as const;
            })
            .filter(Boolean);
    }, [
        allSections,
        headerSectionId,
        resumeSections,
        isTwoColumn,
        sectionConfigs,
        columnLayout,
    ]) as Array<{
        sectionId: string;
        sectionDetails: (typeof allSections)[string];
        columnStyle: ReturnType<typeof getColumnStyle>;
        sectionConfig: Section | undefined;
        sectionWidthClass: string;
    }>;

    const leftColumnSections = useMemo(() => {
        return memoizedSections.filter(
            section => section.sectionDetails.columnIndex === 0,
        );
    }, [memoizedSections]);

    const rightColumnSections = useMemo(() => {
        return memoizedSections.filter(
            section => section.sectionDetails.columnIndex === 1,
        );
    }, [memoizedSections]);

    if (!activeResume) return null;

    return (
        <div className="absolute left-0 top-0 h-full w-full transform bg-neutral-100 pb-10 pt-24 duration-300 ease-in-out">
            {overlay || (
                <PageContent
                    id="document-loader-screen"
                    style={{
                        fontFamily: globalStyles?.fontFamily,
                    }}
                    className="document-page relative grid"
                    pageNumber={0}
                >
                    <LoadingDocumentPage />
                </PageContent>
            )}
            <PageContent
                id="document-loader-sections"
                style={{
                    fontFamily: globalStyles?.fontFamily,
                }}
                className="document-page relative grid overflow-y-scroll opacity-0"
                pageNumber={0}
            >
                {headerSectionId && (
                    <ReadOnlySection
                        sectionId={headerSectionId}
                        shouldMeasure={true}
                        resumeId={activeResume.id}
                        overrideSectionDetails={getSectionDetailsBySectionConfigId(
                            resumeSections,
                            headerSectionConfigId,
                        )}
                        pageNumber={0}
                    />
                )}
                <div
                    id="PageGrid"
                    className="grid grid-cols-2"
                    style={{
                        ...pageHorizontalMargins, // PageGrid styles
                        gridTemplateColumns: !isTwoColumn ? "1fr" : "1fr 40%",
                    }}
                >
                    <div
                        style={leftColumnStyle}
                        className="col-span-1"
                    >
                        {leftColumnSections.map(
                            ({
                                sectionId,
                                sectionDetails,
                                sectionConfig,
                                sectionWidthClass,
                            }) => {
                                return (
                                    <div
                                        key={sectionId}
                                        className={sectionWidthClass}
                                        id={sectionConfig?.name}
                                    >
                                        <ReadOnlySection
                                            sectionId={sectionId}
                                            shouldMeasure={true}
                                            resumeId={activeResume.id}
                                            overrideSectionDetails={
                                                sectionDetails
                                            }
                                            pageNumber={0}
                                            isDefaultMeasurement={
                                                sectionDetails.__isDefaultContent
                                            }
                                        />
                                    </div>
                                );
                            },
                        )}
                    </div>
                    {rightColumnSections.length > 0 && (
                        <div
                            style={rightColumnStyle}
                            className="col-span-1"
                        >
                            {rightColumnSections.map(
                                ({
                                    sectionId,
                                    sectionDetails,
                                    sectionConfig,
                                    sectionWidthClass,
                                }) => {
                                    return (
                                        <div
                                            key={sectionId}
                                            className={sectionWidthClass}
                                            id={sectionConfig?.name}
                                        >
                                            <ReadOnlySection
                                                sectionId={sectionId}
                                                shouldMeasure={true}
                                                resumeId={activeResume.id}
                                                overrideSectionDetails={
                                                    sectionDetails
                                                }
                                                pageNumber={0}
                                                isDefaultMeasurement={
                                                    sectionDetails.__isDefaultContent
                                                }
                                            />
                                        </div>
                                    );
                                },
                            )}
                        </div>
                    )}
                </div>
            </PageContent>
        </div>
    );
};
