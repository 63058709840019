import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import creditActionApi from "@/api/creditAction";
import creditTransactionApi, {
    CreateTransactionRequest,
} from "@/api/creditTransaction";
import { TransactionContext } from "@/context/TransactionContext";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { queryKeys } from "@/hooks/useAppData";
import { openModal } from "@/store/modal/slice";
import { getUserCreditBalance, getUserId } from "@/store/user/selectors";
import { updateUserDetails } from "@/store/user/slice";
import { ICreditAction } from "@/types/creditAction";
import { PerformActionParams } from "@/types/creditTransaction";
import { ModalTypes } from "@/types/modal";
import { formatCreditAmount } from "@/utils/string";

const THIRTY_MINUTES = 30 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

export const TransactionProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const dispatch = useAppDispatch();
    const currentUserId = useAppSelector(getUserId);
    const [isUpdatingBalance, setIsUpdatingBalance] = useState(false);
    const creditBalance = useAppSelector(getUserCreditBalance);

    const { data: creditActions = [] } = useQuery({
        queryKey: queryKeys.creditActions,
        queryFn: async () => {
            const response = await creditActionApi.getCreditActionsApi({
                limit: 999,
            });
            return response.results;
        },
        staleTime: THIRTY_MINUTES,
        gcTime: ONE_HOUR,
    });

    const getCreditActions = (filter: {
        actionCode?: string;
        type?: string;
    }) => {
        let actions = creditActions.filter(action => action.isActive);

        if (filter.actionCode) {
            actions = actions.filter(
                action => action.code === filter.actionCode,
            );
        }

        if (filter.type) {
            actions = actions.filter(action => action.type === filter.type);
        }

        return actions;
    };

    const getCreditActionByCode = (actionCode: string) => {
        return creditActions.find(
            action => action.code === actionCode && action.isActive,
        );
    };

    const getCanAffordCreditAction = (actionCode: string) => {
        const action = getCreditActionByCode(actionCode);
        if (!action) {
            return false;
        }
        return creditBalance - (action.credits ?? 0) >= 0;
    };

    const getCreditActionCostByCode = (actionCode: string) => {
        const action = getCreditActionByCode(actionCode);
        const credits = Number(action?.credits);
        return isNaN(credits) ? 0 : Math.abs(credits);
    };

    const getAndFormatCreditActionCostByCode = (actionCode: string) => {
        const credits = getCreditActionCostByCode(actionCode);
        if (credits === 0) {
            return ""; // Should avoid showing 0 credits
        }
        return formatCreditAmount(credits);
    };

    const handleInsufficientFunds = ({
        transactionAmount,
        availableBalance,
        actionCode,
    }: {
        transactionAmount: number;
        availableBalance: number;
        actionCode: string;
    }) => {
        dispatch(
            openModal({
                modalType: ModalTypes.INSUFFICIENT_FUNDS,
                props: { transactionAmount, availableBalance, actionCode },
            }),
        );
    };

    const getTransactionAmount = (action: ICreditAction, amount?: number) => {
        // Variable amount actions require an amount

        // Variable amount actions require an amount
        if (action.amountType === "VARIABLE") {
            if (amount === undefined) {
                throw new Error(
                    "Amount is required for variable credit actions",
                );
            }
            return amount;
        }

        // Fixed amount actions use the predefined credit amount
        return action.credits || 0;
    };

    const validateAndAddTransaction = async (params: PerformActionParams) => {
        const { creditActionError, hasInsufficientFunds } =
            validateCreditAction(params);

        if (creditActionError || hasInsufficientFunds) {
            throw new Error(creditActionError || "Insufficient funds");
        }

        await addTransaction(params);
    };

    const validateCreditAction = ({
        actionCode,
        amount,
        userId = currentUserId,
    }: PerformActionParams) => {
        const action = getCreditActionByCode(actionCode);
        if (!action || !userId) {
            return {
                creditActionError: "Action or userId not found",
                hasInsufficientFunds: false,
            };
        }

        if (action.amountType === "VARIABLE" && amount === undefined) {
            return {
                creditActionError:
                    "Amount is required for variable credit actions",
                hasInsufficientFunds: false,
            };
        }

        const transactionAmount = getTransactionAmount(action, amount);

        if (transactionAmount < 0) {
            const newBalance = creditBalance + transactionAmount; // Amounts are defined as negative values

            if (newBalance < 0) {
                handleInsufficientFunds({
                    transactionAmount,
                    availableBalance: creditBalance,
                    actionCode,
                });
                return {
                    creditActionError: "Insufficient funds",
                    hasInsufficientFunds: true,
                };
            }
        }

        return {
            creditActionError: null,
            hasInsufficientFunds: false,
        };
    };

    const addTransaction = async ({
        actionCode,
        amount,
        metadata,
        description,
        createdBy,
        userId = currentUserId,
    }: PerformActionParams) => {
        try {
            const action = getCreditActionByCode(actionCode);
            if (!action || !userId) {
                throw new Error("Action or userId not found");
            }

            setIsUpdatingBalance(true);

            const transactionMetadata = {
                ...metadata,
            };

            const transactionAmount = getTransactionAmount(action, amount);

            const newTransaction: CreateTransactionRequest = {
                actionCode,
                amount: transactionAmount,
                description: description || action.description || "",
                metadata: transactionMetadata,
                userId,
                createdBy: createdBy || userId,
                type: action.type,
            };

            const { userUpdates } =
                await creditTransactionApi.createTransaction(newTransaction);

            if (currentUserId === userId) {
                dispatch(updateUserDetails(userUpdates));
            }
        } catch (error) {
            console.error("Error adding transaction", error);
        } finally {
            setIsUpdatingBalance(false);
        }
    };

    return (
        <TransactionContext.Provider
            value={{
                addTransaction,
                validateCreditAction,
                validateAndAddTransaction,
                getCreditActionCostByCode,
                getAndFormatCreditActionCostByCode,
                isUpdatingBalance,
                getCanAffordCreditAction,
                getCreditActions,
                getCreditActionByCode,
            }}
        >
            {children}
        </TransactionContext.Provider>
    );
};
