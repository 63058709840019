import clsx from "clsx";
import { useCallback, useEffect, useMemo } from "react";
import { Alert } from "@/components/Alert/Alert";
import { CreditPackSummary } from "@/components/Credits/CreditPackSummary";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { useTransaction } from "@/hooks/useTransaction";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { StripeItem } from "@/types/billing";
import { ICreditAction } from "@/types/creditAction";

interface CreditPacksProps {
    onPackSelect: (creditAction: ICreditAction, product: StripeItem) => void;
}

export const CreditPacks = ({ onPackSelect }: CreditPacksProps) => {
    const allStripeItems = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const { getCreditActions } = useTransaction();
    const purchaseCreditActions = useMemo(
        () => getCreditActions({ type: "PURCHASE" }),
        [getCreditActions],
    );
    const { getStripeProducts } = useStripe();

    const stripeProducts = useMemo(
        () => allStripeItems?.filter(product => !product.isSubscription),
        [allStripeItems],
    );

    const onPackSelectCallback = useCallback(
        (creditAction: ICreditAction, product: StripeItem) => {
            onPackSelect(creditAction, product);
        },
        [onPackSelect],
    );

    useEffect(() => {
        if (allStripeItems === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, allStripeItems, loadingProducts, errorProducts]);

    if (errorProducts) {
        return (
            <div className="flex h-[230px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    return (
        <div className={clsx()}>
            {loadingProducts ? (
                <div className="flex min-h-[430px] items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                    {stripeProducts?.map(pack => {
                        return (
                            <CreditPackSummary
                                key={pack.priceId}
                                pack={pack}
                                purchaseCreditActions={purchaseCreditActions}
                                onPackSelect={onPackSelectCallback}
                            />
                        );
                    })}
                </div>
            )}
        </div>
    );
};
