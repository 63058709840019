import { useState } from "react";
import { WhatsappShareButton, FacebookShareButton } from "react-share";
import { IconButton } from "@/components/Button/IconButton";
import {
    WhatsAppIcon,
    LinkedInIcon,
    FacebookIcon,
} from "@/components/Icons/SocialIcons";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";

interface ShareButtonsProps {
    url: string;
    message: string;
    className?: string;
}

export const ShareButtons = ({
    url,
    message,
    className = "",
}: ShareButtonsProps) => {
    const [isSharing, setIsSharing] = useState(false);

    const getShareLink = (source: string) => {
        // Make sure we have the full URL including protocol
        const fullUrl = url.startsWith("http")
            ? url
            : `${window.location.origin}${url}`;
        return `${fullUrl}&utm_source=${source}`;
    };

    const shareTitle = "Join me on 1Template";
    const shareDescription =
        "Create professional resumes with 1Template, build your resume in 10 minutes with the most advanced AI-powered builder";

    const handleShareClick = () => {
        setIsSharing(true);
        // Reset meta tags after a short delay
        setTimeout(() => {
            setIsSharing(false);
        }, 1000);
    };

    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(getShareLink("linkedin"))}`;

    return (
        <>
            {isSharing && (
                <PageHelmet
                    title={shareTitle}
                    description={shareDescription}
                    url={url}
                />
            )}

            <div className={`flex justify-center gap-2 ${className}`}>
                <a
                    href={linkedinShareUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:opacity-80"
                    onClick={handleShareClick}
                >
                    <IconButton
                        variant="text"
                        color="primary"
                    >
                        <LinkedInIcon className="h-6 w-6" />
                    </IconButton>
                </a>

                <FacebookShareButton
                    url={getShareLink("facebook")}
                    className="hover:opacity-80"
                    onClick={handleShareClick}
                >
                    <IconButton
                        variant="text"
                        color="primary"
                    >
                        <FacebookIcon className="h-6 w-6" />
                    </IconButton>
                </FacebookShareButton>

                <WhatsappShareButton
                    url={getShareLink("whatsapp")}
                    title={message}
                    className="hover:opacity-80"
                    onClick={handleShareClick}
                >
                    <IconButton
                        variant="text"
                        color="primary"
                    >
                        <WhatsAppIcon className="h-6 w-6" />
                    </IconButton>
                </WhatsappShareButton>
            </div>
        </>
    );
};
