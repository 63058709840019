import {
    CredentialResponse,
    GoogleLogin,
    useGoogleLogin,
} from "@react-oauth/google";
import userApi from "@/api/user";
import { Google } from "@/components/Icons/Icons";
import { AppText } from "@/components/Text/AppText";
import { User } from "@/types";
import { SiteRoutes } from "@/types/routes";
import { Tokens } from "@/types/token";

export type SSOLogin = {
    setError: (error: string) => void;
    onSuccess: (user: User, tokens: Tokens) => void;
};

export const SSOLogin = ({ setError, onSuccess }: SSOLogin) => {
    const onOneTapSuccess = async (credentialResponse: CredentialResponse) => {
        const { tokens, user } = await userApi.ssoLoginApi(
            "credential",
            credentialResponse.credential ?? "",
            "google",
        );
        onSuccess(user, tokens);
    };

    const onOneTapError = () => {
        setError("Failed to login with Google");
    };

    const loginWithGoogle = useGoogleLogin({
        flow: "auth-code",
        onSuccess: async ({ code }) => {
            const { tokens, user } = await userApi.ssoLoginApi(
                "code",
                code,
                "google",
            );
            onSuccess(user, tokens);
        },
        onError: e => {
            if (e?.error !== "access_denied") {
                setError("Failed to login with Google");
            }
        },
    });

    return (
        <div>
            <div className="relative mb-5 mt-3">
                <div className="mx-auto w-[90%] border-t border-neutral-900 px-4" />
            </div>
            <div>
                <button
                    onClick={() => {
                        loginWithGoogle();
                    }}
                    className="mx-auto flex h-10 items-center justify-center rounded-lg bg-neutral-50 pl-[2px] pr-3 ring-2 ring-[#35475a33] hover:bg-[#F2F2F2]"
                >
                    <Google className="h-10 w-10" />
                    <AppText variant="labelsbuttons">
                        Continue with Google
                    </AppText>
                </button>
            </div>
            <div className="hidden">
                <GoogleLogin
                    useOneTap
                    onSuccess={onOneTapSuccess}
                    onError={onOneTapError}
                    cancel_on_tap_outside={false}
                />
            </div>
            <div className="mt-4 flex justify-center">
                <AppText
                    variant="footnote"
                    className="w-[250px] text-center text-neutral-700"
                >
                    By continuing, you agree to 1Template&apos;s{" "}
                    <a
                        href={SiteRoutes.Terms}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-700"
                    >
                        Terms of Service
                    </a>
                    . Read our{" "}
                    <a
                        href={SiteRoutes.PrivacyPolicy}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-700"
                    >
                        Privacy Policy
                    </a>
                    .
                </AppText>
            </div>
        </div>
    );
};
