import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { AppText } from "@/components/Text/AppText";

interface NavigationPillProps {
    to: string;
    target?: string;
    rel?: string;
    label: string;
    variant?: "primary" | "white" | "dark" | "light";
}

export const NavigationPill = ({
    to,
    target,
    rel,
    label,
    variant = "primary",
}: NavigationPillProps) => {
    const textColor =
        variant === "primary" ? "text-neutral-50" : "text-neutral-900";
    const bgColor =
        variant === "primary" ? "bg-primary-700" : "bg-neutral-white";
    const hoverBgColor =
        variant === "primary" ? "hover:bg-primary-600" : "hover:bg-neutral-100";
    const activeBgColor =
        variant === "primary"
            ? "active:bg-primary-800"
            : "active:bg-neutral-100";
    return (
        <NavLink
            to={to}
            target={target}
            rel={rel}
        >
            <div
                className={clsx(
                    "group rounded-lg px-3 py-2",
                    bgColor,
                    hoverBgColor,
                    activeBgColor,
                )}
            >
                <AppText
                    variant="labelsbuttons"
                    className={clsx(textColor, "block !leading-none")}
                >
                    {label}
                </AppText>
            </div>
        </NavLink>
    );
};
