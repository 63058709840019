import React from "react";
import { TooltipContext, TooltipOptions } from "@/components/Tooltip/types";
import { useTooltip } from "@/components/Tooltip/useTooltip";

export const Tooltip = ({
    children,
    ...options
}: { children: React.ReactNode } & TooltipOptions) => {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const tooltip = useTooltip(options);
    return (
        <TooltipContext.Provider value={tooltip}>
            {children}
        </TooltipContext.Provider>
    );
};
