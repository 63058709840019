import { useEffect, RefObject } from "react";

export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
  ignoreElementIds?: string[]
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      // Check if click was in any ignored elements
      const clickInIgnoredId = ignoreElementIds?.some((id) => {
        const ignoredElement = document.getElementById(id);
        return ignoredElement?.contains(event.target as Node);
      });

      // If click was outside ref element and not in ignored elements, trigger callback
      if (ref.current && !ref.current.contains(event.target as Node) && !clickInIgnoredId) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [ref, callback, ignoreElementIds]);
};